<template>
  <div class="card">
    <div class="card-header " :class="classHeader">
      <slot name="header"></slot>
    </div>
    <div class="card-body" :class="classBody">
      <slot name="body">

      </slot>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    classHeader: {
      type: String,
      default: null
    },
    classBody: {
      type: String,
      default: null
    },
  },
  setup() {

  },
}
</script>
  