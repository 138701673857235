import { isAuthenticatedGuard,isAuthorized, hasChangedPassword } from '@/guard/auth-guard'
import TasksList from '../views/TasksList.vue'
export default{
    name:'tasks',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'/tasks',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
            meta:{permission:116,showNav: false},
            name:'tasks-index1',
            component:TasksList
        },
        {
            path:'/tasks/list',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
            meta:{permission:116,showNav: false},
            name:'tasks-index2',
            component:TasksList
        },
        {
            path:'/tasks/:codTarea',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
            meta:{permission:116,showNav: false},
            name:'tasks-form1',
            component:TasksList,
            props:(router)=>{
                return{
                    codTarea:router.params.codTarea
                }
            }
        },
        {
            path:'/tasks/list/:codTarea',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
            meta:{permission:116,showNav: false},
            name:'tasks-form2',
            component:TasksList,
            props:(router)=>{
                return{
                    codTarea:router.params.codTarea
                }
            }
        },
    ]
    
}