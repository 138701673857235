import { isAuthenticatedGuard, isAuthorized, hasActiveShopAllied, hasChangedPassword } from '@/guard/auth-guard'
import CasherIndex from '../views/CasherIndex.vue'

export default {
    name: 'casher',
    component: () =>
        import( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [{
        path: '',
        beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized, hasActiveShopAllied],
        name: 'casher-index',
        meta: { permission: 46, showNav: false },
        component: CasherIndex
    },
    ]



}