//Acciones asincronas que pueden llamar una mutación. 
//Estas tienen impacto en la base de datos
//Una vez tengan respuesta de la base de datos se para a las mutations
// export const myAction=async ({commit})=>{

import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'
import store from '@/store'

// }

export const loadUsers = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/users/lists`, {
            params: formData
        })
        commit('setUsers', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })

    }

}

export const createUser = async ({ commit }, formDataUser) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/users/store`, formDataUser)
        formDataUser.origen != 'externo' ? commit('addUserLists', { data: data.data }) : ''//Verifica si la creación de usuario viene de un módulo externo al de usuario para no agregar el nuevo usuario a la lista de usuarios
        commit('setUserEdit', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })

    }
}
export const getUser = async ({ commit }, userId) => {
    try {
        commit('showProcessHideAlert', 'user')
        const { data } = await requestApi.get(`/users/getUser/${userId}`)
        commit('setUserEdit', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })

    }
}
export const getUserProfile = async ({ commit }, userId) => {
    try {
        commit('showProcessHideAlert', 'user')
        const { data } = await requestApi.get(`/users/profile/${userId}`)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })

    }
}
export const updateUser = async ({ commit }, { formDataUser, userId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/users/update/${userId}?_method=PUT`, formDataUser)
        commit('updateUserLists', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Usuario actualizado', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })

    }
}

export const getUserShops = async ({ commit }, userId) => {
    try {
        commit('showProcessHideAlert', 'store')
        const { data } = await requestApi.get(`/users/getAlmacenesUsuario/${userId}`)
        commit('setUserShops', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })

    }
}
export const addShopToUser = async ({ commit }, { userId, formData }) => {
    try {
        commit('showProcessHideAlert', 'addStore')
        const { data } = await requestApi.post(`/users/storeAlmacenUsuario/${userId}`, formData)
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })
    }
}
export const removeShopToUser = async ({ commit }, { userId, formData }) => {
    try {
        commit('showProcessHideAlert', 'addStore')
        const { data } = await requestApi.post(`/users/inactivarRolUsuarioAlmacen/${userId}`, formData)
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })
    }
}
export const changeUserStatus = async ({ commit }, { userId }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.post(`/users/cambiarEstadoUsuario/${userId}`)
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true' })
        commit('setStatusUserList', { userId })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })
    }
}

export const getUserShopChat = async ({ commit }, { codAliado, codAlmacen }) => {
    try {
        commit('showProcessHideAlert', 'list')
        commit('clearUserShopChat')
        const { data } = await requestApi.get(`users/usuariosAlmacenChat?codAliado=${codAliado}&codAlmacen=${codAlmacen}`)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        commit('setUserShopChat', { data: data.data })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })
    }
}
export const selectUserShop = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.post(`users/seleccionarAlmacen`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })
    }
}
export const changePassword = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/auth/cambiarContrasenaLogueado`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        store.commit('auth/loginUser', { user: data.data })
        return { response: true, data:data }
    } catch (error) {
        return setErrorsFields({ modulo: 'users', error: error })
    }
}

export const setErrorsFielFrontend = ({ commit }, errors) => {
    commit('hideProcessShowAlert', { messageServer: 'Verifique que los campos se encuentren debidamene diligenciados', errorsFields: errors, statusResponse: false })
}

