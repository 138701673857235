import { isAuthenticatedGuard, isAuthorized, hasChangedPassword } from '@/guard/auth-guard'
import AlliedList from '../views/AlliedList.vue'
import DashboardLayout from '@/modules/dashboard/layouts/DashboardLayout'

export default {
    name: 'allied',
    component: DashboardLayout,
    children: [{
        path: '',
        beforeEnter: [isAuthenticatedGuard, isAuthorized, hasChangedPassword],
        name: 'allied-index',
        meta: { permission: 26, showNav: false },
        component: AlliedList
        // component: () =>
        //     import ( /* webpackChunkName: "allied-index'" */ '@/modules/allied/views/AlliedList')
    },]



}