<template>
    <div class="card h-100">
        <div class="card-header">
            <i class="fa-solid fa-table"></i> Estado de cuenta Shivam
        </div>
        <div class="card-body">
            <widget-loading v-if="isLoading['shivamGraphics']" />
            <div v-else>
                <base-table-forms :headers="['Fecha', 'Descripción', 'Debe', 'Haber', 'Saldo']" :whitPagination="false">
                    <tr v-for="account in shivamAccountGraphics" :key="account">
                        <td class="text-center">{{ inputDate(account.fecha, 2) }}</td>
                        <td class="text-start">{{ account.descripcion }}</td>
                        <td class="text-end">{{ currentFormat(account.debe) }}</td>
                        <td class="text-end">{{ currentFormat(account.haber) }}</td>
                        <td class="text-end">{{ currentFormat(account.saldo) }}</td>
                    </tr>
                    <tr>
                        <th class="text-end" colspan="2">Totales</th>
                        <th class="text-end">{{ currentFormat(total(shivamAccountGraphics, 'debe')) }}</th>
                        <th class="text-end">{{ currentFormat(total(shivamAccountGraphics, 'haber')) }}</th>
                        <th class="text-end">{{ totales.debe - totales.haber }}</th>
                    </tr>

                </base-table-forms>
            </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-end">
                <base-button-forms classButton="btn-primary" icon="fa-solid fa-file-export" :permissionId="71" :onClick="onExportToExcel" :isLoading="isLoading['shivamGraphicsExport']" :disabled="isLoading['shivamGraphicsExport']"></base-button-forms>
            </div>
        </div>
    </div>
</template>
  
<script>

import { ref, computed, onMounted } from 'vue'


import useGraphics from '@/modules/graphics/composables/useGraphics'
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import inputFormat from '@/helpers/inputMaskFormat'





export default {
    components: {
        WidgetLoading,
        BaseSelectForms,
        BaseTableForms,
        BaseButtonForms
    },

    setup() {
        const {
            isLoading,
            getDataGraphics,
            shivamAccountGraphics,
            exportToExcel,
        } = useGraphics()
        const { inputDate, inputCurrence } = inputFormat()
        const totales = ref({
            debe: 0,
            haber: 0
        })

        getDataGraphics({},'shivamGraphics')
        const currentFormat = (value) => {
            return inputCurrence(value, 0, 'state')
        }
        return {
            shivamAccountGraphics,
            isLoading,
            inputDate,
            currentFormat,
            exportToExcel,
            totales,

            total: (objet, key) => {
                let total = 0
                for (const k in objet) {
                    total += objet[k][key]
                }
                totales.value[key] = total
                return total
            },
            onExportToExcel: async ()=>{
                await exportToExcel({},'shivamGraphics')
            }
        }
    },


}
</script>