//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    available:{},
    articles:{},
    dataTicket:{},
    accountStatus:{},
    sales:{},
    payments:{},
    isLoadingTicket:false,
    isLoading: false,
    isLoadingCasher: false,
    isLoadingOperationCasher: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: '',
    CasherParameters: {}
})