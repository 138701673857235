import { isAuthenticatedGuard, isAuthorized,hasChangedPassword } from '@/guard/auth-guard'
import ShopList from '../views/ShopList.vue'
export default{
    name:'shop',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
            name:'shop-index',
            meta:{permission:29,showNav: false},
            component:ShopList
        },
    ]
    
}