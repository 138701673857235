<template>
    <li class="nav-item" :class="{ 'menu-is-opening': openNavItem, 'menu-open': openNavItem }" @click="menuNavItem">
        <a @click="hasChildren ? null : goPage(page)" href="#" class="nav-link"
            :class="{ 'custom-active': currentPage === page, 'disabled text-body-secondary': disabled }">
            <i :class="icon"></i>
            <p>
                {{ label }}
                <i v-if="hasChildren" class="right fas fa-angle-left"></i>
            </p>
        </a>
        <ul v-if="hasChildren" class="nav nav-treeview ps-3 child"
            :class="{ 'd-none': openNavItem == false, 'd-block': openNavItem }">
            <li class="nav-item child">
                <a v-for="child in children" :key="child" href="#" class="nav-link child"
                    :class="{ 'custom-active': currentPage === child.path, 'disabled text-body-secondary': disabled }"
                    @click="goPage(child.path)">
                    <i :class="'child nav-icon ' + child.meta.icon"></i>
                    <p class="child">{{ child.meta.title }}</p>
                </a>
            </li>
        </ul>
    </li>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { computed, ref } from 'vue';

export default {
    props: {
        page: {
            type: String
        },
        label: {
            type: String
        },
        icon: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        children: {
            type: Array,
            default: []
        }
    },

    setup(props) {
        const router = useRouter()
        const route = useRoute()
        const openNavItem = ref(false)
        const hasChildren = computed(() => props.children.length > 0)

        return {
            goPage: async (page) => {
                const result = await router.push(page)
            },
            currentPage: computed(() => route.path),

            //Indica si se abren o cierran las opciones del menu
            menuNavItem: (e) => {
                // Valida que el elemento no sea una lista hija
                if (e.target.classList.contains('child')) {
                    return
                }
                if (hasChildren.value) {
                    openNavItem.value = !openNavItem.value
                    return
                }
                openNavItem.value = false




            },
            openNavItem,
            hasChildren

        }
    }

}
</script>

<style lang="css" scoped src="../css/adminlte.css"></style>
<style lang="scss" scoped>
@import "@/styles/styles.scss";

.custom-active {
    background-color: $primary !important;
}

a.custom-active i,
a.custom-active p {
    color: white;
}

/* Revisar porque no esta funcionando como se espera */
.show-item-nav {
    display: block;
    height: auto;

    animation-name: showMenu;
    animation-duration: 1s;
}

@keyframes showMenu {
    0% {
        height: 0%;
    }

    50% {
        height: 50%;
    }

    100% {
        height: 100%;
    }
}
</style>