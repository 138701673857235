import { useStore } from "vuex";
import requestApi from '@/api/requestApi'
import { setErrorsFields } from '@/helpers/errorsFields'
import { serverUrl } from '@/helpers/accesoVariables';



export const signInUser = async ({ commit }, user) => {
    const { login, password } = user

    try {
        commit('showProcessHideAlert')
        await requestApi.get(serverUrl + '/sanctum/csrf-cookie')
        const { data } = await requestApi.post('/auth/ingresar', { login, password })
        commit('loginUser', { user: data.data })
        commit('setCurrentPage', 'dashboard')
        commit('hideProcessShowAlert', { messageServer: 'Usuario autenticado', errorsFields: {}, typeAlert: 'success' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'auth', error: error })
    }
}
export const logout = async ({ commit }, porInactividad) => {

    try {
        commit('showProcessHideAlert')
        const { data } = await requestApi.post('/auth/salir', { porInactividad: porInactividad })
        commit('logout')
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, typeAlert: 'success' })
        window.Echo.leaveChannel(`session`);
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'auth', error: error })
    }
}
export const requestLinkChangePassword = async ({ commit }, { email }) => {

    try {
        commit('showProcessHideAlert')
        await requestApi.get(serverUrl + '/sanctum/csrf-cookie')
        const { data } = await requestApi.post('/auth/enlaceCambiarContrasena', { email })
        commit('hideProcessShowAlert', { messageServer: data.data, errorsFields: {}, typeAlert: 'success' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'auth', error: error })
    }
}
export const changePassword = async ({ commit }, { formData }) => {

    try {
        commit('showProcessHideAlert')
        await requestApi.get(serverUrl + '/sanctum/csrf-cookie')
        const { data } = await requestApi.post('/auth/cambiarContrasena', formData)
        commit('hideProcessShowAlert', { messageServer: data.data, errorsFields: {}, typeAlert: 'success' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'auth', error: error })
    }
}


export const checkAuthentication = async ({ commit }) => {

    const store = useStore()
    try {
        if (store.state['auth']['permissions'].length === 0 && store.state['auth']['UserLoggedIn'] != null) {
            const { data } = await requestApi.get('/auth/user')
            const { usuario, fechaSistema, listas, parametros, permisos, parentesco } = data.data
            // const user = data.data;
            // const resp = await requestApi.get('/auth/getPermissionsUser')
            commit('setUserPermissions', { user: usuario, permissions: permisos, parameters: parametros, system: fechaSistema })
            store.commit('parameterization/setListsSelect', { data: listas.listas })
            store.commit('parameterization/setListsArmed', { data: listas.fuerzasArmadas })
            store.commit('parameterization/setPrincipalList', { data: listas.tiposListas })
            store.commit('relationship/setRelationshipSelect', { data: listas.parentesco })
        }
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, typeAlert: 'success' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'auth', error: error })
    }
}
export const changeAnalystShift = async ({ commit }, { codShift }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.post(`/users/cambiarTurnoAnalistas`, { estadoTurno: codShift })
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, typeAlert: 'success' })
        commit('updateShiftUser', { codShift })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'auth', error: error })
    }
}
export const setErrorsFielFrontend = ({ commit }, errors) => {
    commit('hideProcessShowAlert', { messageServer: 'Verifique que los campos se encuentren debidamene diligenciados', errorsFields: errors, typeAlert: 'danger' })

}

export const setCurrentPage = ({ commit }, currentPage) => {
    commit('setCurrentPage', currentPage)
}






