<template>
    <div>
        <widget-loading v-if="isLoadingList" />
        <!-- Crear/Actualizar lista -->
        <form v-else @submit.prevent="onSubmit">
            <Transition name="fade">
                <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
            </Transition>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="formData.tipoLista" idSelect="tipoLista" title="Tipo lista Principal*"
                        :options="principalList" optionId="codListaPrincipal" optionName="nombreListaPrincipal"
                        :errors="errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="formData.listaDependiente" idSelect="listaDependiente"
                        title="Tipo Lista Dependiente" :options="principalList" optionId="codListaPrincipal"
                        optionName="nombreListaPrincipal" :errors="errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="formData.codListaDependiente" idSelect="codListaDependiente"
                        title="Cod Lista Dependiente" :options="codigosListaDependiente" optionId="codLista"
                        optionName="descripcionLista" :errors="errorsFields"></base-select-forms>
                </div>
                <!-- <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.codLista" inputId="codLista" :errors="errorsFields"
                        title="Cod nueva lista" typeInput="text"></base-input-forms>
                </div> -->
            </div>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.descripcionLista" inputId="descripcionLista" :errors="errorsFields"
                        title="Nombre" typeInput="text"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.aliasLista" inputId="aliasLista" :errors="errorsFields" title="Alias*"
                        typeInput="text"></base-input-forms>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="formData.comentarios" title="Comentarios" textareaId="comentarios" :errors="errorsFields"></base-text-area>
                </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
                <base-button-forms classButton="btn-primary" :isLoading="isLoadinOperationlist" :disabled="buttonDisabled"
                    :label="isListEditing ? 'Actualizar' : 'Guardar'" :permissionId="isListEditing ? 19 : 18"></base-button-forms>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, watch, reactive, computed, toRef } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'



import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';


import useList from '../composables/useLists';
import validatorLang from '@/helpers/validatorLang'
import useAllied from '@/modules/allied/composables/useAllied'



export default {

    props: {

        listId: {
            type: String,
            required: true,

        }

    },

    components: {
        WidgetAlerts,
        BaseButtonForms,
        BaseSelectForms,
        BaseSelectList,
        BaseInputForms,
        WidgetLoading,
        BaseTextArea

    },
    setup(props) {
        const {
            codigosListaDependiente,
            errorsFields,
            formData,
            isLoading,
            isLoadingList,
            isLoadinOperationlist,
            messageServer,
            principalList,
            showAlert,
            typeAlert,
            createList,
            rules,
            v$,
            setErrorsFielFrontend,
            getList,
            updateList
        } = useList()

        const buttonDisabled = ref(false)
        const listIdCopy = toRef(props, 'listId')


        watch(listIdCopy, async (newValue) => {
            for (const property in formData.value) {
                formData.value[property] = ''
            }
            if (listIdCopy.value != 'newList') {
                const { data } = await getList(listIdCopy.value)
                formData.value = data
            }
        })

        return {
            buttonDisabled,
            codigosListaDependiente,
            createList,
            errorsFields,
            formData,
            isLoading,
            isLoadingList,
            isLoadinOperationlist,
            listIdCopy,
            messageServer,
            principalList,
            rules,
            setErrorsFielFrontend,
            showAlert,
            typeAlert,
            v$,
            updateList,

            isListEditing: computed(() => listIdCopy.value === 'newList' ? false : true),

        }

    },
    methods: {
        async onSubmit() {
            this.buttonDisabled = true
            let errors = {}
            this.v$.$validate()
            if (this.v$.$error) {
                for (const field in this.rules) {
                    if (this.v$[field].$errors[0]) {
                        errors[field] = [this.v$[field].$errors[0].$message]
                    }
                }
                this.setErrorsFielFrontend(errors)
                this.buttonDisabled = false
                return
            }
            // Si esta editando la lista
            if (this.isListEditing) {
                const resp = await this.updateList(this.formData, this.listIdCopy)
                this.buttonDisabled = false
                return
            }
            //Si esta creando una lista
            const resp = await this.createList(this.formData)
            this.buttonDisabled = false
            if (resp.response) this.$emit('on:createdList', resp.data.lista.consecutivo)
        }

    }
}

</script>

<style></style>