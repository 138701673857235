//Acciones asincronas que pueden llamar una mutación. 
//Estas tienen impacto en la base de datos
//Una vez tengan respuesta de la base de datos se para a las mutations
// export const myAction=async ({commit})=>{

import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const createRegister = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/validacionCentralesInformacion/store`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'centersInformation', error: error })

    }
}
export const getRegister = async ({ commit }, { tipoModelo, codModelo }) => {
    try {
        commit('showProcessHideAlert', 'register')
        const { data } = await requestApi.get(`/validacionCentralesInformacion/edit?tipoModelo=${tipoModelo}&codModelo=${codModelo}`)
        commit('setRegister', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data }
    } catch (error) {
        return setErrorsFields({ modulo: 'centersInformation', error: error })

    }
}

