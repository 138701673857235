import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setCustomerList = (state, { data }) => {
    state.customerList = data.aliados
    state.customerParameters = data.parametros
}
export const setCustomer = (state, { data }) => {
    state.customer = data
}

export const addCustomer = (state, { data }) => {
    state.customerList.data.unshift(data)
    state.customer.ca100CodAliado=data.ca100CodAliado
}
export const updateField = (state, { field, value }) => {
    state.customer[field] = value;
}
export const updateCustomer = (state, { data }) => {
    const idx = state.customerList.data.map(u => u.ca100CodAliado).indexOf(data.ca100CodAliado)
    state.customerList.data[idx] = data
}
export const clearCustomer = (state) => {
    for (const property in state.customer) {
        state.customer[property] = ''
    }
}

export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.messageServer = ''
    state.alert.showAlert = false
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false
    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.alert.showAlert = false
        }, 5000);
    }
}