<template>
    <div class="form-check" :class="{ 'form-check-inline': inlineInput }">
        <input v-model="inputModel" class="form-check-input" :class="{ 'is-invalid': errors[inputId] }" :type="typeInput"
            :name="inputId" :id="inputId" :value="inputValue" @change="onChange(paramFunction)" @click="onClick(paramFunction)"  :disabled="disabled">
        <label class="form-check-label" :for="inputId">{{ title }}</label>
    </div>
    <span v-if="errors[inputId]" class="invalid-feedback" role="alert">
        <small>{{ errors[inputId] ? errors[inputId][0] : '' }}</small>
    </span>
</template>

<script>
import { computed, ref } from 'vue'
export default {
    props: {
        modelValue: {
            required: false,
            default: '',
        },
        typeInput: {
            type: String,
            default: null
        },
        inputId: {
            type: String,
            default: null
        },
        inputValue: {
            default: ''
        },
        title: {
            default: ''
        },
        inlineInput: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: []
        },
        onBlur: {
            type: Function
        },
        onChange: {
            type: Function,
            default: () => { }
        },
        onClick: {
            type: Function,
            default: () => { }
        },
        paramFunction: {
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        checked: {
            type: Boolean,
            default:false
        }

    },
    setup(props, { emit }) {
        const inputModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', newValue)
            }
        })
        return {
            inputModel
        }

    }


}
</script>

<style></style>