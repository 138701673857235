import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import pusherApi from './api/pusherApi'


import './styles/styles.scss'
import './styles/app.css'

createApp(App).use(pusherApi).use(store).use(router).mount('#app')