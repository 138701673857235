import requestApi from "@/api/requestApi"
import { Toast } from "@/helpers/toastSwalert"
import Swal from "sweetalert2"

export const loadChatList = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'chatList')
        const { data } = await requestApi.get(`/chat/index`, {
            params: formData
        })
        commit('setChatList', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data }
    } catch (error) {
        return responseError(commit, error)
    }
}
export const getChatsNavbar = async ({ commit }) => {
    try {
        const { data } = await requestApi.get(`/chat/chatsPendientes`)
        commit('setChatsNavbar', { data })
        return { response: true }
    } catch (error) {
        return responseError(commit, error)
    }
}
export const startChat = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/chat/iniciarChat`, formData)
        commit('addChat', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return responseError(commit, error)
    }
}

export const sendMessageChat = async ({ commit }, { formData, chatId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/chat/enviarMensaje/${chatId}?_method=PUT`, formData)
        if (data.mensajeEnviado) {
            commit('upDateChatList', { data })
        }
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, send: data.mensajeEnviado, chat: data.mensajeEnviado ? data.chat : '' }
    } catch (error) {
        return responseError(commit, error)
    }
}
export const closeChat = async ({ commit }, { chatId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/chat/cerrarChat/${chatId}?_method=PUT`)
        commit('closeChat', chatId)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return responseError(commit, error)
    }
}

const responseError = (commit, error) => {
    if (error.response) {
        const { data, status } = error.response
        if (status === 422) {
            const messageAdd = data.seccionesPendientes ? data.seccionesPendientes : ''
            commit('hideProcessShowAlert', { messageServer: 'Verifique que los campos se encuentren debidamene diligenciados. ' + messageAdd, errorsFields: data.errors, statusResponse: false })
        } else {
            const message = data.Error ? data.Error : data.message
            commit('hideProcessShowAlert', { messageServer: message, errorsFields: {}, statusResponse: false })
            // let mensaje = ''
            // for (let key in data.Error) {
            //     if (data.Error.hasOwnProperty(key)) {
            //         mensaje = mensaje + `<li>${key}: ${data.Error[key]}</li>`;
            //     }
            // }
            Swal.fire(JSON.stringify(message), '', 'error')
        }
    } else {
        commit('hideProcessShowAlert', 'error')
    }
    return { response: false }
}

