import requestApi from "@/api/requestApi"

const options = {
    requestGraphics: { url: 'graficoSolicitudes', commit: 'setRequestGraphics' },
    salesGraphics: { url: 'graficoVentas', commit: 'setSalesGraphics' },
    paymentsGraphics: { url: 'graficoRecaudo', commit: 'setPaymentsGraphics' },
    creditGraphics: { url: 'graficoCartera', commit: 'setCreditGraphics' },
    shivamGraphics: { url: 'cuentaShivamAliado', commit: 'setShivamAccountGraphics' },
}

export const getDataGraphics = async ({ commit }, { formData, typeGraphics }) => {
    try {
        commit('showProcessHideAlert', typeGraphics)
        const { data } = await requestApi.get(`/admin/${options[typeGraphics].url}`, {
            params: formData
        })
        commit(options[typeGraphics].commit, { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: typeGraphics })
        return { response: true }
    } catch (error) {
        return responseError(commit, error,typeGraphics)
    }
}
export const exportToExcel = async ({ commit },{formData,typeGraphics}) => {
    try {
        commit('showProcessHideAlert', `${typeGraphics}Export`)
        const { data } = await requestApi.get(`/admin/${options[typeGraphics].url}/exportar`, {
            params:formData,
            responseType: 'blob'
        })
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = typeGraphics + new Date().getTime() + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:`${typeGraphics}Export` })
        return { response: true }
    } catch (error) {
        return responseError(commit, error,`${typeGraphics}Export`)
    }
}


const responseError = (commit, error,typeGraphics) => {
    if (error.response) {
        const { data, status } = error.response
        if (status === 422) {
            const messageAdd = data.seccionesPendientes ? data.seccionesPendientes : ''
            commit('hideProcessShowAlert', { messageServer: 'Verifique que los campos se encuentren debidamene diligenciados. ' + messageAdd, errorsFields: data.errors, statusResponse: false })
        } else {
            const message = data.Error ? data.Error : data.message
            commit('hideProcessShowAlert', { messageServer: message, errorsFields: {}, statusResponse: false,typeProcess: typeGraphics})
        }
    } else {
        commit('hideProcessShowAlert', 'error')
    }
    return { response: false }
}

