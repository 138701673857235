import { useStore } from "vuex";
import requestApi from '@/api/requestApi'
import { setErrorsFields } from '@/helpers/errorsFields'



export const getShopAuthorizedLists = async ({ commit }, typeShop) => {
    try {
        commit('showProcessHideAlert','shopList')
        const { data } = await requestApi.get(`/almacen/authorizeList/${typeShop}`)
        commit('setShopAuthorized', data.data)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'shop', error: error })
    }
}
export const loadShopList = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'shopList')
        const { data } = await requestApi.get(`/almacen/index`, {
            params: formData
        })
        commit('setShopList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'shop', error: error })
    }
}
export const getSimpleList = async ({ commit }, { codAliado }) => {
    try {
        commit('showProcessHideAlert', 'shopList')
        commit('clearSimpleList')
        const { data } = await requestApi.get(`/almacen/listadoSimple?codAliado=${codAliado}`)
        commit('setSimpleList', { data:data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'shop', error: error })
    }
}

export const createShop = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/almacen/store`, formData)
        commit('addShop', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'shop', error: error })
    }
}
export const getShop = async ({ commit }, { shopId }) => {
    try {
        commit('showProcessHideAlert', 'shop')
        const { data } = await requestApi.get(`/almacen/getAlmacen/${shopId}`)
        commit('setShop', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'shop', error: error })
    }
}
export const updateShop = async ({ commit }, { formData, shopId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/almacen/update/${shopId}?_method=PUT`, formData)
        commit('updateShop', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'shop', error: error })
    }
}










