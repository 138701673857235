//Acciones asincronas que pueden llamar una mutación. 
//Estas tienen impacto en la base de datos
//Una vez tengan respuesta de la base de datos se para a las mutations
// export const myAction=async ({commit})=>{

import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'



export const loadRequest = async ({ commit }, {formDataFilter }) => {
    try {
        commit('showProcessHideAlert', 'requestList')
        const { data } = await requestApi.get(`/solicitudCredito/index`, {
            params:formDataFilter
        })
        commit('setRequestList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
       return setErrorsFields({ modulo: 'request', error: error })

    }

}
// export const loadRequestParameters = async ({ commit }) => {
//     try {
//         commit('showProcessHideAlert', 'requestList')
//         const { data } = await requestApi.get(`/solicitudCredito/parametrosSolicitud`)
//         commit('setRequestParameters', { data:data.data })
//         commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
//         return { response: true }

//     } catch (error) {
//        return setErrorsFields({ modulo: 'request', error: error })

//     }

// }



export const createRequest = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/solicitudCredito/store`, formData)
        commit('addRequest', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        console.log(error);
       return setErrorsFields({ modulo: 'request', error: error })

    }
}
export const getRequest = async ({ commit }, requestId) => {
    try {
        commit('showProcessHideAlert', 'request')
        const { data } = await requestApi.get(`/solicitudCredito/getRequest/${requestId}`)
        commit('setRequest', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
       return setErrorsFields({ modulo: 'request', error: error })

    }
}
export const updateRequest = async ({ commit }, { formData, requestId, section }) => {
    try {
        const url = ['','updateBasicos', 'updateUbicacion', 'updateFinanzas', 'updateOcupacion', 'updateConyuge', 'updateInfoCredito','', 'enviarAEstudio']
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/solicitudCredito/${url[section]}/${requestId}`, formData)
        // commit('updateList', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
       return setErrorsFields({ modulo: 'request', error: error })

    }
}


