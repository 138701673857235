//Acciones asincronas que pueden llamar una mutación. 
//Estas tienen impacto en la base de datos
//Una vez tengan respuesta de la base de datos se para a las mutations
// export const myAction=async ({commit})=>{

import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'



export const loadListsIndex = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'listList')
        const { data } = await requestApi.get(`/listas/index`,{
            params:formData
        })
        commit('setListsIndex', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        return setErrorsFields({ modulo: 'parameterization', error: error })

    }

}


export const createList = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/listas/store`, formData)
        commit('addList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'parameterization', error: error })

    }
}
export const getList = async ({ commit }, listId) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/listas/getList/${listId}`)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'parameterization', error: error })

    }
}
export const updateList = async ({ commit }, { formData, listId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/listas/update/${listId}`, formData)
        commit('updateList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Lista actualizada', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'parameterization', error: error })

    }
}

export const setErrorsFielFrontend = ({ commit }, errors) => {
    commit('hideProcessShowAlert', { messageServer: 'Verifique que los campos se encuentren debidamene diligenciados', errorsFields: errors, statusResponse: false })
}
