<template>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-select-list v-model="Ca111TipoIdeConyuge" idSelect="Ca111TipoIdeConyuge" :errors="errorsFields"
                tipoLista="TPIDEN" listaPadre="TPTERC" listaPadreId="2" title="Tipo ID Cónyuge">
            </base-select-list>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NumeroIdConyuge" inputId="Ca111NumeroIdConyuge" :errors="errorsFields"
                title="Número Id cónyuge" typeInput="text" typeData="numId"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NombreCompletoConyuge" inputId="Ca111NombreCompletoConyuge" :errors="errorsFields"
                title="Nombre completo cónyuge" typeInput="text"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111CelularConyuge" inputId="Ca111CelularConyuge" :errors="errorsFields"
                title="Celular cónyuge" typeInput="text" typeData="phone"></base-input-forms>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111TelefonoFijoConyuge" inputId="Ca111TelefonoFijoConyuge" :errors="errorsFields"
                title="Teléfono fijo cónyuge" typeInput="text" typeData="phone"></base-input-forms>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseSelectListArmed from '@/common/forms/BaseSelectListArmed.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseSelectListArmed,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111TipoIdeConyuge: computed({
                get: () => store.state['request']['request']['Ca111TipoIdeConyuge'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoIdeConyuge', value: val })
            }),
            Ca111NumeroIdConyuge: computed({
                get: () => store.state['request']['request']['Ca111NumeroIdConyuge'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NumeroIdConyuge', value: val })
            }),
            Ca111NombreCompletoConyuge: computed({
                get: () => store.state['request']['request']['Ca111NombreCompletoConyuge'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreCompletoConyuge', value: val })
            }),
            Ca111CelularConyuge: computed({
                get: () => store.state['request']['request']['Ca111CelularConyuge'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CelularConyuge', value: val })
            }),
            Ca111TelefonoFijoConyuge: computed({
                get: () => store.state['request']['request']['Ca111TelefonoFijoConyuge'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoFijoConyuge', value: val })
            }),
           
        }

    },
}
</script>