export default () => ({
    chatList:[],
    chatsNavbar:[],
    isLoading: false,
    isLoadingChat: false,
    isLoadingOperationChat: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: '',
    chatParameters: {}
})