<template>
    <div class="table-responsive">
        <table class="table table-sm table-striped table-hover">
            <!-- style="min-height: 12rem;" -->
            <thead class="table-info">
                <tr v-if="whitOrder" class="text-center">
                    <th :colspan="colspanColumn" v-for="head in headers" :key="head.columna" class="text-center" :role="head.columna?'button':''"
                        @click="head.columna?dataFunction(1, head.columna):null" >
                        <i v-if="orderColumn === head.columna" :class="{'fa-solid fa-sort-up':order=='asc','fa-solid fa-sort-down':order=='desc',}"></i>
                        {{ head.encabezado }}
                    </th>
                </tr>
                <tr v-else>
                    <th :colspan="colspanColumn" v-for="head in headers" :key="head[head]" class="text-center">
                        {{ head }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <slot></slot>
            </tbody>
        </table>
        <template v-if="whitPagination">
            <div class="text-center">Mostrando {{ data.data.length }} de {{ data.total }} elementos</div>
            <Bootstrap5Pagination :data="data" @pagination-change-page="dataFunction" :limit="10" align="left" />
        </template>
        <!-- <template v-else>
            <div class="text-center"> {{ data.length }} elementos</div>
        </template> -->
    </div>
</template>

<script>
import { Bootstrap5Pagination } from 'laravel-vue-pagination';



export default {
    components: {
        Bootstrap5Pagination,
    },
    props: {
        headers: {
            type: Array,
            default: []
        },
        data: {
            type: Object,
            default: {}
        },
        dataFunction: {
            type: Function,
            default: () => { }
        },
        colspanColumn: {
            default: 0,
            type: Number
        },
        whitPagination: {
            type: Boolean,
            default: true
        },
        whitOrder: {
            type: Boolean,
            default: false
        },
        orderColumn: {
            type: String,
            default: ''
        },
        order: {
            type: String,
            default: ''
        },
        
    },

}
</script>

<style></style>