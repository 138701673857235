<template>
  <TheContentHeader titlePage="Chats" />
  <the-content-body>
    <!-- Filtros -->
    <form @submit.prevent="callLoadList">
      <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <base-select-list v-model="formData.tipoAsunto" idSelect="tipoAsunto" :errors="[]" tipoLista="TPASUN"
            title="Tipo asunto"></base-select-list>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <base-select-forms v-model="formData.estadoAsunto" title="Estado" idSelect="estadoAsunto"
            :options="[{ nombre: 'Abierto', codigo: 'A' }, { nombre: 'Cerrado', codigo: 'C' }]" optionName="nombre"
            optionId="codigo" :errors="[]"></base-select-forms>

        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <base-select-forms v-model="formData.codAliado" title="Aliado" idSelect="codAliado" :options="alliedList"
            optionName="nombreAliado" optionId="ca100CodAliado" :errors="[]" :onChange="callgetUserAndShop"
            :paramFunction="formData.codAliado ? formData.codAliado : '%%'"></base-select-forms>

        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <base-select-forms v-model="formData.codAlmacen" title="Almacén" idSelect="codAlmacen" :options="shopList"
            optionName="Ca110Alias" optionId="Ca110CodAlmacen" :onChange="callgetUser" :errors="[]"></base-select-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <base-select-forms v-model="formData.codUsuarioAlmacen" title="Usuarios" idSelect="codUsuarioAlmacen"
            :options="userShopChat" optionName="name" optionId="id" :errors="[]"></base-select-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <b class="col-form-label">Fechas</b>
          <div class="input-group">
            <input v-model="formData.fechaInicial" type="date" class="form-control form-control-sm">
            <input v-model="formData.fechaFinal" type="date" class="form-control form-control-sm">
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
          <base-button-forms label="Buscar" classButton="btn-primary mt-3" :disabled="isLoading" :isLoading="isLoading"
            :permissionId="61"></base-button-forms>
        </div>
      </div>
    </form>

    <!-- Chats -->
    <div class="card">
      <div class="card-body">
        <div class="card" style="height: 100%">
          <div class="row g-0">
            <div class="col-12 col-lg-5 col-xl-3 border-right lista-asuntos">
              <div class="px-4">
                <div class="d-flex align-items-center text-center ">
                  <div class="flex-grow-1">
                    <p class="form-control form-control-sm my-3">Asuntos</p>
                    <div class="row">
                      <div class="col-6">
                        <div class="badge bg-success float-center mr-2"><i class="fa fa-flag" aria-hidden="true"></i>
                          Atendidas: {{ chatStatus[0] }}</div>
                      </div>
                      <div class="col-6">
                        <div class="badge bg-warning float-center"><i class="fa fa-flag" aria-hidden="true"></i>
                          Pendientes: {{ chatStatus[1] }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Lista de asuntos -->
              <widget-loading v-if="isLoading" />
              <template v-else>
                <a v-for="chat in chatList" :key="chat.CA202CODCHAT" role="button"
                  class="list-group-item list-group-item-action border-0 mt-1"
                  :class="{ 'activar': currentChat.CA202CODCHAT === chat.CA202CODCHAT }" @click="getChatMessage(chat)">
                  <div class="badge float-start"
                    :class="{ 'bg-warning': chat.CA202FLAG === '1', 'bg-success': chat.CA202FLAG === '0' }">
                    <i class="fa fa-flag" aria-hidden="true"></i>
                  </div>
                  <div class="d-flex align-items-start">
                    <div class="flex-grow-1 ms-3">
                      {{ chat.CA202ASUNTO }}
                      <div class="small">
                        {{ lastMessageChat(chat.CA202MENSAJE)['mensaje'] }}
                        {{ getDiffForHumans(lastMessageChat(chat.CA202MENSAJE)['timEnvia']) }}
                      </div>
                    </div>
                  </div>
                </a>
              </template>

            </div>
            <div class="col-12 col-lg-7 col-xl-9">
              <div class="py-2 px-4 border-bottom border-top">
                <div class="d-flex align-items-center py-1">
                  <div class="flex-grow-1 pl-3">
                    <strong>Asunto: {{ currentChat.CA202ASUNTO }}</strong>
                    <div class="text-muted small" id="typing" style="visibility: hidden">
                      <em>Escribiendo...</em>
                    </div>
                  </div>
                </div>
              </div>
              <!-- {{ --Lista de mensajes-- }} -->
              <div class="card">
                <div class="chat-messages p-2" ref="containerMessageList">
                  <template v-for="(message, index) in chatMessage" :key="index">
                    <!-- {{ --Mensaje lado derecho-- }} -->
                    <div v-if="message['envia'] === userDataLoggedIn.id" class="chat-message-right pb-4">
                      <div>
                        <div class="text-muted small text-nowrap mt-2">
                          {{ getDiffForHumans(message['timEnvia']) }}
                        </div>
                      </div>
                      <div class="flex-shrink-1 rounded py-2 px-3 mr-3 table-success">
                        <div class="font-weight-bold mb-1">
                          {{ message['nombreEnvia'] }}
                        </div>
                        <div class=" auto-line text-break"> {{ message['mensaje'] }}</div>
                      </div>
                    </div>
                    <!-- {{ --Mensaje lado izquierdo-- }} -->
                    <div v-else class="chat-message-left pb-4">
                      <div>
                        <div class="text-muted small text-nowrap mt-2">
                          {{ getDiffForHumans(message['timEnvia']) }}
                        </div>
                      </div>
                      <div class="flex-shrink-1 rounded py-2 px-3 ml-3 auto-line"
                        :class="{ 'table-danger': message['envia'] === '1', 'table-warning': message['envia'] != '1' }">
                        <div class="font-weight-bold mb-1">
                          {{ message['nombreEnvia'] }}
                        </div>
                        <div class=" auto-line text-break"> {{ message['mensaje'] }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <!-- Footer -->
              <div class="card-footer" style="background-color: white">
                <div class="flex-grow-0 py-0 px-4">
                  <small v-show="userTyping">escribiendo...</small>
                  <form @submit.prevent="onSendMessageChat">
                    <div class="input-group">
                      <base-input-forms v-model="message" :errors="[]" inputId="message" placeholder="Escriba el mensaje ..."
                        typeInput="text" :disabled="isLoadingOperationChat" :onKeyup="sendEventTyping"></base-input-forms>
                      <span class="input-group-append">
                        <base-button-forms :disabled="isLoadingOperationChat || !message" :isLoading="isLoadingOperationChat"
                          label="Enviar" :permissionId="61" classButton="btn-primary btn-sm"
                          icon="fa-solid fa-paper-plane"></base-button-forms>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </the-content-body>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

import BaseInputForms from '@/common/forms/BaseInputForms.vue'
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';

import useChat from '@/modules/chat/composables/useChat'
import useAllied from '@/modules/allied/composables/useAllied';
import useShop from '@/modules/shop/composables/useShop';
import useUsers from '@/modules/users/composables/useUsers';



export default {

  components: {
    BaseInputForms,
    BaseButtonForms,
    WidgetLoading,
    BaseSelectForms,
    BaseSelectList,
    TheContentBody,
    TheContentHeader
  },

  setup() {
    const {
      loadChatList,
      chatList,
      chatStatus,
      chatMessage,
      sendMessageChat,
      getChatMessage,
      getDiffForHumans,
      currentChat,
      containerMessageList,
      isLoadingOperationChat,
      isLoading,
      lastMessageChat,
      userDataLoggedIn,
      sendEventTyping,
      userTyping,
      updateChatList,
      getChatsNavbar,
    } = useChat()
    const { getSimpleList: getAlliedList, simpleList: alliedList } = useAllied()
    const { getSimpleList: getShopList, simpleList: shopList } = useShop()
    const { getUserShopChat, userShopChat } = useUsers()
    // const today = Intl.DateTimeFormat('en').format(new Date(Date.now()));
    // console.log(today);
    const formData = ref({
      tipoAsunto: 1,
      estadoAsunto: 'A',
      codUsuarioAlmacen: '',
      codAliado: '',
      codAlmacen: '',
      fechaInicial: '2023-08-28',
      fechaFinal: '2023-08-28'
    })
    const message = ref(null)

    // Invocado al cargar el componente
    const callLoadList = async () => {
      await loadChatList(formData.value)
      currentChat.value = chatList.value[0]
      getChatMessage(currentChat.value)

    }
    // Invocado por el select aliado|
    const callgetUserAndShop = () => {
      getShopList(formData.value.codAliado)
      getUserShopChat(formData.value.codAliado, formData.value.codAlmacen)
    }
    // Invocado por el select almacen
    const callgetUser = () => {
      getUserShopChat(formData.value.codAliado, formData.value.codAlmacen)
    }
    callLoadList()
    getAlliedList()
    callgetUserAndShop()

    // Conectar a websockets
    window.Echo
      .private(`chat-shivam`)
      .listen('.ChatEvent', (e) => {
        updateChatList(e)
      })
    window.Echo
      .private(`chat-escribiendo`)
      .listenForWhisper('typing', (e) => {
        if (e.asunto === currentChat.value.CA202ASUNTO) {
          userTyping.value = true
          setTimeout(() => {
            userTyping.value = false
          }, 2000)
        }

      })


    return {
      chatList,
      chatStatus,
      chatMessage,
      userDataLoggedIn,
      currentChat,
      containerMessageList,
      message,
      isLoadingOperationChat,
      isLoading,
      formData,
      alliedList,
      shopList,
      userShopChat,
      userTyping,

      getShopList,
      callgetUserAndShop,
      callgetUser,
      lastMessageChat,
      getChatMessage,
      getDiffForHumans,
      callLoadList,
      sendEventTyping,
      onSendMessageChat: async () => {
        const { response, send, chat } = await sendMessageChat(currentChat.value.CA202CODCHAT, {
          mensaje: message.value
        })
        if (response && send) {
          getChatMessage(chat)
          message.value = ''
          getChatsNavbar()
        }

      },

    }
  }






}
</script>
<style lang="css" scoped src="../../dashboard/css/adminlte.css"></style>
<style>
/* body {
  margin-top: 20px;
} */



.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.lista-asuntos {
  /* max-height: 300px; */
  height: 450px;
  overflow-y: scroll;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  /* max-height: 300px; */
  height: 300px;
  overflow-y: scroll;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.auto-line {
  word-wrap: break-word;
}

.activar {
  background-color: #bec9e5 !important;
  border-radius: 10px
}
</style>