export default () => ({
    questionsList: {},
    requestFields: {},
    questionsSelect: {},
    question: {
        CA003PREGUNTA: '',
        CA003TIPOPREGUNTA: '',
        CA003NUMOPCIONES: '',
        CA003CAMPORELACIONADO: '',
        segmentos: [],
        tiposReferencias: [],
        objetivos: [],
        opcionesRespuesta: [{
            CA004IDLISTARESPUESTA: '',
            CA004LISTARESPUESTA: '',
            CA004VALOR: ''
        }]
    },
    isLoading: {
        list: false,
        question: false,
        operation: false,
        segmento: false,
        referencia: false,
        objetivo: false,
        answerOptions: false
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: ''
    }
})