import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

import validatorLang from '@/helpers/validatorLang'



const useRequest = () => {
    const store = useStore()

    const numPages = ref("5")
    const requestId = ref('newRequest')
    const modals = ref({ modalRequest: null })

    const { customRequired } = validatorLang()


    const formDataFilter = ref({
        codSolicitud: '',
        tipoIdentificacionCliente: '',
        numIdentificacionCliente: '',
        codAlmacen: '',
        estadoSolicitud: '',
        estadoFase1: '',
        estadoFase2: '',
        estadoFase3: '',
        numPages: '5',
        page: '1',
        column:'Ca111CodSolicitud',
        order:'asc'

    })

    // const rules = {
    //     tipoLista: { required: customRequired },
    //     codLista: { required: customRequired },
    //     descripcionLista: { required: customRequired },
    //     aliasLista: { required: customRequired },
    //     comentarios: { required: customRequired },
    // }
    // const v$ = useVuelidate(rules, formData)


    const loadRequest = async (formDataFilter) => {
        const resp = await store.dispatch('request/loadRequest', { formDataFilter })
        return resp
    }
    const loadRequestParameters = async () => {
        const resp = await store.dispatch('request/loadRequestParameters');
        return resp
    }


    const createRequest = async (formData) => {
        const resp = await store.dispatch('request/createRequest', formData)
        return resp
    }
    const getRequest = async (requestId) => {
        const resp = await store.dispatch('request/getRequest', requestId)
        return resp
    }
    const updateRequest = async (formData, requestId, section) => {
        const resp = await store.dispatch('request/updateRequest', { requestId, formData, section })
        return resp
    }
    const setErrorsFielFrontend = (errors) => {
        const resp = store.dispatch('request/setErrorsFielFrontend', errors)
    }


    return {
        formDataFilter,
        requestId,
        modals,
        numPages,
        // rules,
        // v$,
        setErrorsFielFrontend,

        loadRequest,
        loadRequestParameters,
        createRequest,
        getRequest,
        updateRequest,

        errorsFields: computed(() => store.state['request']['errorsFields']),
        isLoading: computed(() => store.state['request']['isLoading']),
        isLoadingRequest: computed(() => store.state['request']['isLoadingRequest']),
        isLoadingOperationRequest: computed(() => store.state['request']['isLoadingOperationRequest']),
        messageServer: computed(() => store.state['request']['messageServer']),
        request: computed(() => store.state['request']['request']),
        requestsLists: computed(() => store.state['request']['requestsLists']),
        requestParameters: computed(() => store.state['request']['requestParameters']),
        showAlert: computed(() => store.state['request']['showAlert']),
        typeAlert: computed(() => store.state['request']['typeAlert']),


    }
}

export default useRequest