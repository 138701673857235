export const getListSelect = (state) => (typeLists, dependentList = '', codDependentList = '') => {

    if (dependentList.length > 0 && codDependentList.length > 0) {
        const list = state.listsSelect.filter(l => l.tipoLista === typeLists && l.listaDependiente === dependentList && l.codListaDependiente === codDependentList)

        return list

    }
    if (dependentList.length > 0 && codDependentList.length === 0) {
        const list = state.listsSelect.filter(l => l.tipoLista === typeLists && l.listaDependiente === dependentList)

        return list


    }
    if (dependentList.length === 0 && codDependentList.length === 0) {
        const list = state.listsSelect.filter(l => l.tipoLista === typeLists)

        return list


    }
}
export const getListBodiesArmed = (state) => {
    return state.listBodiesArmed
}

export const getListSubdivision = (state) => {
    return state.listSubdivisionArmed

}

export const getListArmed = (state) => (codCuerpo, codSubdivision = '', codRango = '') => {

    const list = state.listsArmaded
    return list.filter(l => l.codCuerpo === codCuerpo && l.codSubdivision === codSubdivision)
}

