import { isAuthenticatedGuard, hasActiveShopAllied,hasChangedPassword } from '@/guard/auth-guard'
import Dashboard from '../views/Dashboard.vue'
export default {
    name: 'dashboard',
    component: () =>
        import ( /* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [{
        path: '',
        beforeEnter: [isAuthenticatedGuard,hasChangedPassword,hasActiveShopAllied],
        name: 'start',
        meta:{permission:2,  showNav: false,},
        component:Dashboard
    }, ]



}