<template>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-select-list v-model="Ca111CodTipoEstudio" idSelect="Ca111CodTipoEstudio" :errors="errorsFields"
                tipoLista="TPEST" listaPadre="" listaPadreId="" title="Tipo de estudio">
            </base-select-list>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NombreEstudio" inputId="Ca111NombreEstudio" :errors="errorsFields"
                title="Estudio realizado" typeInput="text"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NombreInstitucionEstudio" inputId="Ca111NombreInstitucionEstudio"
                :errors="errorsFields" title="Nombre Institución" typeInput="text"></base-input-forms>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';



import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseInputForms,
        WidgetLoading,

    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111CodTipoEstudio: computed({
                get: () => store.state['request']['request']['Ca111CodTipoEstudio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodTipoEstudio', value: val })
            }),
            Ca111NombreEstudio: computed({
                get: () => store.state['request']['request']['Ca111NombreEstudio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreEstudio', value: val })
            }),
            Ca111NombreInstitucionEstudio: computed({
                get: () => store.state['request']['request']['Ca111NombreInstitucionEstudio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreInstitucionEstudio', value: val })
            }),
            

        }

    },
}
</script>