<template>
    <label :for="textareaId" class="fw-bold">{{ title }}</label>
    <textarea v-model="textAreaModel" :name="textareaId" :id="textareaId" class="form-control form-control-sm"
        :class="{ 'is-invalid': errors[textareaId] }" cols="130" :rows="rows" maxlength="200" @blur="onBlur(paramFunction)" :disabled="disabled"></textarea>
    <div>
        {{ countCharacters }} Caracteres
    </div>
    <span v-if="errors[textareaId]" class="invalid-feedback" role="alert">
        <small>{{ errors[textareaId] ? errors[textareaId][0] : '' }}</small>
    </span>
</template>
<script>
import { computed } from 'vue'
export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            type: String,
        },
        textareaName: {
            type: String
        },
        textareaId: {
            type: String
        },
        title: {
            type: String
        },
        errors: {
            type: Object,
            default: []
        },
        rows: {
            type: Number,
            default: 1
        },
        onBlur: {
            type: Function,
            default:()=>{}
        },
        paramFunction: {
            default: ''
        },
        disabled:{
            type:Boolean,
            default:false
        }
    },
    setup(props, { emit }) {
        const textAreaModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', newValue)
            }
        })

        return {
            textAreaModel,

            countCharacters: computed(() => 200 - props.modelValue.length)
        }
    }

}
</script>

<style></style>