import { computed } from "vue";
import { useStore } from "vuex";

const useGraphics = () => {
    const store = useStore()

    const getDataGraphics = async(formData,typeGraphics) => {
        return await  store.dispatch('graphics/getDataGraphics', { formData,typeGraphics })
    }
    const exportToExcel = async(formData,typeGraphics) => {
        return await  store.dispatch('graphics/exportToExcel',{formData,typeGraphics})
    }
   

    return {
        getDataGraphics,
        exportToExcel,


       
        alert: computed(() => store.state['graphics']['alert']),
        requestGraphics: computed(() => store.state['graphics']['requestGraphics']),
        graphicsParameters: computed(() => store.state['graphics']['graphicsParameters']),
        salesGraphics: computed(() => store.state['graphics']['salesGraphics']),
        paymentsGraphics: computed(() => store.state['graphics']['paymentsGraphics']),
        creditGraphics: computed(() => store.state['graphics']['creditGraphics']),
        shivamAccountGraphics: computed(() => store.state['graphics']['shivamAccountGraphics']),
        isLoading: computed(() => store.state['graphics']['isLoading']),

    }



}


export default useGraphics