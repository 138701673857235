/**
 * Valida si se puede acceder a una imagen en internet
 * @param {String} url 
 * @returns {Promise}
 */
export const checkFile = async (url) => {
    return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = url;

        img.onload = function () {
            // La imagen se ha cargado correctamente
            resolve(img);
        };

        img.onerror = function () {
            // La imagen no se ha podido cargar
            reject(new Error('No se pudo cargar la imagen.'));
        };

    });
}