
export const setReferenceList = (state, { data }) => {
    state.referenceList = data
}

export const setCurrentReference = (state, { data }) => {
    state.currentReference = data
}
export const addReference = (state, { data }) => {
   
    state.referenceList.data.unshift(data)
}

export const updateReference = (state, { data }) => {
    const idx = state.referenceList.data.map(r => r.CA115CODREFERENCIA).indexOf(data.CA115CODREFERENCIA)
    state.referenceList.data[idx] = data
}
export const deleteReference = (state, referenceId) => {
    state.referenceList.data = state.referenceList.data.filter(r => r.CA115CODREFERENCIA != referenceId)
}

export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'referenceList':
            state.isLoading = true
            break;
        case 'reference':
            state.isLoadingReference = true
            break;
        case 'operation':
            state.isLoadinOperationReference = true
            break;
    }
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingReference = false
    state.isLoadinOperationReference = false
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }
}