export default () => ({
    request: {
        // Ca111TipoIdCliente: '2',
        // Ca111NumeroIdCliente: '1144133869',
        // Ca111FechaExpDocID: '2008-01-31',
        // Ca111CiudadExpedicion: 'Cali',
        // Ca111PrimerNombre: 'Alvaro',
        // Ca111SegundoNombre: 'Eduardo',
        // Ca111PrimerApellido: 'Ocampo',
        // Ca111SegundoApellido: 'Paz',
        // Ca111Genero: '1',
        // Ca111FechaNacimiento: '1989-12-19',
        // Ca111EstadoCivil: '4',
        // Ca111TelefonoCelular: '3207236182',
        // Ca111CorreoElectronico: 'aeopaz@gmail.com',
        // Ca111NumTotalHijos: '1',
        // Ca111NumHijosDependenciaFinanciera: '1',
        // Ca111NumOtrosDependenciaFinanciera: '2',
        Ca111TipoIdCliente: '',
        Ca111NumeroIdCliente: '',
        Ca111FechaExpDocID: '',
        Ca111CiudadExpedicion: '',
        Ca111PrimerNombre: '',
        Ca111SegundoNombre: '',
        Ca111PrimerApellido: '',
        Ca111SegundoApellido: '',
        Ca111Genero: '',
        Ca111FechaNacimiento: '',
        Ca111EstadoCivil: '',
        Ca111TelefonoCelular: '',
        Ca111CorreoElectronico: '',
        Ca111NumTotalHijos: '',
        Ca111NumHijosDependenciaFinanciera: '',
        Ca111NumOtrosDependenciaFinanciera: '',
    },
    requestsLists: [],
    requestParameters: [],
    isLoading: false,
    isLoadingRequest: false,
    isLoadingOperationRequest: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: ''
})