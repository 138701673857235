import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'



export const loadObjetiveIndex = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/objetivos/index`, {
            params: formData
        })
        commit('setObjetiveIndex', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'objetives', error: error, typeProcess: 'list' })


    }

}

export const loadObjetiveListSelect = async ({ commit }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/objetivos/loadLists`)
        commit('setObjetiveSelect', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'objetives', error: error, typeProcess: 'list' })

    }

}



export const createObjetive = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/objetivos/store`, formData)
        commit('addObjetiveList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'objetives', error: error, typeProcess: 'operation' })

    }
}
export const getObjetive = async ({ commit }, objetiveId) => {
    try {
        commit('showProcessHideAlert', 'objetive')
        const { data } = await requestApi.get(`/objetivos/getObjetivo/${objetiveId}`)
        commit('setObjetive', { data: data.data })

        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'objetive' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'objetives', error: error, typeProcess: 'objetive' })

    }
}
export const updateObjetive = async ({ commit }, { formData, objetiveId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/objetivos/update/${objetiveId}?_method=PUT`, formData)
        commit('updateObjetiveList', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'objetives', error: error, typeProcess: 'operation' })

    }
}
