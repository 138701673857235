import { computed } from "vue";
import { useStore } from "vuex";

const useRoles = () => {
    const store = useStore()

    const loadRolesList = async(page = 1, numPages = 5, search = '') => {
        return await  store.dispatch('roles/loadRolesList', { page, numPages, search })
    }
    const getRole = async(roleId) => {
        return await  store.dispatch('roles/getRole',{roleId})
    }
    const createRole = async(formData) => {
        return await  store.dispatch('roles/createRole',{formData})
    }
    const updateRole = async(roleId,formData) => {
        return await  store.dispatch('roles/updateRole',{roleId,formData})
    }
   

    return {
        loadRolesList,
        getRole,
        createRole,
        updateRole,

        rolesList: computed(() => store.state['roles']['rolesList']),
        role: computed(() => store.state['roles']['role']),
        permissionsList: computed(() => store.state['roles']['permissionsList']),
        alert: computed(() => store.state['roles']['alert']),
        isLoading: computed(() => store.state['roles']['isLoading']),
       


    }



}


export default useRoles