<template>
    <widget-loading v-if="isLoading.summary" />
    <div v-else class="row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <widget-progress-circle v-for="category in summaryTask" :key="category.codCategoria"
                :text="category.nomCategoria" :progress="categoryProgress(category)" color="danger"
                :width="100"></widget-progress-circle>
        </div>
    </div>
</template>

<script>
import useTasks from '@/modules/tasks/composables/useTasks'

import WidgetProgressCircle from '@/common/widgets/WidgetProgressCircle.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';

export default {
    components: {
        WidgetProgressCircle,
        WidgetLoading
    },

    setup() {

        const {
            isLoading,
            loadSummaryTask,
            summaryTask,

        } = useTasks()

        loadSummaryTask()

        return {
            isLoading,
            summaryTask,

            categoryProgress: (category) => {
                let numTareasMes=category.finalizadasMes + category.pendientesMes
                let p = numTareasMes > 0 ? category.finalizadasMes / numTareasMes : 0
                return Math.round(p)
            }
        }
    },
}
</script>