export default () => ({
    register: {
        CA117TIPOMODELO: '',
        CA117CODMODELO: '',
        CA117VALIDACIONNOMBREREGISTRADURIA: '',
        CA117VALIDACIONLUGAREXPEDICION: '',
        CA117VALIDACIONESTADOREGISTRADURIA: '',
        CA117COMENTARIOSREGISTRADURIA: '',
        CA117NUMCERTIFICADOREGISTRADURIA: '',
        CA117TIENEVEHICULO:'',
        CA117VALIDACIONPLACAVEHICULO: '',
        CA117VALIDACIONMULTASTRANSITO: '',
        CA117COMENTARIOSRUNT: '',
        CA117VALIDACIONRESPONSABIDADFISCAL: '',
        CA117NUMCERTIFICADOCONTRALORIA: '',
        CA117COMENTARIOSCONTRALORIA: '',
        CA117VALIDACIONNOMBREPOLICIA: '',
        CA117VALIDACIONANTECEDENTES: '',
        CA117COMENTARIOSPOLICIA: '',
        CA117VALIDACIONNOMBREDATACREDITO: '',
        CA117INDICADORPAGO: '',
        CA117CANTPAGOSINMORA: '',
        CA117CANTPAGO30MORA: '',
        CA117CANTPAGO60MORA: '',
        CA117CANTPAGOMAYOR90MORA: '',
        CA117CANTSINPAGO: '',
        CA117VLRVALORCUOTAACTUAL: '',
        CA117CANTCREDITOSCERRADOS: '',
        CA117VLRSALDOMORA: '',
        CA117CANTCONSULTASMES: '',
        CA117COMENTARIOSDATACREDITO: '',
        CA117VLRAPROBADODATACREDITO: '',
        CA117CODREDENCION: '',
        CA117COMENTARIOSDATACREDITOT4C: '',
        CA117COINCIDENCIAOFAC: '',
        CA117COMENTARIOSOFAC: '',
    },
    isLoading: false,
    isLoadingRegister: false,
    isLoadingOperationRegister: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: ''
})