<template>
    <div class="dropdown">
        <button class="btn dropdown-toggle" :class="classButton" type="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            <!-- <i :class="icon"></i> -->
            <i class="fa fa-ellipsis-h"></i>
        </button>
        <ul class="dropdown-menu">
            <li v-for="item in dropdownItem" :key="dropdownItem[item]">
                <a v-if="checkPermission(item.permissionId) && item.visible" href="#" class="dropdown-item"
                    @click="item.action">{{ item.label }}</a>
            </li>
        </ul>
    </div>
</template>

<script>

import useAuth from '@/modules/auth/composables/useAuth';
export default {

    props: {

        type: {
            type: String,
            default: 'button'
        },
        classButton: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        dropdownItem: {
            type: Object,
            default: [{ label: 'Editar', action: () => { }, permissionId: 0, visible: true }, { label: 'Eliminar', action: () => { }, permissionId: 0, visible: true }]
        },
    },
    setup() {
        const { checkPermission } = useAuth()


        return {
            checkPermission
        }

    },
}
</script>