import store from "@/store";

export const orderArray = (array, column, order = 'asc') => {
    let newArray = array.sort((x, y) => {
        let comparison = 0;
        if (typeof x[column] === 'string' && typeof y[column] === 'string') {
            comparison = x[column].localeCompare(y[column]);
        } else if (typeof x[column] === 'number' && typeof y[column] === 'number') {
            comparison = x[column] - y[column];
        }

        return order === 'asc' ? comparison : -comparison;
    });
    return newArray
}

export const mensajeErrorStore = (modulo) => {
    return JSON.stringify(store.state[modulo]['alert']['messageServer']) + ' ' + JSON.stringify(store.state[modulo]['alert']['errorsFields'])
}
export const mostrarPasoRojo = (Swal, currentStepDecision) => {
    currentStepDecision > 0 ? currentStepDecision += 1 : null
    Swal.getProgressSteps().children[currentStepDecision].style.backgroundColor = '#f50606'
}

export const mostrarBotonesSwal = (Swal) => {
    Swal.getConfirmButton().style.display = "block";
    Swal.getCloseButton().style.display = "block";
}

export const procesarPaso = async (Swal, titulo, texto, paso, accion, errorMensaje, modulo) => {
    Swal.update({
        currentProgressStep: paso,
        title: titulo,
        text: texto,
    });
    Swal.getConfirmButton().style.display = "none";

    const resultado = await accion();
    if (!resultado) throw new Error(errorMensaje + mensajeErrorStore(modulo, Swal));
}

export const finalizarProceso = (Swal, mensaje, tipoMensaje) => {
    mostrarBotonesSwal(Swal);
    Swal.hideLoading();
    const opciones = {
        title: tipoMensaje,
        text: mensaje,
        icon: tipoMensaje == 'OK' ? 'success' : 'error',
    }
    tipoMensaje == 'error' ? opciones.progressSteps = '' : null
    Swal.update(opciones);
}