<template>
    <div class="custom-avatar" :class="'custom-avatar' + '-' + avatarSize" :data-label="getInitialsName(name)"></div>
</template>

<script>
import useAuth from '@/modules/auth/composables/useAuth'
export default {

    props: {
        name: {
            type: String,
            default: ''
        },
        avatarSize: {
            type: String,
            default: 'm'//m, l o xl
        }
    },

    setup() {
        return {
            getInitialsName: (name = '') => {
                const arrayName = name.split(' ')
                let initialsName = ''
                arrayName.forEach(n => {
                    initialsName = initialsName + n[0]
                });
                return initialsName;
            },
        }
    }

}
</script>

<style scoped>
.custom-avatar {
    width: 3em;
    height: 3em;
    border-radius: 50%;
    border: 1px solid #C8C5C4;
    background: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;

}

.custom-avatar.custom-avatar-m {
    font-size: 16px;

}

.custom-avatar.custom-avatar-l {
    font-size: 35px;

}

.custom-avatar.custom-avatar-xl {
    font-size: 60px;

}

.custom-avatar::after {
    content: attr(data-label);
    font-family: 'Readex Pro', sans-serif;
    color: black;
    text-transform: uppercase;
}
</style>