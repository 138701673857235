import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const loadNotificationList = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/notificaciones/index`, formData)
        commit('setNotificationList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'notifications', error: error,typeProcess:'list' })
    }
}
export const markAsRead = async ({ commit }, { notificationId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/notificaciones/marcarComoLeida/${notificationId}`)
        commit('updateNotificationList', { notificationId })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:'list'  })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'notifications', error: error,typeProcess:'list' })
    }
}

