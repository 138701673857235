<template>
  <TheContentHeader titlePage="Parametrizacion de Objetivos" />
  <the-content-body>
    <widget-card title="Objetivos" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Objetivos</div>
          <base-button-fab @on:click="openModal('newObjetive')" :permissionId="24"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
          :typeAlert="alert.typeAlert"></widget-alerts>
        <base-input-group :icon="'fa-solid fa-magnifying-glass'" v-model="formData.search"
          :onBlur="callLoadObjetiveIndex"></base-input-group>
        <widget-loading v-if="isLoading.list" />
        <template v-else-if="objetivesList.data.length > 0">
          <widget-select-paginator v-model="formData.numPages" :onChange="callLoadObjetiveIndex"></widget-select-paginator>
          <base-table-forms :data="objetivesList" :dataFunction="callLoadObjetiveIndex" :headers="headers"
            :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
            <tr v-for="objetive in objetivesList.data" :key="objetive.CA008CODOBJETIVO">
              <td class="text-center">{{ objetive.CA008CODOBJETIVO }}</td>
              <td class="text-center">{{ objetive.CA008NOMBREOBJETIVO }}</td>
              <td class="text-center">{{ objetive.CA008X100PESO }}</td>
              <td class="text-center">
                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
            { label: 'Editar', action: () => { openModal(objetive.CA008CODOBJETIVO.toString()) }, permissionId: 24, visible: true },
          ]"></widget-dropdown>
              </td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <widget-modal-boostrap modalId="modalObjetive"
    :modalTitle="objetiveId === 'newObjetive' ? 'Crear Objetivo' : 'Actualizar Objetivo'" modalClassSize="modal-lg"
    :showFooter="false">
    <template v-slot:modalBody>
      <widget-loading v-if="isLoading.objetive" />
      <form v-else @submit.prevent="onSubmit">
        <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
          :typeAlert="alert.typeAlert"></widget-alerts>
        <div class="form-group row">
          <!-- Nombre Objetivo -->
          <div class="col-6">
            <base-input-forms v-model="CA008NOMBREOBJETIVO" :errors="alert.errorsFields" title="Nombre"
              inputId="CA008NOMBREOBJETIVO"></base-input-forms>
          </div>
          <!-- Peso -->
          <div class="col-6">
            <base-input-forms v-model="CA008X100PESO" :errors="alert.errorsFields" title="Peso" inputId="CA008X100PESO"
              typeInput="number"></base-input-forms>
          </div>
        </div>
        <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoading.operation"
          :disabled="isLoading.operation" label="Guardar" :permissionId="24"></base-button-forms>
      </form>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { Modal } from 'bootstrap';
import { useStore } from 'vuex'
import { Toast } from "@/helpers/toastSwalert"

import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';


import useObjetive from '@/modules/parameterization/objetives/composables/useObjetive'


export default {
  components: {
    BaseButtonFab,
    BaseInputForms,
    BaseTableForms,
    BaseButtonForms,
    BaseInputGroup,
    TheContentBody,
    TheContentHeader,
    WidgetAlerts,
    WidgetCard,
    WidgetDropdown,
    WidgetLoading,
    WidgetModalBoostrap,
    WidgetSelectPaginator,


  },

  setup() {
    const objetiveId = ref('newObjetive')
    const formData = ref({
      search: '',
      numPages: '5',
      page: 1,
      column: 'CA008CODOBJETIVO',
      order: 'asc'

    })

    const headers = ref([
      { encabezado: 'ID', columna: 'CA008CODOBJETIVO' },
      { encabezado: 'Nombre', columna: 'CA008NOMBREOBJETIVO' },
      { encabezado: 'Peso', columna: 'CA008X100PESO' },
      { encabezado: 'Acciones', columna: null },

    ])
    const modals = ref({ modalObjetive: null })
    const store = useStore()

    const {
      isLoading,
      alert,
      loadObjetiveIndex,
      objetivesList,
      createObjetive,
      getObjetive,
      updateObjetive,
      objetive
    } = useObjetive()


    onMounted(() => {
      modals.modalObjetive = new Modal('#modalObjetive', {})
    })
    const callLoadObjetiveIndex = (page,column) => {
      formData.value.page = page
      if (column) {
        formData.value.column = column
        formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
      }
      loadObjetiveIndex(formData.value)
    }
    callLoadObjetiveIndex()

    const openModal = async (id) => {
      store.commit('objetives/clearObjetive')
      objetiveId.value = id
      modals.modalObjetive.show()
      if (id != 'newObjetive') {
        await getObjetive(id)
      }
    }
    const closeModal = () => {
      modals.modalObjetive.hide()
    }


    return {
      objetiveId,
      objetive,
      objetivesList,
      createObjetive,
      getObjetive,
      updateObjetive,
      alert,
      isLoading,
      callLoadObjetiveIndex,
      formData,
      headers,

      openModal,
      closeModal,

      CA008NOMBREOBJETIVO: computed({
        get: () => store.state['objetives']['objetive']['CA008NOMBREOBJETIVO'],
        set: (val) => store.commit('objetives/updateField', { field: 'CA008NOMBREOBJETIVO', value: val })
      }),
      CA008X100PESO: computed({
        get: () => store.state['objetives']['objetive']['CA008X100PESO'],
        set: (val) => store.commit('objetives/updateField', { field: 'CA008X100PESO', value: val })
      }),
      onSubmit: async (e) => {
        const formData = new FormData(e.target)
        if (objetiveId.value === 'newObjetive') {
          const { response } = await createObjetive(formData)
          if (response) {
            Toast.fire({
              icon: 'success',
              title: 'Objetivo Creado'
            })
            openModal(objetivesList.value.data[0].CA008CODOBJETIVO)
          }
        } else {
          const { response } = await updateObjetive(objetiveId.value, formData)
          if (response) {
            Toast.fire({
              icon: 'success',
              title: 'Objetivo Actualizado'
            })
          }
        }
      },


    }
  }

}
</script>

<style></style>