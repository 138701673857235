import { isAuthenticatedGuard, hasActiveShopAllied,hasChangedPassword } from '@/guard/auth-guard'
import CreditRequestList from '../views/CreditRequestList.vue'
export default{
    name:'credit-request',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,hasActiveShopAllied],
            meta:{permission:36,showNav: false},
            name:'credit-index',
            component:CreditRequestList
        },
    ]
    
}