
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

import validatorLang from '@/helpers/validatorLang'



const useLists = () => {
    const store = useStore()

    const numPages = ref("5")
    const listId = ref('newList')
    const search = ref('')
    const modals = ref({ modalUser: null })

    const { customRequired } = validatorLang()


    const formData = ref({
        tipoLista: '',
        // codLista: '',
        descripcionLista: '',
        aliasLista: '',
        comentarios: '',
        listaDependiente: '',
        codListaDependiente: '',
        estado: '',
    })

    const rules = {
        tipoLista: { required: customRequired },
        // codLista: { required: customRequired },
        descripcionLista: { required: customRequired },
        aliasLista: { required: customRequired },
        comentarios: { required: customRequired },
    }
    const v$ = useVuelidate(rules, formData)


    const loadListsIndex = async (formData) => {
        const resp = await store.dispatch('parameterization/loadListsIndex', formData)
        return resp
    }
    const loadPrincipalList = async () => {
        const resp = await store.dispatch('parameterization/loadPrincipalList')
        return resp
    }
    const createList = async (formData) => {
        const resp = await store.dispatch('parameterization/createList', formData)
        return resp
    }
    const getList = async (listId) => {
        const resp = await store.dispatch('parameterization/getList', listId)
        return resp
    }
    const updateList = async (formData, listId) => {
        const resp = await store.dispatch('parameterization/updateList', { listId, formData })
        return resp
    }
    const setErrorsFielFrontend = (errors) => {
        const resp = store.dispatch('parameterization/setErrorsFielFrontend', errors)
    }

    const getListSelect = (typeLists, dependentList = '', codDependentList = '') => {
        return store.getters['parameterization/getListSelect'](typeLists, dependentList, codDependentList)
    }


    return {
        formData,
        listId,
        modals,
        numPages,
        search,
        rules,
        v$,
        setErrorsFielFrontend,

        loadListsIndex,
        loadPrincipalList,
        createList,
        getList,
        updateList,
        getListSelect,



        listsIndex: computed(() => store.state['parameterization']['listsIndex']),
        principalList: computed(() => store.state['parameterization']['principalList']),
        isLoading: computed(() => store.state['parameterization']['isLoading']),
        isLoadingList: computed(() => store.state['parameterization']['isLoadingList']),
        isLoadinOperationlist: computed(() => store.state['parameterization']['isLoadinOperationlist']),
        messageServer: computed(() => store.state['parameterization']['messageServer']),
        errorsFields: computed(() => store.state['parameterization']['errorsFields']),
        showAlert: computed(() => store.state['parameterization']['showAlert']),
        typeAlert: computed(() => store.state['parameterization']['typeAlert']),

        codigosListaDependiente: computed(() => store.getters['parameterization/getListSelect'](formData.value.listaDependiente, '', ''))




    }
}

export default useLists