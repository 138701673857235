import { isAuthenticatedGuard, isActivateShift,hasChangedPassword } from '@/guard/auth-guard'
import CreditStudyList from '../views/CreditStudyList.vue'
export default{
    name:'credit-study',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isActivateShift],
            name:'credit-study-index',
            meta:{permission:41,showNav: false},
            component:CreditStudyList
        },
    ]
    
}