import { watch } from 'vue';
<template>
    <div class="web-camera-container">
        <div class="camera-button">
            <!-- Boton para cerrar y abrir cámara -->
            <!-- <button type="button" class="button is-rounded"
                :class="{ 'is-primary': !isCameraOpen, 'is-danger': isCameraOpen }" @click="toggleCamera">
                <span v-if="!isCameraOpen">Open Camera</span>
                <span v-else>Close Camera</span>
            </button> -->
        </div>

        <div v-show="isCameraOpen && isLoading" class="camera-loading">
            <ul class="loader-circle">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>

        <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash': isShotPhoto }">

            <div class="camera-shutter" :class="{ 'flash': isShotPhoto }"></div>

            <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
            <!-- Silueta selfie -->
            <img v-if="codArchivo==='5'" src="/assets/img/camera/silueta6.png" alt="" class="guie-selfie">
            <!-- Silueta documento -->
            <div v-else class="guie-document"></div>

            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
        </div>

        <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <button type="button" class="button" @click="takePhoto">
                <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
            </button>
        </div>

        <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">
            <!-- <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">
                Download
            </a> -->
            <a id="downloadPhoto" class="button" role="button" @click="onUploadFile">
                Guardar captura
            </a>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import Swal from 'sweetalert2'

import useFiles from '@/modules/files/composables/useFiles'

export default {

    props: {
        tipoModelo: {
            default: ''
        },
        codModelo: {
            default: ''
        },
        tipoAliado: {
            default: ''
        },
        ocupacionCliente: {
            default: ''
        },
        estudioCategoria: {
            default: ''
        },
    },

    setup(props) {

        const isCameraOpen = ref(false)
        const isPhotoTaken = ref(false)
        const isShotPhoto = ref(false)
        const isLoading = ref(false)
        const link = ref('#')
        const camera = ref(null)
        const canvas = ref(null)
        const codArchivo = ref(null)
        const { uploadFile } = useFiles()

        const toggleCamera = (codigoArchivo) => {
            codArchivo.value = codigoArchivo
            if (isCameraOpen.value) {
                isCameraOpen.value = false
                isPhotoTaken.value = false
                isShotPhoto.value = false
                stopCameraStream()
            } else {
                isCameraOpen.value = true;
                createCameraElement();
            }
        }

        const createCameraElement = () => {
            isLoading.value = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true
            });


            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    isLoading.value = false;
                    camera.value.srcObject = stream;

                })
                .catch(error => {
                    isLoading.value = false;
                    alert("May the browser didn't support or there is some errors.");
                });
        }

        const stopCameraStream = () => {
            let tracks = camera.value.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            });

        }

        const takePhoto = () => {
            if (!isPhotoTaken.value) {
                isShotPhoto.value = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    isShotPhoto.value = false;
                }, FLASH_TIMEOUT);
            }

            isPhotoTaken.value = !isPhotoTaken.value;

            const context = canvas.value.getContext('2d');
            context.drawImage(camera.value, 0, 0, 450, 337.5);
        }

        const downloadImage = () => {
            const download = document.getElementById("downloadPhoto");
            const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");
            download.setAttribute("href", canvas);
        }
        const onUploadFile = async () => {
            new Swal({
                title: 'Subiendo archivo...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");
            if (canvas) {
                const form = new FormData();
                form.append('file', canvas)
                form.append('tipoModelo', props.tipoModelo)
                form.append('codModelo', props.codModelo)
                form.append('tipoArchivo', codArchivo.value)
                const { response } = await uploadFile(form, true)
                if (response) {
                    Swal.fire('Archivo subido', '', 'success')
                    return
                }
                Swal.fire('Ha ocurrido un error', '', 'error')
            }
        }

        return {
            isCameraOpen,
            isPhotoTaken,
            isShotPhoto,
            isLoading,
            link,
            camera,
            canvas,
            codArchivo,

            toggleCamera,
            downloadImage,
            takePhoto,
            onUploadFile




        }


    },
}
</script>

<style lang="scss" scoped>
body {
    display: flex;
    justify-content: center;
}

.guie-document {
    background-color: transparent;
    border: 19px dashed black;
    height: 50%;
    position: absolute;
    right: 5%;
    text-align: center;
    top: 20%;
    width: 90%;
    z-index: 1;
}

.guie-selfie {
    width: 62%;
    height: 54%;
    right: 18%;
    top: 17%;
    color: #fff;
    text-align: center;
    position: absolute;
    z-index: 1;
}

// .guie-selfie-body{
//     border-radius: 100px 100px 11px 12px;
//     border: 19px dashed black;
//     position: absolute;
//     right: 9%;
//     text-align: center;
//     top: 48%;
//     width: 81%;
//     z-index: 1;
//     height: 23%;
// }
// .guie-selfie-face{
//     border-radius: 86%;
//     border: 5px dashed black;
//     position: absolute;
//     right: 33%;
//     text-align: center;
//     top: 18%;
//     width: 31%;
//     z-index: 1;
//     height: 24%;
// }

.web-camera-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 500px;


    .camera-button {
        margin-bottom: 2rem;
    }

    .camera-box {
        .camera-shutter {
            opacity: 0;
            width: 450px;
            height: 337.5px;
            background-color: #fff;
            position: absolute;

            &.flash {
                opacity: 1;
            }
        }
    }

    .camera-shoot {
        margin: 1rem 0;

        button {
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            img {
                height: 35px;
                object-fit: cover;
            }
        }
    }

    .camera-loading {
        overflow: hidden;
        height: 100%;
        position: absolute;
        width: 100%;
        min-height: 150px;
        margin: 3rem 0 0 -1.2rem;

        ul {
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 999999;
            margin: 0;
        }

        .loader-circle {
            display: block;
            height: 14px;
            margin: 0 auto;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            transform: translateX(-50%);
            position: absolute;
            width: 100%;
            padding: 0;

            li {
                display: block;
                float: left;
                width: 10px;
                height: 10px;
                line-height: 10px;
                padding: 0;
                position: relative;
                margin: 0 0 0 4px;
                background: #999;
                animation: preload 1s infinite;
                top: -50%;
                border-radius: 100%;

                &:nth-child(2) {
                    animation-delay: .2s;
                }

                &:nth-child(3) {
                    animation-delay: .4s;
                }
            }
        }
    }

    @keyframes preload {
        0% {
            opacity: 1
        }

        50% {
            opacity: .4
        }

        100% {
            opacity: 1
        }
    }
}
</style>
