import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setChatList = (state, { data }) => {
    state.chatList = data
    // state.chatParameters = data.parametros
}
export const setChatsNavbar = (state, { data }) => {
    state.chatsNavbar = data
}
export const addChat = (state, { data }) => {
    state.chatList.unshift(data)
}

export const updateField = (state, { field, value }) => {
    state.chat[field] = value;
}
export const upDateChatList = (state, { data }) => {
    const idx = state.chatList.map(c => c.CA202CODCHAT).indexOf(data.chat.CA202CODCHAT)
    console.log(idx);
    if (idx>=0) {
        state.chatList[idx] = data.chat
    } else {
        state.chatList.push(data.chat)
    }
}
export const closeChat = (state, chatId) => {
    state.chatList = state.chatList.filter(r => r.CA202CODCHAT != chatId)
}

export const clearChat = (state) => {
    for (const property in state.chat) {
        state.chat[property] = ''
    }
}


export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'chatList':
            state.isLoading = true
            break;
        case 'chat':
            state.isLoadingChat = true
            break;
        case 'operation':
            state.isLoadingOperationChat = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingChat = false
    state.isLoadingOperationChat = false
    state.messageServer = messageServer
    state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }

}