export default () => ({
    customerList: {},
    customer: {

    },
    isLoading: {
        customer: false,
        list: false,
        operation: false
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: ''
    },
    customerParameters: {}
})