<template>
    <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge">{{ notificationList.length }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="max-height: 280px; overflow-y: auto;">
            <span class="dropdown-header">{{ notificationList.length }} Notificaciones</span>
            <div class="dropdown-divider"></div>
            <div v-for="notification in notificationList" :key="notification.id">
                <a href="#" @click="onMarkAsRead(notification.id)" class="dropdown-item">
                    <widget-spinner v-if="isLoading[notification.id]" :typeSpinner="4"></widget-spinner>
                    <i class="fas fa-envelope mr-2"></i>{{ titleNotification(notification).substring(0,20) }}
                    <span class="float-right text-muted text-sm">{{ getDiffForHumans(notification.created_at) }}</span>

                </a>
                <div class="dropdown-divider"></div>
            </div>
            <a href="#" @click="$router.push({name:'notification-index'})" class="dropdown-item dropdown-footer">Ver todas las Notificaciones</a>
        </div>
    </li>
</template>

<script>
import useNotification from '@/modules/notification/composables/useNotification'
import WidgetSpinner from '@/common/widgets/WidgetSpinner.vue'
import useAuth from '@/modules/auth/composables/useAuth'
import { ref } from 'vue'


export default {

    components: {
        WidgetSpinner
    },

    setup() {
        const isLoading = ref({})
        const {
            loadNotificationList,
            notificationList,
            markAsRead,
            getDiffForHumans,
            titleNotification
        } = useNotification()
        const {  userDataLoggedIn } = useAuth()
        

        loadNotificationList()

         /**Escuchar las notificaciones */
         window.Echo.private(`App.Models.User.${userDataLoggedIn.value.id}`)
            .notification((notification) => {
              
                loadNotificationList()
            });

        return {
            notificationList,
            isLoading,


            onMarkAsRead: async (notificationId) => {
                isLoading.value[notificationId] = true
                await markAsRead(notificationId)
                isLoading.value[notificationId] = false

            },
            getDiffForHumans,
            titleNotification
           
        }
    }

}
</script>

<style lang="css" scoped src="../css/adminlte.css"></style>