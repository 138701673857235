export default () => ({
    fase2decision: {},
    objetiveResult: {},
    isLoading: false,
    isLoadingOperationQualification: false,
    isLoadingOperationQuestion: false,
    isLoadingOperationRequest: false,
    isLoadingQualification: false,
    isLoadingQuestions: false,
    isLoadingRequest: false,
    questions: {},
    references: {},
    request: {},
    centralInformationValidation: {},
    requestsLists: {
        enGestion: {},
        estadisticas: {
            analistas_activos: [],
            analistas_ausentes: [],
            analistas_cerrados: [],
            analistas_todos: [],
            promedio_atendidas: [],
            solicitudes_atentidas: [],
            solicitudes_en_proceso: [],
            solicitudes_nuevas: [],
            solicitudes_pendientes: [],
        }
    },
    alert: {
        showAlert: false,
        typeAlert: '',
        errorsFields: {},
        messageServer: null,
    }
})