import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone, groupBy } = inputFormat()

export const setAvailable = (state, { data }) => {
    state.available = data
}
export const setArticles = (state, { data }) => {
    state.articles = data
}
export const setDataTicket = (state, { data }) => {
    state.dataTicket = data
}
export const setSales = (state, { data }) => {

    let nuevoDatosCompras = []
    let comprasAgrupadas = []
    let comprasOrganizadas = []
    //Crear un nuevo objeto con las compras
    for (let key1 in data['Compras']) {
        for (let key2 in data['Compras'][key1]) {
            for (let index = 0; index < data['Compras'][key1][key2].length; index++) {
                let compra = data['Compras'][key1][key2][index];
                nuevoDatosCompras.push(compra);
            }
        }
    }
    //Agrupar por compras
    comprasAgrupadas = groupBy(nuevoDatosCompras, 'codCompra');
    //Organizar compras
    for (let key1 in comprasAgrupadas) {
        //Obtener los datos de la compra
        let fechaSistema = comprasAgrupadas[key1][0]['fechaSistema'];
        let fechaCompra = inputDate(comprasAgrupadas[key1][0]['fechaCompra'], 2);
        let almacen = comprasAgrupadas[key1][0]['codAlmacen'];
        let estadoCompra = comprasAgrupadas[key1][0]['flagReversion'];
        let valorCompra = 0;
        //Calcular el valor de la compra
        for (let index = 0; index < comprasAgrupadas[key1].length; index++) {
            valorCompra += comprasAgrupadas[key1][index]['valorCapital']
        }

        let compra = { codCompra: key1, fechaCompra: fechaCompra, valorCompra: inputCurrence(valorCompra, 0, 'state'), almacen: almacen, estadoCompra: estadoCompra, detalleCompra: comprasAgrupadas[key1] }
        comprasOrganizadas.push(compra)
    }

    state.sales.sales = comprasOrganizadas
    state.sales.cliente = data['OK']['DatosPersonales']
}
export const saleReverse = (state, { codCompra }) => {
    const idx = state.sales.sales.map(s => s.codCompra).indexOf(codCompra)
    state.sales.sales[idx].estadoCompra = 'R'
}
export const setAccountStatus = (state, { data }) => {
    state.accountStatus = data
}
export const setPayments = (state, { data }) => {
    state.payments = data.OK.pagosCliente
}
export const paymentReverse = (state, { codPago }) => {
    const idx = state.payments.map(p => p.secPago).indexOf(codPago)
    state.payments[idx].estadoPago = 'R'
}
export const clear = (state) => {
    state.available = {}
    state.sales = {}
    state.accountStatus = {}
    state.payments = {}
}


export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'cash':
            state.isLoading = true
            break;
        case 'operation':
            state.isLoadingOperationCasher = true
            break;
        case 'ticket':
            state.isLoadingTicket = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingCasher = false
    state.isLoadingOperationCasher = false
    state.isLoadingTicket = false
    state.messageServer = messageServer
    // state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }

}