<template>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NombreEmpresaTrabajo" inputId="Ca111NombreEmpresaTrabajo" :errors="errorsFields"
                title="Nombre empresa" typeInput="text"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111CantMesesTrabajando" inputId="Ca111CantMesesTrabajando" :errors="errorsFields"
                title="Tiempo trabajando en meses" typeInput="text" typeData="digit"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111Cargo" inputId="Ca111Cargo" :errors="errorsFields" title="Cargo desempeñado"
                typeInput="text"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-select-list v-model="Ca111TipoContrato" idSelect="Ca111TipoContrato" :errors="errorsFields" tipoLista="TPCTO"
                listaPadre="" listaPadreId="" title="Tipo contrato">
            </base-select-list>
        </div>
    </div>
    <div class="form-group row">
        <base-select-list-location 
                v-model:modelValueCountry="Ca111CodPaisEmpresa" 
                v-model:modelValueState="Ca111CodigoDptoEmpresa" 
                v-model:modelValueCity="Ca111CodCiudadEmpresa" 
                idSelectCountry="Ca111CodPaisEmpresa"
                idSelectState="Ca111CodigoDptoEmpresa"
                idSelectCity="Ca111CodCiudadEmpresa"
                titleCountry="País empresa"
                titleState="Departamento empresa"
                titleCity="Ciudad empresa"
                :errors="errorsFields" 
            >
            </base-select-list-location>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111BarrioEmpresa" inputId="Ca111BarrioEmpresa" :errors="errorsFields"
                title="Barrio empresa" typeInput="text"></base-input-forms>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111DireccionEmpresa" inputId="Ca111DireccionEmpresa" :errors="errorsFields" title="Dirección empresa"
                typeInput="text">
            </base-input-forms>
        </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111TelefonoFijoEmpresa" inputId="Ca111TelefonoFijoEmpresa" :errors="errorsFields" title="Teléfono fijo empresa"
                typeInput="text" typeData="phone">
            </base-input-forms>
        </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111TelefonoCelularEmpresa" inputId="Ca111TelefonoCelularEmpresa" :errors="errorsFields" title="Teléfono celular empresa"
                typeInput="text" typeData="phone">
            </base-input-forms>
        </div> 
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111NombreEmpresaTrabajo: computed({
                get: () => store.state['request']['request']['Ca111NombreEmpresaTrabajo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreEmpresaTrabajo', value: val })
            }),
            Ca111CantMesesTrabajando: computed({
                get: () => store.state['request']['request']['Ca111CantMesesTrabajando'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CantMesesTrabajando', value: val })
            }),
            Ca111Cargo: computed({
                get: () => store.state['request']['request']['Ca111Cargo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111Cargo', value: val })
            }),
            Ca111TipoContrato: computed({
                get: () => store.state['request']['request']['Ca111TipoContrato'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoContrato', value: val })
            }),
            Ca111CodPaisEmpresa: computed({
                get: () => store.state['request']['request']['Ca111CodPaisEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodPaisEmpresa', value: val })
            }),
            Ca111CodigoDptoEmpresa: computed({
                get: () => store.state['request']['request']['Ca111CodigoDptoEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodigoDptoEmpresa', value: val })
            }),
            Ca111CodCiudadEmpresa: computed({
                get: () => store.state['request']['request']['Ca111CodCiudadEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodCiudadEmpresa', value: val })
            }),
            Ca111BarrioEmpresa: computed({
                get: () => store.state['request']['request']['Ca111BarrioEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111BarrioEmpresa', value: val })
            }),
            Ca111DireccionEmpresa: computed({
                get: () => store.state['request']['request']['Ca111DireccionEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DireccionEmpresa', value: val })
            }),
            Ca111TelefonoFijoEmpresa: computed({
                get: () => store.state['request']['request']['Ca111TelefonoFijoEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoFijoEmpresa', value: val })
            }),
            Ca111TelefonoCelularEmpresa: computed({
                get: () => store.state['request']['request']['Ca111TelefonoCelularEmpresa'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoCelularEmpresa', value: val })
            }),
        }

    },
}
</script>