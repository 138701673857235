import { isAuthenticatedGuard,isNotAuthenticatedGuard } from '@/guard/auth-guard'
export default {
    name: 'auth',
    component: () => import(/* webpackChunkName: "Auth Layaout" */ '@/modules/auth/layouts/AuthLayout'),
    children: [
        {
            path: '',
            beforeEnter:[isNotAuthenticatedGuard],
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ '@/modules/auth/views/AuthLogin')
        },
        {
            path: '/link-reset-password',
            beforeEnter:[isNotAuthenticatedGuard],
            name: 'link-reset-password',
            component: () => import(/* webpackChunkName: "Link Reset Password" */ '@/modules/auth/views/LinkResetPassword')
        },
        {
            path: '/reset-password/:token',
            beforeEnter:[isNotAuthenticatedGuard],
            name: 'reset-password',
            component: () => import(/* webpackChunkName: "Reset Password" */ '@/modules/auth/views/ResetPassword'),
            props: (router) => {
                return {
                    token: router.params.token
                }
            }
        },
    ]



}