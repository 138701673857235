/**
 * Realiza las peticiones al servidor
 * Muestra las respuesta del servidor
 */
import axios from 'axios'
import { Toast } from '@/helpers/toastSwalert'
import Swal from 'sweetalert2'
import { apiUrl } from '@/helpers/accesoVariables';

const requestApi = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    withXSRFToken : true
})

requestApi.interceptors.response.use((response) => {
    responseSuccess(response)
    return response
}, (error) => {
    console.log(error)
    responseError(error)
    return Promise.reject(error);

})


/**
 * Muestra mensaje cuando la respuesta del servidor es satisfactoria
 * @param {*} response Respuesta del servidor 
 */
const responseSuccess = (response) => {
    if (!response.config.url.includes('csrf')) {
        const { level, message, type } = response.data
        //Definir el nivel de invasión del mensaje
        switch (level) {
            case 1:
                Swal.fire({ html: message, icon: type, showCloseButton: true })
                break;
            case 2:
                Toast.fire({ html: message, icon: type, showCloseButton: true });
                break;
            default:
                break;
        }
    }
}

const responseError = (error) => {
    let response = error.response
    if (response) {
        let titleError = `Error ${response.status} ${response.statusText}`;
        let mensaje = ''
        let nivel = error.response.data.level ? error.response.data.level : 2
        //Organizar el mensaje a mostrar

        if (response.status === 422) {
            mensaje = response.data.message

        } else {
            let errors = response.data.errors ? response.data.errors : response.data.Error
            if (errors) {
                //Si es del usuario
                for (let key in errors) {
                    mensaje = mensaje + `<li>${key}: ${errors[key]}</li>`;
                }
            } else {
                //Si es del código servidor
                mensaje = `<li>Error: ${response.status} ${response.statusText}</li><li>DetalleError: ${response.data.message}</li>`;
            }
        }
        //Definir el nivel de invasión del mensaje
        switch (nivel) {
            case 1:
                Swal.fire({ title: titleError, html: mensaje, icon: 'error', showCloseButton: true })
                break;
            case 2:
                Toast.fire({ title: titleError, html: mensaje, icon: 'error', showCloseButton: true });
                break;
            case 3:
                break;
            default:
                Swal.fire({ title: titleError, html: mensaje, icon: 'error', showCloseButton: true })
                break;

        }
    }
}
export default requestApi
