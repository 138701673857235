export default () => ({
    productList: {},
    product: {
        categoria_producto: '',
        referencia: '',
        descripcion: '',
        almacen_producto: '',
        precio: '',
        precio_descuento: '',
        cashback: '',
        colores: '',
        tallas: '',
        fecha_ini_promocion: '',
        fecha_fin_promocion: ''
    },
    productCategorie:{},
    isLoading: false,
    isLoadingProduct: false,
    isLoadingOperationProduct: false,
    isLoadinStoreProductShop:false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: '',
    productParameters: {}
})