<template>
    <div class="progress-container">
        <div class="progress blue"
            :style="{ ...progressStyles, '--progress-r': newProgress.degR + 'deg', '--progress-l': newProgress.degL + 'deg' }">
            <span class="progress-left">
                <span class="progress-bar" :class="'border-' + color" :style="progressBarLeftStyles"></span>
            </span>
            <span class="progress-right">
                <span class="progress-bar" :class="'border-' + color" :style="progressBarRightStyles"></span>
            </span>
            <div class="progress-value" :class="'text-' + color">{{ progress }}%</div>
        </div>
        <div class="progress-text">{{ text }}</div>
    </div>
</template>

<script>
import { computed, getCurrentInstance, ref } from 'vue';
export default {

    props: {
        progress: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            required: true
        },
        text: {
            type: String,
            default: 'Probando'
        },
    },
    setup(props) {
        const progressStyles = ({
            width: `${props.width}px`,
            height: `${props.width}px`,
            lineHeight: `${props.width}px`
        });
        const progressBarRightStyles = ({
            borderTopLeftRadius: `${props.width}px`,
            borderBottomLeftRadius: `${props.width}px`,


        });

        const progressBarLeftStyles = ({
            borderTopRightRadius: `${props.width}px`,
            borderBottomRightRadius: `${props.width}px`,
        });

        const newProgress = ref({
            degR: 0,
            degL: 0
        })

        const setProgress = () => {
            let degCircle = props.progress * 3.6

            if (degCircle < 180) {
                newProgress.value.degR = degCircle
                newProgress.value.degL = 0

            } else {
                newProgress.value.degR = 180
                newProgress.value.degL = degCircle - 180
            }

        }
        setProgress()

        return {
            progressStyles,
            progressBarLeftStyles,
            progressBarRightStyles,
            newProgress
        }
    },
}
</script>
<style scoped>
.progress {
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 12px solid silver;
    position: absolute;
    top: 0;
    left: 0;
}

.progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progress .progress-left {
    left: 0;
}

.progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 12px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progress .progress-left .progress-bar {
    left: 100%;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    right: 0;
}

.progress .progress-right .progress-bar {
    left: -100%;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
    background: #ffffff;
    font-size: 24px;
    text-align: center;
    position: relative;

}

.progress-text {
    background: #ffffff;
    /* font-size: 24px; */
    text-align: center;
    position: relative;

}


.progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;

}






@keyframes loading-1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(var(--progress-r));
    }
}

@keyframes loading-2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(var(--progress-l));
    }
}

@media only screen and (max-width: 990px) {
    .progress {
        margin-bottom: 20px;
    }
}
</style>