import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import useObjetive from '../../objetives/composables/useObjetive'

const useRelationShip = () => {
    const store = useStore()
    const {loadObjetiveListSelect, objetivesSelect} =useObjetive();

    const loadRelationshipIndex = async (formData) => {
        const resp = await store.dispatch('relationship/loadRelationshipIndex', formData)
        return resp
    }

    const createRelationship = async (formData) => {
        const resp = await store.dispatch('relationship/createRelationship',formData)
        return resp
    }
    const getRelationship = async (relationshipId) => {
        const resp = await store.dispatch('relationship/getRelationship', relationshipId)
        return resp
    }
    const updateRelationship = async (relationshipId,formData) => {
        const resp = await store.dispatch('relationship/updateRelationship', {formData,relationshipId})
        return resp
    }
    const addRelevance = async (relationshipId,formData) => {
        const resp = await store.dispatch('relationship/addRelevance', {formData,relationshipId})
        return resp
    }

    return {

        loadRelationshipIndex,
        createRelationship,
        getRelationship,
        updateRelationship,
        loadObjetiveListSelect, 
        objetivesSelect,
        addRelevance,

        relationship: computed(() => store.state['relationship']['relationship']),
        relationshipList: computed(() => store.state['relationship']['relationshipList']),
        isLoading: computed(() => store.state['relationship']['isLoading']),
        alert: computed(() => store.state['relationship']['alert']),
        relationshipSelect:computed(() => store.state['relationship']['relationshipSelect']),

    }
}

export default useRelationShip