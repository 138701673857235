<template>
    <div class="card-deck fixed-chat">

        <div>
            <!-- {{ --Agregue variable collapse que se encuentra en el back para que de forma predeterminada este
                        oculto el chat-- }} -->
            <div class="card card-info direct-chat collapsed-card" id="card-chat" wire:ignore.self>
                <!-- {{ --Encabezado --}} -->
                <div class="card-header bg-primary">
                    <h3 class="card-title" id="asuntoChat">Asunto: {{ currentChat.CA202ASUNTO }}</h3>
                    <div class="card-tools">
                        <!-- {{ --Icono mensajes nuevos-- }}
                        {{ -- < span data - toggle="tooltip" title = "3 New Messages" class="badge badge-light" > 3
                            < /span> --}} -->
                        <!-- {{ --Icono minimizar-- }} -->
                        <button type="button" class="btn btn-tool" data-card-widget="collapse"
                            wire:click='validarEstadoChat'>
                            <i class="fas fa-plus" wire:ignore.self></i>
                            <!-- {{ --Coloco de forma predeterminada plus para que muestre el mas, antes estaba el menos-- }} -->
                        </button>
                        <!-- {{ --Icono Asuntos-- }} -->
                        <button type="button" class="btn btn-tool" data-toggle="tooltip" title="Asuntos"
                            data-widget="chat-pane-toggle">
                            <i class="fas fa-comments"></i>
                        </button>
                        <!-- {{ --Icono Cerrar chat-- }} -->
                        <button v-if="currentChat.CA202CODCHAT" type="button" class="btn btn-tool"
                            @click="onCloseChat(currentChat.CA202CODCHAT)"><i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body" style="height: 340px">
                    <!-- Conversations are loaded here -->
                    <!-- Lista de mensajes -->
                    <div class="direct-chat-messages" ref="containerMessageList" style="height: 100%" wire:loading.remove
                        wire:target='mensajes'>
                        <widget-loading v-if="isLoading" />
                        <!-- Message. Default to the left -->
                        <template v-else-if="chatMessage.length > 0">
                            <template v-for="(message, index) in chatMessage" :key="index">
                                <div v-if="message['envia'] != userDataLoggedIn.id" class="direct-chat-msg">
                                    <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                            <!-- Puede ir el nombre del usuario -->
                                        </span>
                                        <span class="direct-chat-timestamp float-right">
                                            {{ getDiffForHumans(message['timEnvia']) }}
                                        </span>
                                    </div>
                                    <!-- /.direct-chat-infos -->
                                    <!-- Puede ir la imagen del usuario -->
                                    <!-- <img class="direct-chat-img" src="" alt="message user image"> -->
                                    <!-- /.direct-chat-img -->
                                    <div class="direct-chat-text text-break">
                                        {{ message['mensaje'] }}
                                    </div>
                                    <!-- /.direct-chat-text -->
                                </div>
                                <!-- /.direct-chat-msg -->
                                <!-- Message to the right -->
                                <div v-else class="direct-chat-msg right">
                                    <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-right">
                                            <!-- Puede ir el nombre del usuario -->
                                        </span>
                                        <span class="direct-chat-timestamp float-left">
                                            {{ getDiffForHumans(message['timEnvia']) }}
                                        </span>
                                    </div>
                                    <!-- /.direct-chat-infos -->
                                    <!-- Puede ir la imagen del usuario -->
                                    <!-- <img class="direct-chat-img" src="" alt="message user image"> -->
                                    <!-- /.direct-chat-img -->
                                    <div v-if="message['envia'] === 1"
                                        class="direct-chat-text bg-danger text-light text-break">
                                        {{ message['mensaje'] }}
                                    </div>
                                    <div v-else class="direct-chat-text text-break bg-info">
                                        {{ message['mensaje'] }}
                                    </div>
                                    <!-- /.direct-chat-text -->
                                </div>
                            </template>
                        </template>
                        <!-- /.direct-chat-msg -->
                    </div>
                    <!-- Lista de asuntos  -->
                    <div class="direct-chat-contacts bg-info " style="height: 100%;">
                        <ul class="contacts-list">
                            <widget-loading v-if="isLoading" />
                            <template v-else>
                                <template v-for="chat in chatList" :key="chat.CA202CODCHAT">
                                    <li>
                                        <a style="cursor: pointer" wire:click="" data-widget="chat-pane-toggle"
                                            @click="getChatMessage(chat)">
                                            <!-- Imagen asunto -->
                                            <!-- <img class="contacts-list-img"
                                            src = "{{ App\Models\User::find($chat->CA202IDUSERORIGEN)->adminlte_image() }}"> -->
                                            <div class="contacts-list-info">
                                                <span class="contacts-list-name float-left" style="color: black">
                                                    {{ chat.CA202ASUNTO }}
                                                </span>
                                                <small class="contacts-list-date float-right" style="color: black">
                                                    {{ getDiffForHumans(lastMessageChat(chat.CA202MENSAJE)['timEnvia']) }}
                                                </small>
                                                <br>
                                                <span class="contacts-list-msg float-left" style="color: black">
                                                    {{ lastMessageChat(chat.CA202MENSAJE)['mensaje'] }}

                                                </span>
                                            </div>
                                            <!-- /.contacts-list-info -->
                                        </a>
                                    </li>
                                </template>
                            </template>
                            <!-- End Contact Item -->
                        </ul>
                        <!-- /.contacts-list -->
                    </div>
                    <!-- /.direct-chat-pane -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    <small v-show="userTyping">escribiendo...</small>
                    <form @submit.prevent="onSendMessageChat">
                        <div class="input-group">
                            <base-input-forms v-model="message" :errors="[]" inputId="message"
                                placeholder="Escriba el mensaje ..." typeInput="text"
                                :disabled="isLoadingOperationChat || !currentChat.CA202CODCHAT"
                                :onKeyup="sendEventTyping"></base-input-forms>
                            <span class="input-group-append">
                                <base-button-forms :disabled="isLoadingOperationChat || !message" :permissionId="62"
                                    classButton="btn-primary btn-sm" icon="fa-solid fa-paper-plane"></base-button-forms>
                            </span>
                        </div>
                    </form>
                </div>
                <!-- /.card-footer-->
            </div>
        </div>
        <!--/.direct-chat -->
    </div>
</template>

<script>
import { computed, onMounted, ref, toRef, watch } from 'vue'
import Swal from 'sweetalert2';

import BaseInputForms from '@/common/forms/BaseInputForms.vue'
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';


import useChat from '@/modules/chat/composables/useChat'
import store from '@/store';



export default {

    components: {
        BaseInputForms,
        BaseButtonForms,
        WidgetLoading,
        BaseSelectForms,
        BaseSelectList,
    },
    props: {
        tipoAsunto: {
            type: Number

        },
        asunto: {
            type: String

        }
    },

    setup(props) {
        const {
            loadChatList,
            chatList,
            chatStatus,
            chatMessage,
            sendMessageChat,
            getChatMessage,
            getDiffForHumans,
            currentChat,
            containerMessageList,
            isLoadingOperationChat,
            isLoading,
            lastMessageChat,
            startChat,
            closeChat,
            updateChatList,
            userDataLoggedIn,
            userTyping,
            sendEventTyping,
            getChatsNavbar,
        } = useChat()

        const message = ref(null)
        const tipoAsuntoCopy = toRef(props, 'tipoAsunto')
        const asuntoCopy = toRef(props, 'asunto')

        watch(asuntoCopy, async (newValue) => {
            new Swal({
                title: 'Iniciando Chat...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            const { response } = await startChat({
                tipoAsunto: tipoAsuntoCopy.value,
                asunto: asuntoCopy.value
            })
            // Abrir chat
            if (response) {
                $('#card-chat').CardWidget('expand')
                currentChat.value = chatList.value[0]
                getChatMessage(currentChat.value)
                Swal.close()
                return
            }

        })
        // Conectar a websockets
        window.Echo
            .private(`chat-tradicional.${userDataLoggedIn.value.id}`)
            .listen('.ChatEvent', (e) => {
                updateChatList(e)
            })
        window.Echo
            .private(`chat-escribiendo`)
            .listenForWhisper('typing', (e) => {
                if (e.asunto === currentChat.value.CA202ASUNTO) {
                    userTyping.value = true
                    setTimeout(() => {
                        userTyping.value = false
                    }, 2000)
                }

            })


        // Invocado al cargar el componente
        const callLoadList = async () => {
            await loadChatList({})
            currentChat.value = chatList.value[0]
            getChatMessage(currentChat.value)

        }
        callLoadList()

        return {
            chatList,
            chatStatus,
            chatMessage,
            userDataLoggedIn,
            currentChat,
            containerMessageList,
            message,
            isLoadingOperationChat,
            isLoading,
            closeChat,
            userTyping,


            lastMessageChat,
            getChatMessage,
            getDiffForHumans,
            callLoadList,
            sendEventTyping,
            onSendMessageChat: async () => {
                const { response, send, chat } = await sendMessageChat(currentChat.value.CA202CODCHAT, {
                    mensaje: message.value
                })
                if (response && send) {
                    getChatMessage(chat)
                    message.value = ''
                    getChatsNavbar()
                }

            },
            onCloseChat: async (chatId) => {
                const { isConfirmed } = await Swal.fire({
                    title: '¿Esta seguro?',
                    text: '¿Desea cerrar el chat?',
                    showDenyButton: true,
                    confirmButtonText: 'Si, estoy seguro'
                })
                if (isConfirmed) {
                    new Swal({
                        title: 'Cerrando chat',
                        allowOutsideClick: false
                    })
                    Swal.showLoading()
                    const { response } = await closeChat(chatId)
                    if (response) {
                        currentChat.value = []
                        getChatMessage(currentChat.value)
                        Swal.fire('Chat cerrado', '', 'success')
                        if (chatList.value.length === 0) {
                            $('#card-chat').CardWidget('collapse')
                        }
                        return
                    }
                    Swal.fire('Ha ocurrido un error', '', 'error')
                }

            }

        }
    }






}
</script>
<style lang="css" scoped src="../../dashboard/css/adminlte.css"></style>
<style lang="scss" scoped>

@import "@/styles/styles.scss";

.direct-chat-text {
    width: 240px;
}

.bg-primary{
    background-color: map-get($theme-colors, "primary") !important;
}

/*Fijar chat*/

.fixed-chat {
    position: fixed;
    bottom: 0;
    right: 5px;
    /* margin-right: 23px; */
    /* left: 17%; */
    /* width: 350px; */
    /* background-color: white; */
    /* height: 70%; */
}
</style>