//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    users: {
        data: {}
    },
    userEdit: {
        tipoDoc: '',
        numeroDoc: '',
        name: '',
        celular: '',
        email: '',
        username: '',
        alias: '',
        perfil: '',
        TipoIdentificacionAliado: '',
        NumIdentificacionAliado: '',
        nombreAliado: '',
    },
    userShops: {},
    isLoading: false,
    isLoadingUser: false,
    isLoadingOperationUser: false,
    isLoadingUserStore: false,
    isLoadingAddUserStore: false,
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: '',
    },
    userShopChat: {}
})