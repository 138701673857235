import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const loadRolesList = async ({ commit }, { page, numPages, search }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/roles/index?page=${page}&numPages=${numPages}&search=${search}`)
        commit('setRoleList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'roles', error: error, typeProcess:'list' })
    }
}


export const createRole = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/roles/store`, formData)
        commit('addRole', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'roles', error: error, typeProcess:'operation' })
    }
}
export const getRole = async ({ commit }, { roleId }) => {
    try {
        commit('showProcessHideAlert', 'role')
        const { data } = await requestApi.get(`/roles/getRole/${roleId}`)
        commit('setRole', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:'role' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'roles', error: error, typeProcess:'role' })
    }
}
export const updateRole = async ({ commit }, { formData, roleId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/roles/update/${roleId}?_method=PUT`, formData)
        commit('updateRole', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:'operation'  })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'roles', error: error, typeProcess:'operation' })
    }
}


