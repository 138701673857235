<template>
  <button v-if="checkPermission(permissionId)" class="btn btn-light rounded-circle" @click="$emit('on:click')">
    <i class="fa fa-1x" :class="icon"></i>
  </button>
</template>

<script>
import useAuth from '@/modules/auth/composables/useAuth';
export default {
  props: {
    icon: {
      type: String,
      default: 'fa-plus'
    },
    permissionId: {
      type: Number
    }

  },
  setup() {
    const { checkPermission } = useAuth()


    return {
      checkPermission
    }

  },


}
</script>

<style lang="scss" scoped>
// button {
//   border-radius: 100%;
//   // bottom: 20%;
//   height: 40%;
//   position: relative;
//   // right: 20px;
//   width: 3%;
// }
</style>