import { isAuthenticatedGuard, isAuthorized, hasChangedPassword } from '@/guard/auth-guard'
import RolesIndex from '../views/RolesIndex.vue'
export default {
    name: 'roles',
    component: () =>
        import( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [{
        path: '',
        beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized],
        name: 'roles-index',
        meta: { permission: 13 ,showNav: false},
        component:RolesIndex
    },]



}