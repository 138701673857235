
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

import validatorang from '@/helpers/validatorLang'



const useQuestion = () => {
    const store = useStore()

    const loadQuestionIndex = async (formData) => {
        const resp = await store.dispatch('questions/loadQuestionIndex', formData)
        return resp
    }
   
    const createQuestion = async (formData) => {
        const resp = await store.dispatch('questions/createQuestion',formData)
        return resp
    }
    const getQuestion = async (questionId) => {
        const resp = await store.dispatch('questions/getQuestion', questionId)
        return resp
    }
    const updateQuestion = async (questionId,formData) => {
        const resp = await store.dispatch('questions/updateQuestion', {formData,questionId})
        return resp
    }
    const questionSettings = async (questionId,formData,numSetting) => {
        const resp = await store.dispatch('questions/questionSettings', {formData,questionId,numSetting})
        return resp
    }
    const answerOptions = async (questionId,formData) => {
        const resp = await store.dispatch('questions/answerOptions', {formData,questionId})
        return resp
    }
    
    return {
        loadQuestionIndex,
        createQuestion,
        getQuestion,
        updateQuestion,
        questionSettings,
        answerOptions,

        question: computed(() => store.state['questions']['question']),
        questionsList: computed(() => store.state['questions']['questionsList']),
        requestFields: computed(() => store.state['questions']['requestFields']),
        isLoading: computed(() => store.state['questions']['isLoading']),
        alert: computed(() => store.state['questions']['alert']),


    }
}

export default useQuestion