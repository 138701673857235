import { computed,ref } from "vue"
import { useStore } from "vuex"

const useUsers = () => {

    const store = useStore()

    const numPages = ref("5")
    const userId = ref('newUser')
    const search=ref('')
    const modals = ref({modalList: null})

    const loadUsers = async (formData) => {
        const resp = await store.dispatch('users/loadUsers', formData)
        return resp
    }

    const createUser = async (formDataUser) => {
        const resp = await store.dispatch('users/createUser', formDataUser)
        return resp
    }
    const updateUser = async (formDataUser, userId) => {
        const resp = await store.dispatch('users/updateUser', { formDataUser, userId })
        return resp
    }
    const getUser = async (userId) => {
        const resp = await store.dispatch('users/getUser', userId)
        return resp
    }
    const getUserProfile = async (userId) => {
        const resp = await store.dispatch('users/getUserProfile', userId)
        return resp
    }
    const setErrorsFielFrontend = (errors) => {
        const resp = store.dispatch('users/setErrorsFielFrontend', errors)
    }
    const getUserShops = async (userId) => {
        const resp = await store.dispatch('users/getUserShops', userId)
        return resp
    }
    const addShopToUser = async (userId, formData) => {
        const resp = await store.dispatch('users/addShopToUser', { userId, formData })
        return resp

    }
    const removeShopToUser = async (userId, shopId) => {
        const resp = await store.dispatch('users/removeShopToUser', { userId, formData:shopId })
        return resp
    }
    const changeUserStatus = async (userId) => {
        const resp = await store.dispatch('users/changeUserStatus', { userId })
        return resp
    }
    const getUserShopChat = async (codAliado, codAlmacen) => {
        const resp = await store.dispatch('users/getUserShopChat', {codAliado, codAlmacen })
        return resp
    }

    const selectUserShop = async (formData) => {
        return await store.dispatch('users/selectUserShop', {formData })
       
    }
    const changePassword = async (formData) => {
        return await store.dispatch('users/changePassword', {formData })
       
    }


    return {
        modals,
        numPages,
        userId,
        search,
        selectUserShop,

        loadUsers,
        createUser,
        updateUser,
        getUser,
        getUserProfile,
        getUserShops,
        addShopToUser,
        removeShopToUser,
        changeUserStatus,
        setErrorsFielFrontend,
        getUserShopChat,
        changePassword,


        userLists: computed(() => store.state['users']['users']),
        userEdit: computed(() => store.state['users']['userEdit']),
        userShops: computed(() => store.state['users']['userShops']),
        isLoading: computed(() => store.state['users']['isLoading']),
        isLoadingUser: computed(() => store.state['users']['isLoadingUser']),
        isLoadingOperationUser: computed(() => store.state['users']['isLoadingOperationUser']),
        isLoadingUserStore: computed(() => store.state['users']['isLoadingUserStore']),
        isLoadingAddUserStore: computed(() => store.state['users']['isLoadingAddUserStore']),
        alert: computed(() => store.state['users']['alert']),
        userShopChat: computed(() => store.state['users']['userShopChat']),
    }
}

export default useUsers