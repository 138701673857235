export default () => ({
    shopList: {},
    shop: {
        Ca110CodAlmacen: '',
        Ca110CodAliado: '',
        Ca110NombreComercial: '',
        Ca110Alias: '',
        Ca110Pais: '',
        Ca110Depto: '',
        Ca110Ciudad: '',
        Ca110Direccion: '',
        Ca110SectorUbicacion: '',
        Ca110NombreContacto1: '',
        Ca110CargoContacto1: '',
        Ca110email1: '',
        Ca110TelefonoFijo1: '',
        Ca110Celular1: '',
        Ca110NombreContacto2: '',
        Ca110CargoContacto2: '',
        Ca110email2: '',
        Ca110TelefonoFijo2: '',
        Ca110Celular2: '',
        Ca110latitud: '',
        Ca110longitud: '',
        Ca110CantMesesLocal: '',
        Ca110TipoLocal: '',
        Ca110TamanoLocal: '',
        Ca110NegocioReconocido: '',
        Ca110BuenaReputacion: '',
        Ca110ComentariosEjecutivoComercial: '',
        Ca110DescValidacionArchivos: '',
        Ca110FlagRecaudo: '',
        Ca110Estado: '',
        Ca110Iparchivos: '',
        Ca110CodCifradoArchivo: '',
        nombreAliado:'',
        numIdAliado:'',
        tipoIdAliado:'',
        secciones:[]
    },
    isLoading: false,
    isLoadingShop: false,
    isLoadingOperationShop: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: '',
    shopParameters: {},
    shopAuthorized: {},
    simpleList:{}
})