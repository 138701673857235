<template>
  <TheContentHeader titlePage="Aliados" />
  <the-content-body>
    <widget-card title="Solicitudes" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Aliados</div>
          <base-button-fab @on:click="openModal('newAllied')" :permissionId="27"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
        <!-- Filtros de búsqueda -->
        <form @submit.prevent="callLoadAlliedList">
          <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-input-forms v-model="formData.codAliado" title="Cod. Aliado" :errors="errorsFields"
                inputId="codAliado"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-list v-model="formData.tipoPersona" idSelect="tipoPersona" :errors="errorsFields"
                tipoLista="TPTERC" title="Tipo persona"></base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-list v-model="formData.tipoIdentidad" idSelect="tipoIdentidad" :errors="errorsFields"
                tipoLista="TPIDEN" listaPadre="" listaPadreId="" title="Tipo ID Aliado"></base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-input-forms v-model="formData.numIdentidad" title="No. Id Aliado" :errors="errorsFields"
                inputId="numIdentidad" typeData="numId"></base-input-forms>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <base-button-forms classButton="btn-primary" :isLoading="isLoading" :disabled="isLoading" label="Buscar"
              :permissionId="26"></base-button-forms>
          </div>
        </form>

        <widget-loading v-if="isLoading" />
        <template v-else-if="alliedList.data">
          <!-- Resultado búsqueda -->
          <widget-select-paginator v-model="formData.numPages" :onChange="callLoadAlliedList"></widget-select-paginator>
          <base-table-forms :data="alliedList" :dataFunction="callLoadAlliedList" :headers="headers" :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
            <tr v-for="allied in alliedList.data" :key="allied.ca100CodAliado">
              <td class="text-center">{{ allied.ca100CodAliado }}</td>
              <td class="text-center">{{ allied.tipoAliado }}</td>
              <td>{{ allied.nombreAliado }}</td>
              <td class="text-center">{{ allied.tipoDoc + '-' + allied.ca100NumeroIdentidad }}</td>
              <td class="text-center">{{ alliedParameters.estados[allied.Ca100Estado][0] }}</td>
              <td>
                <widget-dropdown v-if="canEdit(allied)" icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
            { label: 'Editar', action: () => { openModal(allied.ca100CodAliado.toString()) }, permissionId: 28, visible: true },
          ]"></widget-dropdown>
              </td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <!-- Modal para crear o actualizar solicitud -->
  <widget-modal-boostrap modalId="modalAllied" :modalTitle="modalTitle"
    modalClassSize="modal-dialog-scrollable modal-xl" :showFooter="false" modalStatic="static">
    <template v-slot:modalBody>
      <allied-operation :typeOperation="alliedId" @on:createdAllied="openModal" @on:closeModal="closeModal"
        @on:LoadListAllied="callLoadAlliedList"></allied-operation>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { Modal } from 'bootstrap';
import { useStore } from 'vuex'

import AlliedOperation from '../components/AlliedOperation.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';


import useAllied from '@/modules/allied/composables/useAllied'
import useAuth from '@/modules/auth/composables/useAuth'


export default {
  components: {
    WidgetAlerts,
    AlliedOperation,
    BaseButtonFab,
    BaseButtonForms,
    WidgetCard,
    TheContentBody,
    TheContentHeader,
    WidgetDropdown,
    BaseInputForms,
    WidgetLoading,
    WidgetModalBoostrap,
    BaseSelectForms,
    BaseSelectList,
    WidgetSelectPaginator,
    BaseTableForms,



  },

  setup() {
    const formData = ref({
      codAliado: '',
      tipoPersona: '',
      tipoIdentidad: '',
      numIdentidad: '',
      numPages: '5',
      page: '',
      column:'ca100CodAliado',
      order:'asc'

    })
    const store = useStore()

    const modals = ref({ modalAllied: null })
    const alliedId = ref()
    const alliedStatus = ref()
    const headers = ref([
      { encabezado: 'ID', columna: 'ca100CodAliado' },
      { encabezado: 'Tipo aliado', columna: 'ca100TipoPersona' },
      { encabezado: 'Nombre', columna: 'nombreAliado' },
      { encabezado: 'Identificación', columna: 'tipoDoc' },
      { encabezado: 'Estado', columna: 'Ca100Estado' },
      { encabezado: 'Acciones', columna: null }
    ])
    const {
      isLoading,
      loadAlliedList,
      alliedList,
      getAllied,
      messageServer,
      showAlert,
      typeAlert,
      alliedParameters,
      errorsFields,
      allied,

    } = useAllied()

    const { checkGroupUser, userDataLoggedIn } = useAuth()


    onMounted(() => {
      modals.modalAllied = new Modal('#modalAllied', {})
    })
    const callLoadAlliedList = async (page, column) => {
      formData.value.page = page
     
      if (column) {
        formData.value.column = column
        formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
      }
      await loadAlliedList(formData.value)
    }
    callLoadAlliedList()

    return {
      alliedId,
      allied,
      alliedList,
      messageServer,
      showAlert,
      typeAlert,
      isLoading,
      alliedParameters,
      errorsFields,
      formData,
      headers,


      callLoadAlliedList,
      openModal: async (id) => {
        store.commit('allied/clearAllied')
        modals.modalAllied.show()
        alliedId.value = id
        if (id != 'newAllied') {
          await getAllied(id)
        }
      },
      closeModal: () => {
        modals.modalAllied.hide()
      },

      modalTitle: computed(() => {
        if (alliedId.value === 'newAllied') {
          return 'Crear Aliado'
        }
        return 'Actualizar Aliado No. ' + alliedId.value + ` | ${allied.value.nombreAliado} ${allied.value.ca100FechaNacimiento ? '| Fecha Nac: ' + allied.value.ca100FechaNacimiento : ''} | ${allied.value.detalleTipoIdentidad ? allied.value.detalleTipoIdentidad.nombre : ''}: ${allied.value.ca100NumeroIdentidad}`
      }),

      canEdit: (allied) => {
        if (checkGroupUser('usuario-analista', userDataLoggedIn.value.perfil)) {
          return allied.Ca100Estado === 'P'
        }
        if (checkGroupUser('usuario-comercial', userDataLoggedIn.value.perfil)) {
          return ['I', 'AP', 'AE', 'N', 'A', 'C'].includes(allied.Ca100Estado)
        }
        return false

      }

    }
  }

}
</script>

<style></style>