//Acciones asincronas que pueden llamar una mutación. 
//Estas tienen impacto en la base de datos
//Una vez tengan respuesta de la base de datos se para a las mutations
// export const myAction=async ({commit})=>{

import requestApi from "@/api/requestApi"
import { useStore } from "vuex";
import { setErrorsFields } from '@/helpers/errorsFields'



export const loadReferenceList = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'referenceList')
        const { data } = await requestApi.get(`/referencias/index`, {
            params: formData

        })
        commit('setReferenceList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        return setErrorsFields({ modulo: 'reference', error: error })

    }

}

export const createReference = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/referencias/store`, formData)
        commit('addReference', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'reference', error: error })
    }
}
export const updateReference = async ({ commit }, { formData, referenceId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/referencias/update/${referenceId}`, formData)
        commit('updateReference', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: 'Lista actualizada', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'reference', error: error })

    }
}
export const storeAnswerReference = async ({ commit }, { formData, referenceId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/referencias/referenciarSimple/${referenceId}`, formData)
        commit('updateReference', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'reference', error: error })

    }
}
export const deleteReference = async ({ commit }, referenceId) => {
    try {
        commit('showProcessHideAlert', 'operation')
        await requestApi.put(`/referencias/destroy/${referenceId}`)
        commit('deleteReference', referenceId)
        commit('hideProcessShowAlert', { messageServer: 'Lista actualizada', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'reference', error: error })

    }
}
