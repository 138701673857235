<template>
    <form @submit.prevent="onSubmitAdd" class="mb-4">
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-forms v-model="formData.idAlmacen" title="Almacenes" idSelect="idAlmacen"
                    :options="shopAuthorizedLists" optionName="nombreAlmacen" optionId="codAlmacen"
                    :errors="alert.errorsFields"></base-select-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-forms v-model="formData.rolUsuario" idSelect="rolUsuario" title="Perfil/Rol" :options="roles"
                    optionId="name" optionName="name" :errors="alert.errorsFields"></base-select-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3">
                <base-button-forms classButton="btn-primary" label="Agregar" :isLoading="isLoadingAddUserStore"
                    :disabled="isLoadingAddUserStore" :permissionId="5"></base-button-forms>
            </div>
        </div>
    </form>
    <widget-loading v-if="isLoadingUserStore" />
    <template v-else>
        <base-table-forms :data="userShops" :headers="headers"  :whitPagination="false" :whitOrder="true" :dataFunction="orderList" :orderColumn="column" :order="order">
            <tr v-for="shop in userShops" :key="shop.id">
                <td>{{ shop.codAlmacen }}</td>
                <td>{{ shop.aliasAlmacen }}</td>
                <td>{{ shop.descripcionRol }}</td>
                <td>
                   <widget-dropdown icon="fa-solid fa-user-pen text-primary" :dropdownItem="[
                        { label: 'Editar', action: () => { onEdit(shop.codAlmacen, shop.perfil) },permissionId: 5,visible:true },
                        { label: 'Inactivar', action: () => { onSubmitRemove(shop.codAlmacen) },permissionId: 5,visible:true }
                    ]"></widget-dropdown>
                </td>
            </tr>
        </base-table-forms>
    </template>
</template>

<script>
import { ref, toRef, watch } from 'vue';

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';

import useUsers from '../composables/useUsers';
import useShop from '@/modules/shop/composables/useShop'
import { useStore } from 'vuex'





export default {

    props: {
        userId: {
            type: String,
            required: true,
        },
        roles: {
            type: Object,
            required: true
        }

    },
    components: {
        WidgetAlerts,
        BaseButtonForms,
        WidgetDropdown,
        BaseInputForms,
        WidgetLoading,
        BaseSelectForms,
        BaseSelectList,
        BaseTableForms,

    },

    setup(props) {

          const formData = ref({
            idAlmacen: null,
            rolUsuario: null
        })
        const headers=ref(
            [
            { encabezado: 'ID Almacén', columna: 'codAlmacen' },
            { encabezado: 'Almacén', columna: 'aliasAlmacen' },
            { encabezado: 'Rol', columna: 'nombreRol' },
            { encabezado: 'Acciones', columna: null },
            ]
        )
        const column = ref()
        const order = ref()
        const store = useStore()
        const { addShopToUser, removeShopToUser, isLoadingUserStore, isLoadingOperationUser, isLoadingAddUserStore, getUserShops, userShops, alert,userEdit } = useUsers()
        const { getShopAuthorizedLists, shopAuthorizedLists } = useShop()

      
      
        getShopAuthorizedLists('usuario')

        const orderList = (page, col) => {
            if (col) {
                column.value = col
                order.value = order.value == 'asc' ? 'desc' : 'asc'
            }
            store.commit('users/orderUserShops', { column:column.value, order:order.value })
        }

        return {
            alert,
            formData,
            isLoadingUserStore,
            isLoadingOperationUser,
            isLoadingAddUserStore,
            shopAuthorizedLists,
            userShops,
            headers,
            column,
            order,
            orderList,
            


            onSubmitAdd: async () => {
                const { response } = await addShopToUser(userEdit.value.id, formData.value)
                if (response) {
                    getUserShops(userEdit.value.id)
                    formData.value.idAlmacen = ""
                    formData.value.rolUsuario = ""
                }
            },
            onSubmitRemove: async (shopId) => {
                formData.value.idAlmacen = shopId
                const { response } = await removeShopToUser(userEdit.value.id, formData.value)
                if (response) {
                    getUserShops(userEdit.value.id)
                    formData.value.idAlmacen = ""
                    formData.value.rolUsuario = ""
                }
            },
            onEdit: (idAlmacen, rolUsuario) => {
                formData.value.idAlmacen = idAlmacen.toString()
                formData.value.rolUsuario = rolUsuario
            }
        }
    }

}
</script>

