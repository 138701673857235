import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setRoleList = (state, { data }) => {
    state.rolesList = data.roles
    state.permissionsList = data.permisos
}

export const setRole = (state, { data }) => {
    // Convertir el objeto de las secciones en un array
    let permissions = []
    for (const key in data.rol.permissions) {
        permissions.push(data.rol.permissions[key].id)
    }
    state.role=data.rol
    state.role.permissions = permissions
}
export const addRole = (state, { data }) => {
    state.rolesList.data.unshift(data)
}
export const updateField = (state, { field, value }) => {
    state.role[field] = value;
}
export const updateRole = (state, { data }) => {
    const idx = state.rolesList.data.map(r => r.id).indexOf(data.id)
    state.rolesList.data[idx] = data
}
export const clearRole = (state) => {
    for (const property in state.role) {
        state.role[property] = ''
    }
    state.role.permissions=[]
}


export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.messageServer = null
    state.alert.errorsFields = {}
    state.alert.showAlert = false
    state.alert.typeAlert = ''

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false

    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }

}