import { computed } from "vue";
import { useStore } from "vuex";
import useAuth from '@/modules/auth/composables/useAuth';

const useTasks = () => {
    const store = useStore()
    const { userDataLoggedIn } = useAuth()

    const loadTasksList = async (formData) => {
        return await store.dispatch('tasks/loadTasksList', {formData})
    }
    const loadSummaryTask = async () => {
        return await store.dispatch('tasks/loadSummaryTask')
    }
    const getTask = async (taskId) => {
        return await store.dispatch('tasks/getTask', { taskId })
    }
    const getListConfig = async () => {
        return await store.dispatch('tasks/getListConfig')
    }
    const createTask = async (formData) => {
        return await store.dispatch('tasks/createTask', { formData })
    }
    const updateTask = async (taskId, formData) => {
        return await store.dispatch('tasks/updateTask', { taskId, formData })
    }
    const changeStatus = async (taskId, formData) => {
        return await store.dispatch('tasks/changeStatus', { taskId, formData })
    }


    return {
        loadTasksList,
        getTask,
        createTask,
        updateTask,
        changeStatus,
        getListConfig,
        loadSummaryTask,

        canEditTask: (task) => {
            return task.codUsuarioCreador ==userDataLoggedIn.value.id
        },

        tasksList: computed(() => store.state['tasks']['tasksList']),
        task: computed(() => store.state['tasks']['task']),
        summaryTask: computed(() => store.state['tasks']['summaryTask']),
        alert: computed(() => store.state['tasks']['alert']),
        isLoading: computed(() => store.state['tasks']['isLoading']),
        contactosFrecuentes: computed(() => store.state['tasks']['contactosFrecuentes']),
        categorias: computed(() => store.state['tasks']['categorias']),
        taskParameters: computed(() => store.state['tasks']['taskParameters']),
        



    }



}


export default useTasks