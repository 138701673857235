import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setProductList = (state, { data }) => {
    state.productList = data.productos
    state.productCategorie = data.categorias
    // state.productParameters = data.parametros
}
export const setProduct = (state, { data }) => {

    state.product = data
}
export const addProduct = (state, { data }) => {
    state.productList.data.unshift(data)
    state.product.CA120CODPRODUCTO = data.CA120CODPRODUCTO
}
export const updateField = (state, { field, value }) => {
    state.product[field] = value;
}
export const updateProduct = (state, { data }) => {
    const idx = state.productList.data.map(p => p.CA120CODPRODUCTO).indexOf(data.CA120CODPRODUCTO)
    state.productList.data[idx] = data
}
export const deleteProduct = (state, { productId }) => {
    state.productList.data = state.productList.data.filter(p => p.CA120CODPRODUCTO!=productId)
}
export const deleteProductShop = (state, { codShop }) => {
    state.product.almacenes = state.product.almacenes.filter(p => p.Ca110CodAlmacen!=codShop)
}
export const clearProduct = (state) => {
    for (const property in state.product) {
        state.product[property] = ''
    }
    // state.productCategorie={}
}


export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'productList':
            state.isLoading = true
            break;
        case 'product':
            state.isLoadingProduct = true
            break;
        case 'operation':
            state.isLoadingOperationProduct = true
            break;
        case 'productShop':
            state.isLoadinStoreProductShop = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingProduct = false
    state.isLoadingOperationProduct = false
    state.isLoadinStoreProductShop = false
    state.messageServer = messageServer
    // state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }

}