import { isAuthenticatedGuard, isNotAuthenticatedGuard, isAuthorized, hasChangedPassword } from '@/guard/auth-guard'
import ListsLists from '../lists/views/ListsLists.vue'
import ObjetivesLists from '../objetives/views/ObjetivesLists.vue'
import RelationshipLists from '../relationship/views/RelationshipLists.vue'
import QuestionsLists from '../questions/views/QuestionsLists.vue'
export default {
    name: 'parametrization',
    component: () =>
        import( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [{
        path: '/lists',
        beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized],
        name: 'lists-index',
        meta: {
            title: 'Listas',
            icon: 'fas fa-list-ul',
            showNav: true,
            permission: 16,
            group: 'admin',
        },
        component: ListsLists
    },
    {
        path: '/objetives',
        beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized],
        name: 'objetives-index',
        meta: {
            title: 'Objetivos',
            icon: 'fa-solid fa-bullseye',
            showNav: true,
            permission: 24,
            group: 'admin',
        },
        component: ObjetivesLists
    },
    {
        path: '/relationship',
        beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized],
        name: 'relationship-index',
        meta: {
            title: 'Parentescos',
            icon: 'fa-solid fa-people-group',
            showNav: true,
            permission: 25,
            group: 'admin',
        },
        component: RelationshipLists
    },
    {
        path: '/questions',
        beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized],
        name: 'questions-index',
        meta: {
            title: 'Preguntas',
            icon: 'fa-solid fa-clipboard-question',
            showNav: true,
            permission: 20,
            group: 'admin',
        },
        component: QuestionsLists   
    },
    ]



}