<template>
  <TheContentHeader titlePage="Parametrizacion de Listas" />
  <the-content-body>
    <widget-card title="Listas" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Listas</div>
          <base-button-fab @on:click="openModal('newList')" :permissionId="18"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
        <base-input-group :icon="'fa-solid fa-magnifying-glass'" v-model="formData.search"
          :onBlur="callLoadListsIndex"></base-input-group>
        <widget-loading v-if="isLoading" />
        <template v-else-if="listsIndex.data.length > 0">
          <widget-select-paginator v-model="formData.numPages" :onChange="callLoadListsIndex"></widget-select-paginator>
          <base-table-forms :data="listsIndex" :dataFunction="callLoadListsIndex" :headers="headers" :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
            <tr v-for="list in listsIndex.data" :key="list.consecutivo" >
              <td>{{ list.tipoLista }}</td>
              <td>{{ list.descripcionLista }}</td>
              <td>{{ list.codLista }}</td>
              <td>{{ list.listaDependiente }}</td>
              <td>{{ list.codListaDependiente }}</td>
              <td>{{ list.estado }}</td>
              <td>
                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
            { label: 'Editar', action: () => { openModal(list.consecutivo.toString()) }, permissionId: 19, visible: true },
          ]"></widget-dropdown>
              </td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <widget-modal-boostrap modalId="modalList" :modalTitle="listId === 'newList' ? 'Crear lista' : 'Actualizar Lista'"
    modalClassSize="modal-lg" :showFooter="false">
    <template v-slot:modalBody>
      <ListOperation @on:createdList="openModal" :listId="listId"></ListOperation>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { onMounted, ref } from 'vue';
import { Modal } from 'bootstrap';

import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import ListOperation from './ListOperation.vue';


import useList from '@/modules/parameterization/lists/composables/useLists'


export default {
  components: {
    WidgetAlerts,
    BaseButtonFab,
    WidgetCard,
    TheContentBody,
    TheContentHeader,
    WidgetDropdown,
    BaseInputGroup,
    WidgetLoading,
    WidgetModalBoostrap,
    WidgetSelectPaginator,
    BaseTableForms,
    ListOperation


  },

  setup() {
    const formData = ref({
      search: '',
      numPages: '5',
      page: 1,
      column: 'ca001TablaDeLista',
      order: 'asc'

    })
    const headers = ref([
      { encabezado: 'Lista Principal', columna: 'ca001TablaDeLista' },
      { encabezado: 'Descripción', columna: 'ca001DescripcionItemDeLista' },
      { encabezado: 'Código Lista', columna: 'ca001CodigoItemDeLista' },
      { encabezado: 'Lista dependiente', columna: 'ca001TablaDependiente' },
      { encabezado: 'Código Lista Dependiente', columna: 'ca001ItemListaDependiente' },
      { encabezado: 'Estado', columna: 'ca001Estado' },
      { encabezado: 'Acciones', columna: null },
    ])
    const { loadListsIndex,
      listsIndex,
      showAlert,
      messageServer,
      typeAlert,
      numPages,
      isLoading,
      modals,
      listId,
      search } = useList()


    onMounted(() => {
      modals.modalList = new Modal('#modalList', {})
    })
    const callLoadListsIndex = (page, column) => {
      formData.value.page = page

      if (column) {
        formData.value.column = column
        formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
      }
      loadListsIndex(formData.value)
    }
    callLoadListsIndex()




    return {
      listId,
      listsIndex,
      messageServer,
      showAlert,
      typeAlert,
      isLoading,
      formData,
      headers,


      callLoadListsIndex,
      openModal: (id) => {
        listId.value = id
        modals.modalList.show()
      },
      closeModal: () => {
        modals.modalList.hide()
      }
    }
  }

}
</script>

<style></style>