import inputFormat from '@/helpers/inputMaskFormat'
import graphics from '@/helpers/graphics'

const {  groupBy } = inputFormat()
const { backgroundColor, borderColor } = graphics()

//Crear objeto para dar formato a las etiquets encima de cada barra
const datalabels = {
    color: 'black',
    anchor: 'center',
    align: 'center',
    offset: 5

}
export const setRequestGraphics = (state, { data }) => {
    let { dataGrafico, parametros } = data
    let { grafico1 } = dataGrafico
    let colorBarrasFondo = [];
    let colorBarrasBorde = [];
    colorBarrasFondo['FP'] = backgroundColor.verde;
    colorBarrasFondo['FN'] = backgroundColor.rosado;
    colorBarrasFondo['ES'] = backgroundColor.amarillo;
    colorBarrasBorde['FP'] = borderColor.verde;
    colorBarrasBorde['FN'] = borderColor.rosado;
    colorBarrasBorde['ES'] = borderColor.amarillo;

    let datosPorFechaSol = groupBy(grafico1['data'], 'ano');
    //Extrar la fecha que se utilizará como label para cada barra
    let labelFechasSol = [];
    let cantPorFechaSol = [];
    let datasets = [];
    for (const key in datosPorFechaSol) {
        //Obtiene el nombre de las fechas
        labelFechasSol.push(key);
        //Obtiene las cantidades por fecha
        cantPorFechaSol.push(datosPorFechaSol[key].length)
    }
    //Organiza la data
    let aprobadas = [];
    let negadas = [];
    let estudio = [];
    //Extraer la cantidad de solicitudes aprobadas por mes
    labelFechasSol.forEach(element => {
        aprobadas.push(grafico1['data'].filter(fecha => {
            return fecha.ano == element && fecha.ca112estado == 'FP';
        }).length);

        negadas.push(grafico1['data'].filter(fecha => {
            return fecha.ano == element && fecha.ca112estado == 'FN';
        }).length);

        estudio.push(grafico1['data'].filter(fecha => {
            return (fecha.ano == element && fecha.ca112estado == 'PE') || (fecha
                .ano == element && fecha.ca112estado == 'AB');
        }).length);

    });


    //Crear dataset con varios tipos de datos
    datasets = [{
        label: 'Aprobadas',
        data: aprobadas,
        backgroundColor: colorBarrasFondo['FP'],
        borderColor: colorBarrasBorde['FP'],
        borderWidth: 1,
        datalabels
    },
    {
        label: 'Estudio',
        data: estudio,
        backgroundColor: colorBarrasFondo['ES'],
        borderColor: colorBarrasBorde['ES'],
        borderWidth: 1,
        datalabels

    },
    {
        label: 'Rechazadas',
        data: negadas,
        backgroundColor: colorBarrasFondo['FN'],
        borderColor: colorBarrasBorde['FN'],
        borderWidth: 1,
        datalabels
    },

    ]
    /**
     * Definir los usuarios encargados de la solicitud
     */
    let ano = []
    let usuariosAno1 = ''
    let usuariosAno2 = ''

    //Obtiene las key por fecha (año)
    for (const key in datosPorFechaSol) {
        ano.push(key);
    }
    //Validar si existe fecha (año) en el índice 0 para armar el footer del año 1
    if (ano[0]) {
        //Agrupa datos por usuario
        usuariosAno1 = groupBy(datosPorFechaSol[ano[0]], 'Ca111IDUsuarioAlmacen');
    }
    //Validar si existe fecha (año) en el índice 1 para armar el footer del año 2
    if (ano[1]) {
        //Agrupa datos por usuario
        usuariosAno2 = groupBy(datosPorFechaSol[ano[1]], 'Ca111IDUsuarioAlmacen');
    }

    state.requestGraphics = { labels: labelFechasSol, datasets: datasets, title: grafico1.titulo, subtitle: grafico1.subtitulo, usuariosAno1: usuariosAno1, usuariosAno2: usuariosAno2 }
    state.graphicsParameters = parametros
    // return [labelFechasSol, datasets];

}
export const setShivamAccountGraphics = (state, { data }) => {
    state.shivamAccountGraphics = data
}
export const setSalesGraphics = (state, { data }) => {
    let datosGraficoVentas1 = data['grafico1'];

    //Agrupar datos por fecha
    let datosPorFecha1 = groupBy(datosGraficoVentas1['data'], 'ano');
    //Extrar la fecha que se utilizará como label para cada barra y calcular el valor de venta
    let labelFechaVentas1 = [];
    let totalVenta1 = [];
    for (const key in datosPorFecha1) {
        //Suma las ventas por mes
        let valorVenta = 0;
        datosPorFecha1[key].forEach(element => {
            valorVenta += Number(element.CA208VLRCREDITO);
        });

        totalVenta1.push(valorVenta);
        labelFechaVentas1.push(key);
        valorVenta = 0;

    }
    let datasets = [{
        label: 'Ventas',
        data: totalVenta1,
        backgroundColor: Object.values(backgroundColor),
        borderColor: Object.values(borderColor),
        borderWidth: 1,
        datalabels
    }];
    state.salesGraphics = { labels: labelFechaVentas1, datasets: datasets, title: datosPorFecha1.titulo, subtitle: datosPorFecha1.subtitulo }
}
export const setPaymentsGraphics = (state, { data }) => {
    let datosGraficoVentas1 = data['grafico1'];

    //Agrupar datos por fecha
    let datosPorFecha1 = groupBy(datosGraficoVentas1['data'], 'ano');
    //Extrar la fecha que se utilizará como label para cada barra y calcular el valor de venta
    let labelFechaVentas1 = [];
    let totalVenta1 = [];
    for (const key in datosPorFecha1) {
        //Suma las ventas por mes
        let valorVenta = 0;
        datosPorFecha1[key].forEach(element => {
            valorVenta += Number(element.CA209VLRPAGO);
        });

        totalVenta1.push(valorVenta);
        labelFechaVentas1.push(key);
        valorVenta = 0;

    }
    let datasets = [{
        label: 'Pagos',
        data: totalVenta1,
        backgroundColor: Object.values(backgroundColor),
        borderColor: Object.values(borderColor),
        borderWidth: 1,
        datalabels
    }];
    state.paymentsGraphics = { labels: labelFechaVentas1, datasets: datasets, title: datosPorFecha1.titulo, subtitle: datosPorFecha1.subtitulo }
}
export const setCreditGraphics = (state, { data }) => {
    let alDia = 0,
        a30 = 0,
        a60 = 0,
        a90 = 0,
        mayor90 = 0;
    //Sumar los valores
    for (const key in data['data']) {
        let valores = data['data'][key];
        alDia += valores['al_dia'];
        a30 += valores['dia_1_30'];
        a60 += valores['dia_31_60'];
        a90 += valores['dia_61_90'];
        mayor90 += valores['dia_90_mayor'];
    }
    let dataValores = [alDia, a30, a60, a90, mayor90];
    //Armar gráfico
    let datasets = [{
        label: 'Cartera',
        data: dataValores,
        backgroundColor: Object.values(backgroundColor),
        borderColor: Object.values(borderColor),
        borderWidth: 1,
        datalabels
    }];
    state.creditGraphics = { labels: data['label'], datasets: datasets, title: '', subtitle: '' }

}
export const showProcessHideAlert = (state, typeProcess) => {
    state.isLoading[typeProcess] = true
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {
    state.isLoading[typeProcess] = false
    state.alert.messageServer = messageServer
    state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }

}