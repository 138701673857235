<template>
    <label :for="idSelect" class="fw-bold">{{ title }}</label>
    <select 
    v-model="inputModel" 
    :name="idSelect" 
    :id="idSelect" 
    class="form-control form-control-sm"
    :class="{ 'is-invalid': errors[idSelect] }" 
    :required="required" 
    @blur="onBlur"
    :disabled="disabled"
    >
        <option value="">Seleccione...</option>
        <option v-for="{ codLista, descripcionLista } in listas" :value="codLista"
            :key="codLista">{{ descripcionLista }}</option>
    </select>
    <span v-if="errors[idSelect]" class="invalid-feedback" role="alert">
        <small>{{ errors[idSelect] ? errors[idSelect][0] : '' }}</small>
    </span>
</template>
<script>


import { computed, ref, toRef, watch } from "vue"

import requestApi from '@/api/requestApi'
import { useStore } from "vuex";

export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            // type: String|Number,
        },
        idSelect: {
            type: String,
            default: ''
        },
        nameSelect: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        tipoLista: {
            type: String,
            default: ''
        },
        listaPadre: {
            type: String,
            default: ''
        },//Nombre Lista principal
        listaPadreId: {
            type: String,
            default: ''
        },//Id lista principal
        listaHijo: {
            type: String,
            default: ''
        },//Nombre Lista dependiente
        errors: Object,
        required: {
            type: Boolean,
            default: false
        },
        startRequest: {//Iniciar con la petición al montar el componente
            type: Boolean,
            default: false
        },
        onBlur: {
            type: Function,
            default:()=>{}
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    setup(props, { emit }) {

        const store=useStore()
        const listas = ref(store.getters['parameterization/getListSelect'](props.tipoLista,props.listaPadre,props.listaPadreId))
        const tipoListaCopy=toRef(props,'tipoLista')
        const listaPadreCopy=toRef(props,'listaPadre')
        const listaPadreIdCopy=toRef(props,'listaPadreId')

        watch([tipoListaCopy,listaPadreCopy,listaPadreIdCopy],()=>{
            listas.value = store.getters['parameterization/getListSelect'](tipoListaCopy.value,listaPadreCopy.value,listaPadreIdCopy.value)
            inputModel.value=''
        })
        
        const inputModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', newValue)
            }
        })
        return {
            inputModel,
            listas,
        }

    }
}


</script>
