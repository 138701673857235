//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    currentPage: null,
    errorsFields: {},
    isLoading: false,
    messageServer: null,
    permissions: [],
    showAlert: false,
    system: {},
    status: 'authenticating',//authenticating,authenticated,not-authenticated
    typeAlert: '',
    UserLoggedIn: {},
    userParameters: {
        grupos: [],
        turnos: [],
        tiempoSesion: 0
    }
})