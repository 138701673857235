export default () => ({
    rolesList: {},
    permissionsList: {},
    role: {
        id: '',
        name: '',
        description:'',
        permissions: []
    },
    isLoading: {
        list: false,
        role: false,
        operation: false,
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: '',
    }

})