<template>
    <label v-if="title" :for="inputId" class="fw-bold">{{ title }}</label>
    <input 
    v-model="inputModel" 
    :class="{ 'is-invalid': errors[inputId] }" 
    :data-tp_dato="typeData" 
    :disabled="disabled"
    :id="inputId" 
    :max="typeInput==='date'?'9999-12-31':''"
    :maxlength="maxCharacteres"
    :min="typeInput==='date'?'1900-01-01':''"
    :name="inputId" 
    :required="required"
    :title="typeInput==='date'?'Ingrese una fecha dentro del rango permitido':''"
    :type="typeInput" 
    @blur="onBlur(paramFunction)"
    @change="onChange(paramFunction)"
    @keyup="onKeyup(paramFunction)"
    autocomplete="off" 
    class="form-control form-control-sm"
    :placeholder="placeholder"
    >
    <span v-if="errors[inputId]" class="invalid-feedback" role="alert">
        <small>{{ errors[inputId] ? errors[inputId][0] : '' }}</small>
    </span>
</template>

<script>
import { computed, ref } from "vue"

import inputMaskFormat from "@/helpers/inputMaskFormat"

export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            // type: String|Number,
        },
        title: {
            type: String,
            default: null
        },//Label
        typeInput: {
            type: String,
            default: null
        },
        typeData: {
            type: String,
            default: null
        },
        decimals: {
            type: Number,
            default: 0
        },
        inputId: {
            type: String,
            default: null
        },
        inputName: {
            type: String,
            default: null
        },        
        required: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: []
        },
        onBlur:{
            type:Function,
            default:()=>{}
        },
        onChange:{
            type:Function,
            default:()=>{}
        },
        onKeyup:{
            type:Function,
            default:()=>{}
        },
        paramFunction:{
            default:''

        },
        disabled:{
            type:Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:''
        },
        inputClass:{
            type:String,
            default:''
        }

    },
    setup(props, { emit }) {

        const format = inputMaskFormat()
        const inputModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', setFormat(newValue, props.typeData))
            }
        })

        const setFormat = (input, type) => {

            switch (type) {
                case 'currence':
                    return format.inputCurrence(input, props.decimals)
                case 'phone':
                    return format.inputPhone(input)
                case 'numId':
                    return format.inputNumId(input)
                case 'plate':
                    return input.toUpperCase()
                default:
                    return format.inputText(input)
            }

        }
        return {
            inputModel,

            maxCharacteres: computed(() => {
                // return props.typeData === 'currence' ? 10 : 1
                switch (props.typeData) {
                    case 'currence':
                        return 12
                    case 'phone':
                        return 12
                    case 'numId':
                        return 12
                    case 'digit':
                        return 3
                    case 'plate':
                        return 6
                    case 'year':
                        return 4
                    default:
                        break;
                }
            })
        }

    }

}
</script>