import ChartDataLabels from 'chartjs-plugin-datalabels';
import inputFormat from '@/helpers/inputMaskFormat'
import Chart from 'chart.js/auto';

const {  inputCurrence } = inputFormat()

Chart.register(ChartDataLabels);
const graphics = () => {

    const backgroundColor = {
        verde: 'rgba(0, 255, 0, 1)',
        rosado: 'rgba(255, 99, 132, 1)',
        azulClaro: 'rgba(54, 162, 235, 1)',
        amarillo: 'rgba(255, 206, 86, 1)',
        aguamarina: 'rgba(75, 192, 192, 1)',
        moradoClaro: 'rgba(153, 102, 255, 1)',
        naranja: 'rgba(255, 159, 64, 1)',
    }

    const borderColor = {
        verde: 'rgba(0, 255, 0, 1)',
        rosado: 'rgba(255, 99, 132, 1)',
        azulClaro: 'rgba(54, 162, 235, 1)',
        amarillo: 'rgba(255, 206, 86, 1)',
        aguamarina: 'rgba(75, 192, 192, 1)',
        moradoClaro: 'rgba(153, 102, 255, 1)',
        naranja: 'rgba(255, 159, 64, 1)',
    }


    const barGraphicsOptions = ({ tipoInforme, labels, datasets, titulo, subtitulo, textoEjeX, textoEjeY, tipoDato, mostrarLegend = true }) => {
        return {
            type: 'bar',
            data: {
                labels: labels, //Muestra los rótulos o nombres de las barras
                datasets: datasets //Muestra los números
            },
            //Activa el plugins para ver los rótulos por encima de las barras
            plugins: [ChartDataLabels],
            options: {
                //Ejecutar acción al cargarse el gráfico
                animation: {
                    onComplete: (chart) => {

                    }
                },
                responsive: true,
                scales: {
                    y: {
                        ticks: {
                            //Saltos en el eje Y
                            stepSize: 5,
                            //Formato eje y
                            callback: function (value, index, ticks) {
                                return tipoDato === 'monto' ? inputCurrence(value, 0,'state') : value;
                            }
                        },
                        //Indica si muestra apilados o no las barras
                        stacked: false,
                        //Muestra texto eje y
                        display: true,
                        title: {
                            display: true,
                            text: textoEjeY,
                        }
                    },
                    x: {
                        //Indica si muestra apilados o no las barras
                        stacked: false,
                        //Muestra texto eje y
                        display: true,
                        title: {
                            display: true,
                            text: textoEjeX,
                        }
                    },
                },
                plugins: {
                    //Indica si se muestra o no la leyenda de los datasets
                    legend: {
                        display: mostrarLegend
                    },
                    //Título del gráfico
                    title: {
                        display: true,
                        text: titulo,
                    },
                    //Subtítulo del gráfico
                    subtitle: {
                        display: true,
                        text: subtitulo,
                        color: 'blue',
                        font: {
                            size: 12,
                            family: 'tahoma',
                            weight: 'normal',
                            style: 'italic'
                        },
                        //Padding del subtítulo
                        padding: {
                            bottom: 50
                        }
                    },
                    tooltip: {
                        enabled: false // <-- this option disables tooltips
                    },
                    //Formato del texto de los rótulos que aparecen encima de las barras. Para cambiar el formato de la posición del texto, ver la opción datalabels en el datasets de cada gráfico (ver archivo mutations)
                    datalabels: {
                        formatter: function (value, context) {
                            return tipoDato === 'monto' ? inputCurrence(value, 0,'state') : value;
                        }
                    }
                },
            }
        }
    }

    return{
        backgroundColor,
        borderColor,
        barGraphicsOptions

    }

}
export default graphics
// //Exportar a excel
// function exportarExcel(tipoGrafico) {
//     //Obtiene el nombre del formulario.
//     let formulario = 'formGrafico' + tipoGrafico;
//     let urlFormulario = $('#' + formulario).attr('action') + '/exportar';
//     let data = $('#' + formulario).serializeArray();
//     $.ajax({
//         type: 'GET',
//         url: urlFormulario,
//         xhrFields: {
//             responseType: 'blob'
//         },
//         data: data,
//         beforeSend: function () {
//             mostrarIndicadorCarga(formulario)
//         },
//         success: function (json) {
//             let a = document.createElement('a');
//             let url = window.URL.createObjectURL(json);
//             a.href = url;
//             a.download = tipoGrafico + new Date().getTime() + '.xlsx';
//             a.click();
//             window.URL.revokeObjectURL(url);
//             ocultarIndicadorCarga(formulario)
//         },
//         error: function (error) {
//             alert('Error al exportar: ' + error);
//         }
//     });
// }