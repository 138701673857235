
export const setRelationshipSelect = (state, { data }) => {
    state.relationshipSelect = data
}

export const setRelationshipList = (state, { data }) => {
    state.relationshipList = data
}

export const setRelationship = (state, { data }) => {
    state.relationship = data
}

export const addRelationshipList = (state, { data }) => {
    state.relationshipList.data.unshift(data)
}
export const updateRelationshipList = (state, { data }) => {
    const idx = state.relationshipList.data.map(u => u.CA009IDPARENTESCO).indexOf(data.CA009IDPARENTESCO)
    state.relationshipList.data[idx] = data
}
export const clearRelationship = (state) => {
    state.relationship = {
        CA009NOMBREPARENTESCO:'' ,
        relevancia:{}
    }
}


export const updateField = (state, { field, value }) => {
    state.relationship[field] = value;
}

export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false
    state.alert.errorsFields = errorsFields

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.alert.showAlert = false
        }, 5000);
    }
}