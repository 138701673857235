
export const setListsIndex = (state, { data }) => {
    state.listsIndex = data
}
export const setPrincipalList = (state, { data }) => {
    state.principalList = data
}
export const setListsSelect = (state, { data }) => {
    state.listsSelect = data
}
export const setListsArmed = (state, { data }) => {
    state.listsArmaded = data
}
export const addList = (state, { data }) => {
    const { lista } = data
    state.listsIndex.data.unshift(lista)
}

export const updateList = (state, { data }) => {
    const { lista } = data
    const idx = state.listsIndex.data.map(u => u.consecutivo).indexOf(lista.consecutivo)
    state.listsIndex.data[idx]=lista

}

export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'listList':
            state.isLoading = true
            break;
        case 'list':
            state.isLoadingList = true
            break;
        case 'operation':
            state.isLoadinOperationlist = true
            break;
    }
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingList = false
    state.isLoadinOperationlist = false
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }
}