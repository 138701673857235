
import requestApi from "@/api/requestApi"
import { useStore } from "vuex";
import { setErrorsFields } from '@/helpers/errorsFields'

/**
 * Sin utilizar, las listas se cargan al iniciar sesión el usuario
 * @param {*} param
 * @returns 
 */
export const loadRelationshipSelect = async ({ commit }) => {
    const store = useStore()
    if (Object.keys(store.state['relationship']['relationshipSelect']).length === 0) {
        try {
            commit('showProcessHideAlert', false)
            const { data } = await requestApi.get(`/parentesco/loadLists`)
            commit('setRelationshipSelect', { data:data.data })
            return { response: true }
        } catch (error) {
            return setErrorsFields({ modulo: 'relationship', error: error,typeProcess:'' })
        }

    }
    return { response: true }
}
export const loadRelationshipIndex = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/parentesco/index`,
            {
                params:formData
            }
        )
        commit('setRelationshipList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'relationship', error: error,typeProcess:'list' })

    }

}


export const createRelationship = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/parentesco/store`, formData)
        commit('addRelationshipList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'relationship', error: error,typeProcess:'operation' })

    }
}
export const getRelationship = async ({ commit }, relationshipId) => {
    try {
        commit('showProcessHideAlert', 'relationship')
        const { data } = await requestApi.get(`/parentesco/getParentesco/${relationshipId}`)
        commit('setRelationship', { data:data.data })

        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'relationship' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'relationship', error: error,typeProcess:'relationship' })

    }
}
export const updateRelationship = async ({ commit }, { formData, relationshipId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/parentesco/update/${relationshipId}?_method=PUT`, formData)
        commit('updateRelationshipList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'relationship', error: error,typeProcess:'operation' })


    }
}
export const addRelevance = async ({ commit }, { formData, relationshipId }) => {
    try {
        commit('showProcessHideAlert', 'relevance')
        const { data } = await requestApi.post(`/parentesco/agregarRelevancia/${relationshipId}?_method=PUT`, formData)
        commit('setRelationship', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: 'Relevancia actualizado', errorsFields: {}, statusResponse: 'true', typeProcess: 'relevance' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'relationship', error: error,typeProcess:'relevance' })

    }
}


