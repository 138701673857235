import { required, email, helpers } from '@vuelidate/validators'

const validatorLang = () => {

    const customRequired = helpers.withMessage('Este cambo es obligatorio', required)
    const customEmail = helpers.withMessage('Debe ingresar un email válido', email)

    return {
        customRequired,
        customEmail
    }

}

export default validatorLang