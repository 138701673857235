import { computed, onMounted, ref, nextTick } from "vue";
import { useStore } from "vuex";

import inputMaskFormat from '@/helpers/inputMaskFormat';
import useAuth from '@/modules/auth/composables/useAuth';



const useChat = () => {
    const store = useStore()
    const { diffForHumans } = inputMaskFormat()
    const { userDataLoggedIn } = useAuth()
    const currentChat = ref({})
    const userTyping = ref(false)
    const chatMessage = ref([
        {
            envia: '',
            mensaje: '',
            nombreEnvia: '',
            timEnvia: '',
        }
    ])

    const containerMessageList = ref(null)

    const loadChatList = async (formData) => {
        return await store.dispatch('chat/loadChatList', { formData })
    }
    const getChatsNavbar = async () => {
        return await store.dispatch('chat/getChatsNavbar')
    }
    const startChat = async (formData) => {
        return await store.dispatch('chat/startChat', { formData })
    }

    const sendMessageChat = async (chatId, formData) => {
        return await store.dispatch('chat/sendMessageChat', { chatId, formData })
    }
    const closeChat = async (chatId) => {
        return await store.dispatch('chat/closeChat', { chatId })
    }
    const getChatMessage = async (chat) => {
        if (chat) {
            currentChat.value = chat
            chatMessage.value = chat.CA202MENSAJE ? JSON.parse(chat.CA202MENSAJE) : {}
            // Esperar actualización dom
            await nextTick()
            // realizar scroll
            containerMessageList.value.scroll({
                top: containerMessageList.value.scrollHeight,
                // behavior: "smooth",
            });
        } else {
            currentChat.value = {}
            chatMessage.value = {}
        }
    }
    onMounted(() => {
        containerMessageList.value

    })

    return {
        loadChatList,
        startChat,
        sendMessageChat,
        closeChat,
        chatMessage,
        currentChat,
        containerMessageList,
        userDataLoggedIn,
        userTyping,
        getChatsNavbar,

        chatList: computed(() => store.state['chat']['chatList']),
        chatsNavbar: computed(() => store.state['chat']['chatsNavbar']),
        chatParameters: computed(() => store.state['chat']['chatParameters']),
        errorsFields: computed(() => store.state['chat']['errorsFields']),
        isLoading: computed(() => store.state['chat']['isLoading']),
        isLoadingChat: computed(() => store.state['chat']['isLoadingChat']),
        isLoadingOperationChat: computed(() => store.state['chat']['isLoadingOperationChat']),
        messageServer: computed(() => store.state['chat']['messageServer']),
        showAlert: computed(() => store.state['chat']['showAlert']),
        typeAlert: computed(() => store.state['chat']['typeAlert']),

        chatStatus: computed(() => {
            let atendidos = 0
            let pendientes = 0
            for (const key in store.state['chat']['chatList']) {
                if (store.state['chat']['chatList'][key].CA202FLAG === '0') {
                    atendidos++
                } else {
                    pendientes++
                }
            }
            return [atendidos, pendientes]
        }),


        getChatMessage,
        getDiffForHumans: (date) => {
            const { minuts, hours, days } = diffForHumans(date)

            if (minuts >= 0 && minuts < 60) {
                return `hace ${minuts} min(s).`
            }
            if (minuts > 60 && minuts < 1440) {
                return `hace ${hours} hora(s).`
            }
            return `hace ${days} día(s).`

        },
        lastMessageChat: (message) => {
            const messages = message ? JSON.parse(message) : [{
                envia: '',
                mensaje: '',
                nombreEnvia: '',
                timEnvia: '',
            }]
            return messages[messages.length - 1]
        },
        updateChatList: (e) => {
            store.commit('chat/upDateChatList', { data: e.data })
            if (currentChat.value.CA202ASUNTO === e.data.chat.CA202ASUNTO) {
                currentChat.value = e.data.chat
                getChatMessage(currentChat.value)
            }
        },
        sendEventTyping: () => {
            const channel = window.Echo.private(`chat-escribiendo`)
            setTimeout(() => {
                channel.whisper('typing', {
                    nombreUsuario: userDataLoggedIn.value.name,
                    asunto: currentChat.value.CA202ASUNTO
                })

            }, 300)
        }

    }

}


export default useChat