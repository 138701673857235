<template>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111VLRValorCupoSolicitado" inputId="Ca111VLRValorCupoSolicitado" :errors="errorsFields"
                title="Cupo solicitado" typeInput="text" typeData="currence"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-select-forms v-model="Ca111NumDiaPago" title="Día de pago" idSelect="Ca111NumDiaPago" :options="payDay"
                optionName="day" optionId="day" :errors="errorsFields"></base-select-forms>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseSelectListArmed from '@/common/forms/BaseSelectListArmed.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseSelectListArmed,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
            requestParameters,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,
            requestParameters,
            payDay: computed(() => {
                const days = store.state['request']['requestParameters']
                const payDay = []
                for (const key in days.dias_vencimiento) {
                    let day = { day: days.dias_vencimiento[key] }
                    payDay.push(day)
                }
                return payDay
            }),

            Ca111VLRValorCupoSolicitado: computed({
                get: () => store.state['request']['request']['Ca111VLRValorCupoSolicitado'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRValorCupoSolicitado', value: val })
            }),
            Ca111NumDiaPago: computed({
                get: () => store.state['request']['request']['Ca111NumDiaPago'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NumDiaPago', value: val })
            }),
        }

    },
}
</script>