<template>
    <div class="form-group row">
        <base-select-list-armed v-model:modelValueBody="Ca111CodigoFuerzasArmadas"
            v-model:modelValueSubdivision="Ca111SubdivisionFuerzasArmadas" v-model:modelValueRange="Ca111Rango"
            idSelectBody="Ca111CodigoFuerzasArmadas" idSelectSubdivision="Ca111SubdivisionFuerzasArmadas"
            idSelectRange="Ca111Rango" :errors="errorsFields">
        </base-select-list-armed>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111CantMesesFuerzasArmadas" inputId="Ca111CantMesesFuerzasArmadas"
                :errors="errorsFields" title="Tiempo en meses" typeInput="text" typeData="digit"></base-input-forms>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NombreBaseMilitar" inputId="Ca111NombreBaseMilitar" :errors="errorsFields"
                title="Nombre base militar" typeInput="text" ></base-input-forms>
        </div>
        <base-select-list-location v-model:modelValueCountry="Ca111CodPaisBaseMilitar"
            v-model:modelValueState="Ca111CodDptoBaseMilitar" v-model:modelValueCity="Ca111CodCiudadBaseMilitar"
            idSelectCountry="Ca111CodPaisBaseMilitar" idSelectState="Ca111CodDptoBaseMilitar"
            idSelectCity="Ca111CodCiudadBaseMilitar" :errors="errorsFields">
        </base-select-list-location>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111DireccionBaseMilitar" inputId="Ca111DireccionBaseMilitar"
                :errors="errorsFields" title="Dirección base militar" typeInput="text" ></base-input-forms>
        </div>
    </div>

</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseSelectListArmed from '@/common/forms/BaseSelectListArmed.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseSelectListArmed,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111CodigoFuerzasArmadas: computed({
                get: () => store.state['request']['request']['Ca111CodigoFuerzasArmadas'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodigoFuerzasArmadas', value: val })
            }),
            Ca111SubdivisionFuerzasArmadas: computed({
                get: () => store.state['request']['request']['Ca111SubdivisionFuerzasArmadas'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111SubdivisionFuerzasArmadas', value: val })
            }),
            Ca111Rango: computed({
                get: () => store.state['request']['request']['Ca111Rango'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111Rango', value: val })
            }),
            Ca111CantMesesFuerzasArmadas: computed({
                get: () => store.state['request']['request']['Ca111CantMesesFuerzasArmadas'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CantMesesFuerzasArmadas', value: val })
            }),
            Ca111NombreBaseMilitar: computed({
                get: () => store.state['request']['request']['Ca111NombreBaseMilitar'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreBaseMilitar', value: val })
            }),
            Ca111CodPaisBaseMilitar: computed({
                get: () => store.state['request']['request']['Ca111CodPaisBaseMilitar'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodPaisBaseMilitar', value: val })
            }),
            Ca111CodDptoBaseMilitar: computed({
                get: () => store.state['request']['request']['Ca111CodDptoBaseMilitar'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodDptoBaseMilitar', value: val })
            }),
            Ca111CodCiudadBaseMilitar: computed({
                get: () => store.state['request']['request']['Ca111CodCiudadBaseMilitar'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodCiudadBaseMilitar', value: val })
            }),
            Ca111DireccionBaseMilitar: computed({
                get: () => store.state['request']['request']['Ca111DireccionBaseMilitar'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DireccionBaseMilitar', value: val })
            }),

        }

    },
}
</script>