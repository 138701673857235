import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const loadTasksList = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/tareas/index`, {
            params:formData
        })
        commit('setTasksList', { data: data.data })
        commit('setListConfig', { data: data.data.listas.original.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'list' })
    }
}

export const loadSummaryTask = async ({ commit }) => {
    try {
        commit('showProcessHideAlert', 'summary')
        const { data } = await requestApi.get(`/tareas/resumen`)
        console.log(data);
        commit('setSummaryTask', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'summary' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'summary' })
    }
}

export const createTask = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/tareas/store`, formData)
        commit('addTask', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'operation' })
    }
}
export const getTask = async ({ commit }, { taskId }) => {
    try {
        commit('showProcessHideAlert', 'task')
        const { data } = await requestApi.get(`/tareas/show/${taskId}`)
        commit('setTask', { data: data.data })
        commit('setListConfig', { data: data.data.listas.original.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'task' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'task' })
    }
}
export const updateTask = async ({ commit }, { formData, taskId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/tareas/update/${taskId}?_method=PUT`, formData)
        commit('updateTasksList', { data: data.data, taskId: taskId })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'operation' })
    }
}

export const changeStatus = async ({ commit }, { formData, taskId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/tareas/cambiarEstado/${taskId}?_method=PUT`, formData)
        commit('updateTasksList', { data: data.data,taskId })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'operation' })
    }
}

export const getListConfig = async ({ commit }) => {
    try {
        commit('showProcessHideAlert', 'task')
        const { data } = await requestApi.get(`/tareas/listas`)
        commit('setListConfig', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'task' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'tasks', error: error, typeProcess: 'task' })
    }
}

