import { computed } from "vue";
import { useStore } from "vuex";



const useDashboard = () => {

    const store = useStore()
 

    return {
        /*
        -Las clases para fijar el navbar y sidebar se encuentran agregadas en el index.html en el body (layout-navbar-fixed layout-fixed)
        -Las clases utilizadas en los métodos son propias de la plantilla admintle. Se agregan de forma manual ya que
         hay cierta incompatibilidad de vue con el js de admintle
        */


        showSidebar: () => {
            let body = document.getElementsByTagName('body')[0]
            body.classList.add('sidebar-mini')
        },
        hideSidebar: (event) => {
            let body = document.getElementsByTagName('body')[0]

            if (body.classList.contains('sidebar-open') && !event.target.classList.contains('main-sidebar')) {
                body.classList.remove('sidebar-open')
                body.classList.add('sidebar-closed')
                body.classList.add('sidebar-collapse')

            }

        }

    }

}
export default useDashboard
// 'TPIDEN','TPTERC','2'