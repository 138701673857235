//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    listsIndex: {
        data:{}
    },
    listsSelect: [],
    listsArmaded: [],
    listBodiesArmed: [
        {
            cod: 1,
            name: 'Policía'
        },
        {
            cod: 2,
            name: 'Fuerza aéra'
        },
        {
            cod: 3,
            name: 'Armada'
        },
        {
            cod: 4,
            name: 'Infantería de Marina'
        },
        {
            cod: 5,
            name: 'Ejército'
        }
    ],
    listSubdivisionArmed: [
        {
            cod: 1,
            name: 'Oficial'
        },
        {
            cod: 2,
            name: 'Suboficial'
        },
        {
            cod: 3,
            name: ' Nivel Ejectivo'
        },
    ],
    principalList: [],
    listEdit: {},
    isLoading: false,
    isLoadingList: false,
    isLoadinOperationlist: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: ''
})