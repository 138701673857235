
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import useVuelidate from '@vuelidate/core'

import validatorang from '@/helpers/validatorLang'



const useObjetive = () => {
    const store = useStore()

    const loadObjetiveIndex = async (formData) => {
        const resp = await store.dispatch('objetives/loadObjetiveIndex', formData)
        return resp
    }
    const loadObjetiveListSelect = async () => {
        const resp = await store.dispatch('objetives/loadObjetiveListSelect')
        return resp
    }

    const createObjetive = async (formData) => {
        const resp = await store.dispatch('objetives/createObjetive',formData)
        return resp
    }
    const getObjetive = async (objetiveId) => {
        const resp = await store.dispatch('objetives/getObjetive', objetiveId)
        return resp
    }
    const updateObjetive = async (objetiveId,formData) => {
        console.log({objetiveId,formData});
        const resp = await store.dispatch('objetives/updateObjetive', {formData,objetiveId})
        return resp
    }
    
    return {
        loadObjetiveIndex,
        loadObjetiveListSelect,
        createObjetive,
        getObjetive,
        updateObjetive,

        objetive: computed(() => store.state['objetives']['objetive']),
        objetivesList: computed(() => store.state['objetives']['objetivesList']),
        objetivesSelect: computed(() => store.state['objetives']['objetivesSelect']),
        isLoading: computed(() => store.state['objetives']['isLoading']),
        alert: computed(() => store.state['objetives']['alert']),


    }
}

export default useObjetive