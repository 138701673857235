import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'



export const loadQuestionIndex = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'list')
        const { data } = await requestApi.get(`/preguntas/index`,
            {
                params:formData
            }
        )
        commit('setQuestionIndex', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'list' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'questions', error: error, typeProcess:'list' })

    }

}

export const createQuestion = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/preguntas/store`, formData)
        commit('addQuestionList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: 'Operación exitosa', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'questions', error: error, typeProcess:'operation' })

    }
}
export const getQuestion = async ({ commit }, questionId) => {
    try {
        commit('showProcessHideAlert', 'question')
        const { data } = await requestApi.get(`/preguntas/getPregunta/${questionId}`)
        commit('setQuestion', { data:data.data })

        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'question' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'questions', error: error, typeProcess:'question' })

    }
}
export const updateQuestion = async ({ commit }, { formData, questionId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/preguntas/update/${questionId}?_method=PUT`, formData)
        commit('updateQuestionList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'operation' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'questions', error: error, typeProcess:'operation' })


    }
}
export const questionSettings = async ({ commit }, { formData, questionId, numSetting }) => {
    const setting = ['segmento', 'referencia', 'objetivo']
    try {
        commit('showProcessHideAlert', setting[numSetting])
        const { data } = await requestApi.post(`/preguntas/${setting[numSetting]}/${questionId}`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: setting[numSetting] })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'questions', error: error, typeProcess: setting[numSetting] })
        

    }
}
export const answerOptions= async ({ commit }, { formData, questionId }) => {
    try {
        commit('showProcessHideAlert', 'answerOptions')
        const { data } = await requestApi.post(`/preguntas/agregarOpcionesRespuesta/${questionId}`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'answerOptions'})
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'questions', error: error, typeProcess: 'answerOptions' })


    }
}
