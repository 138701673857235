
export const setQuestionIndex = (state, { data }) => {
    state.questionsList = data.preguntas
    state.requestFields = data.camposSolicitud
}

export const setQuestion = (state, { data }) => {
    state.question = data
    state.question.segmentos = state.question.segmentos.map(s => s.CA007CODSEGMENTO)
    state.question.tiposReferencias = state.question.tiposReferencias.map(s => s.CA005CODTIPOREFERENCIA)
    state.question.objetivos = state.question.objetivos.map(s => s.CA006CODOBJETIVO)
    // state.question.opcionesRespuesta = state.question.opcionesRespuesta.map(s => s.CA004IDLISTARESPUESTA)
}


export const addQuestionList = (state, { data }) => {
    state.questionsList.data.unshift(data)
}
export const updateQuestionList = (state, { data }) => {
    const idx = state.questionsList.data.map(u => u.CA003CODPREGUNTA).indexOf(data.CA003CODPREGUNTA)
    state.questionsList.data[idx] = data

}
export const clearQuestion = (state) => {
    for (const key in state.question) {
        state.question[key] = ''
    }

    state.question.segmentos = []
    state.question.tiposReferencias = []
    state.question.objetivos = []
    state.question.opcionesRespuesta = []
}


export const updateField = (state, { field, value }) => {
    state.question[field] = value;
}

export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.messageServer = ''
    state.alert.showAlert = false
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false
    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.alert.showAlert = false
        }, 5000);
    }
}