<template>
    <TheContentHeader titlePage="Caja" />
    <the-content-body>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <widget-info-box label="Registrar venta" icon="fa-solid fa-cart-plus" bgIcon="bg-info" :cursor="true"
                    :onClick="openModal" :paramFunction="1" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <widget-info-box icon="fa-solid fa-bag-shopping" label="Consultar ventas" bgIcon="bg-primary" :cursor="true"
                    :onClick="openModal" :paramFunction="2" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <widget-info-box icon="fa-solid fa-cash-register" label="Registrar Recaudo" bgIcon="bg-secondary" :cursor="true"
                    :onClick="openModal" :paramFunction="3" />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <widget-info-box icon="fa-solid fa-file-invoice" label="Consultar recaudos" bgIcon="bg-warning"
                    :cursor="true" :onClick="openModal" :paramFunction="4" />
            </div>
        </div>
    </the-content-body>
    <!-- Modal opciones caja -->
    <widget-modal-boostrap modalId="modalCasher" :modalTitle="section.title"
        modalClassSize="modal-dialog-scrollable modal-xl" :showFooter="false" modalStatic="static">
        <template v-slot:modalBody>
            <consult-available v-if="section.number === 1"></consult-available>
            <consult-sales v-if="section.number === 2" ></consult-sales>
            <consult-account-status v-if="section.number === 3" ></consult-account-status>
            <consult-payments v-if="section.number === 4" ></consult-payments>
        </template>
    </widget-modal-boostrap>
</template>

<script>
import { ref, onMounted, defineAsyncComponent, onUnmounted, getCurrentInstance } from 'vue';
import { useStore } from 'vuex'
import { Modal } from 'bootstrap';

import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetInfoBox from '@/common/widgets/WidgetInfoBox.vue'


export default {
    components: {
        TheContentBody,
        TheContentHeader,
        WidgetInfoBox,
        WidgetModalBoostrap,
        ConsultAvailable: defineAsyncComponent(() => import(/*webpackChunkName:"ConsultAvailable"*/'@/modules/casher/components/ConsultAvailable.vue')),
        ConsultSales: defineAsyncComponent(() => import(/*webpackChunkName:"ConsultSales"*/'@/modules/casher/components/ConsultSales.vue')),
        ConsultAccountStatus: defineAsyncComponent(() => import(/*webpackChunkName:"ConsultAccountStatus"*/'@/modules/casher/components/ConsultAccountStatus.vue')),
        ConsultPayments: defineAsyncComponent(() => import(/*webpackChunkName:"ConsultPayments"*/'@/modules/casher/components/ConsultPayments.vue')),
    },

    setup() {
        const modals = ref({ modalCasher: null })
        const store = useStore()
        const section = ref({
            title: '',
            number: 0
        })


        onMounted(() => {
            modals.modalCasher = new Modal('#modalCasher', {})
        })


        return {
            section,
            openModal: async (number) => {
                store.commit('casher/clear')
                store.commit('casher/hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
                switch (number) {
                    case 1:
                        section.value.title = 'Registrar venta'
                        section.value.number = 1
                        break;
                    case 2:
                        section.value.title = 'Consultar ventas'
                        section.value.number = 2
                        break;
                    case 3:
                        section.value.title = 'Recaudar'
                        section.value.number = 3
                        break;
                    case 4:
                        section.value.title = 'Consultar recaudos'
                        section.value.number = 4
                        break;
                }
                modals.modalCasher.show()

            },
            closeModal: () => {
                modals.modalCasher.hide()
            },
        }
    }

}
</script>

<style></style>