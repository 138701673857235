<template>
    <label v-if="title" :for="idSelect" class="fw-bold">{{ title }}</label>
    <select v-model="inputModel" :name="idSelect" :id="idSelect" class="form-control form-control-sm"
        :class="{ 'is-invalid': errors[idSelect] }" @blur="onBlur(paramFunction)" @change="onChange(paramFunction)" :disabled="disabled">
        <option value="">Seleccione...</option>
        <option v-for="(optionValue, key) in options" :value="withKey ? key : optionValue[optionId]"
            :key="optionValue[optionId]">
            {{ optionValue[optionName] }}
        </option>
    </select>
    <span v-if="errors[idSelect]" class="invalid-feedback" role="alert">
        <small>{{ errors[idSelect] ? errors[idSelect][0] : '' }}</small>
    </span>
</template>

<script>
import { computed } from "vue"
export default {

    props: {
        modelValue: {
            required: false,
            default: '',
            // type: String|Number,
        },
        optionId: String,
        optionName: String,
        options: Array | Object,
        withKey: {//Si se debe tener en cuenta la key del objeto como valor de la opción
            type: Boolean,
            default: false
        },
        idSelect: String,
        title: {
            type: String,
            default: ''
        },
        onBlur: {
            type: Function,
            default: () => { }
        },
        onChange: {
            type: Function,
            default: () => { }
        },
        paramFunction: {
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: Object,
    },
    setup(props, { emit }) {
        const inputModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', newValue)
            }
        })
        return {
            inputModel
        }

    }

}

</script>