import store from "@/store"
import { Toast } from "./toastSwalert"


export const setErrorsFields = ({ modulo, error,typeProcess }) => {
    //Si es error del backend
    if (error.response) {
        const { data, status } = error.response
        if (status === 422) {
            store.commit(`${modulo}/hideProcessShowAlert`, { messageServer: data.message, errorsFields: data.errors, typeAlert: 'danger',typeProcess })
        }else{
            store.commit(`${modulo}/hideProcessShowAlert`, { messageServer: JSON.stringify(data.errors), errorsFields: {}, typeAlert: '',typeProcess })

        }
    }else{
        //Si es error del frontend
        Toast.fire({title:'Error Javascript',text:error,icon:'error',showCloseButton:true})
        store.commit(`${modulo}/hideProcessShowAlert`, { messageServer: error, errorsFields: {}, typeAlert: '',typeProcess })

    }
    return { response: false }
}