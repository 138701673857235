import { useStore } from 'vuex'
import { computed, ref } from 'vue'

import useRelationShip from '@/modules/parameterization/relationship/composables/useRelationship'


const useReferences = () => {
    const store = useStore()
    const {relationshipSelect} = useRelationShip()

    const numPages = ref("5")
   

    const loadReferenceList = async(formData) => {
        const resp = await store.dispatch('reference/loadReferenceList', formData)
        return resp
    }

    const createReference = async(formData) => {
        const resp = await store.dispatch('reference/createReference', formData)
        return resp
    }
    const updateReference = async(formData,referenceId) => {
        const resp = await store.dispatch('reference/updateReference', {formData,referenceId})
        return resp
    }
    const storeAnswerReference = async(formData,referenceId) => {
        const resp = await store.dispatch('reference/storeAnswerReference', {referenceId,formData})
        return resp
    }

    const deleteReference = async(referenceId) => {
        const resp = await store.dispatch('reference/deleteReference', referenceId)
        return resp
    }

    const setErrorsFielFrontend = (errors) => {
        const resp = store.dispatch('request/setErrorsFielFrontend', errors)
    }


    return {
        numPages,
        setErrorsFielFrontend,

        loadReferenceList,
        createReference,
        updateReference,
        storeAnswerReference,
        deleteReference,

        errorsFields: computed(() => store.state['reference']['errorsFields']),
        isLoading: computed(() => store.state['reference']['isLoading']),
        isLoadingReference: computed(() => store.state['reference']['isLoadingReference']),
        isLoadinOperationReference: computed(() => store.state['reference']['isLoadinOperationReference']),
        messageServer: computed(() => store.state['reference']['messageServer']),
        currentReference: computed(() => store.state['reference']['currentReference']),
        referenceList: computed(() => store.state['reference']['referenceList']),
        showAlert: computed(() => store.state['reference']['showAlert']),
        typeAlert: computed(() => store.state['reference']['typeAlert']),

        relationshipSelect


    }
}

export default useReferences