/**
 * Encriptar y desencriptar datos
 */
import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_KEY); // Use Utf8-Encoder. 
const iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV);

/**
 * Encriptar datos
 * @param {*} data 
 * @returns {String} data encriptada
 */
export const encryption = (data) => {
    const encryptedCP = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv });
    const encryptedBase64 = encryptedCP.toString();
    return encryptedBase64
}
/**
 * Desencriptar datos
 * @param {*} data 
 * @returns {String} data desencriptada
 */
export const decryption = (data) => {
    const decryptedWA = CryptoJS.AES.decrypt(data, key, { iv: iv });
    const decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);
    return decryptedUtf8
}