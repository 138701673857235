<template>
    <!-- Messages Dropdown Menu -->
    <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-comments"></i>
            <span class="badge badge-danger navbar-badge">{{ chatsNavbar.length }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="max-height: 280px; overflow-y: auto;">
            <template v-for="chat in chatsNavbar" :key="chat.CA202CODCHAT">
                <a href="#" class="dropdown-item">
                    <!-- Message Start -->
                    <div class="media">
                        <img src="https://ui-avatars.com/api/?name=John+Doe" alt="User Avatar"
                            class="img-size-50 mr-3 img-circle">
                        <div class="media-body">
                            <h3 class="dropdown-item-title">
                                {{ chat.CA202ASUNTO }}
                                <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                            </h3>
                            <p class="text-sm">{{ lastMessageChat(chat.CA202MENSAJE)['mensaje'] }}</p>
                            <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i>{{
                                getDiffForHumans(chat.CA202TIMACTUALIZACION) }}</p>
                        </div>
                    </div>
                    <!-- Message End -->
                </a>
                <div class="dropdown-divider"></div>
            </template>
            <a v-if="checkPermission(61)" href="#" @click="$router.push({name:'chat-index'})" class="dropdown-item dropdown-footer">Ver todos los chats</a>
        </div>
    </li>
</template>

<script>
import useChat from '@/modules/chat/composables/useChat'
import useAuth from '@/modules/auth/composables/useAuth'


export default {
    setup() {
        const { getChatsNavbar, chatsNavbar, lastMessageChat, getDiffForHumans } = useChat()
        const { checkGroupUser, userDataLoggedIn,checkPermission } = useAuth()
        getChatsNavbar()

        if (checkGroupUser('usuario-analista', userDataLoggedIn.value.perfil)) {
            window.Echo
                .private(`chat-shivam`)
                .listen('.ChatEvent', (e) => {
                    getChatsNavbar()
                })
        }
        if (checkGroupUser('usuario-almacen', userDataLoggedIn.value.perfil)) {
            window.Echo
                .private(`chat-tradicional.${userDataLoggedIn.value.id}`)
                .listen('.ChatEvent', (e) => {
                    getChatsNavbar()

                })
        }

        return {
            chatsNavbar,
            lastMessageChat,
            getDiffForHumans,
            checkPermission
        }
    }

}
</script>

<style lang="css" scoped src="../css/adminlte.css"></style>