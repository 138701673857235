<template>
    <div :class="`${spinner(typeSpinner)} `" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
    props: {
        spinnerClass: {
            type: String
        },
        typeSpinner: {
            type: Number,
            default: 2
        },
        small:{
            type:Boolean,
            default:false,
        }
    },
    setup(props) {

        return {

            spinner: (t) => {
                const type = ['', 'spinner-border', 'spinner-grow' ,'spinner-border spinner-border-sm', 'spinner-grow spinner-grow-sm']
                return type[t]
            }

        }

    },
}
</script>