//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    relationshipList: [],
    relationshipSelect:[],
    relationship: {
        CA009NOMBREPARENTESCO:'' ,
        relevancia:{}

    },
    isLoading: {
        list: false,
        relationship: false,
        operation: false,
        relevance: false
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: ''
    }
})