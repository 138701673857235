<template>
  <TheContentHeader titlePage="Parametrizacion de Parentesco" />
  <the-content-body>
    <widget-card title="Parentesco" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Parentesco</div>
          <base-button-fab @on:click="openModal('newRelationship')" :permissionId="25"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
          :typeAlert="alert.typeAlert"></widget-alerts>
        <base-input-group :icon="'fa-solid fa-magnifying-glass'" v-model="formData.search"
          :onBlur="callLoadRelationshipIndex"></base-input-group>
        <widget-loading v-if="isLoading.list" />
        <template v-else-if="relationshipList.data.length > 0">
          <widget-select-paginator v-model="formData.numPages" :onChange="callLoadRelationshipIndex"></widget-select-paginator>
          <base-table-forms :data="relationshipList" :dataFunction="callLoadRelationshipIndex" :headers="headers"
            :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
            <tr v-for="relationship in relationshipList.data" :key="relationship.CA009IDPARENTESCO">
              <td class="text-center">{{ relationship.CA009IDPARENTESCO }}</td>
              <td class="text-center">{{ relationship.CA009NOMBREPARENTESCO }}</td>
              <td class="text-center">
                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
                  { label: 'Editar', action: () => { openModal(relationship.CA009IDPARENTESCO.toString()) }, permissionId: 25, visible: true },
                ]"></widget-dropdown>
              </td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <widget-modal-boostrap modalId="modalRelationship"
    :modalTitle="relationshipId === 'newRelationship' ? 'Crear Parentesco' : 'Actualizar Parentesco'"
    modalClassSize="modal-lg" :showFooter="false">
    <template v-slot:modalBody>
      <widget-loading v-if="isLoading.relationship" />
      <template v-else>
        <form @submit.prevent="onSubmit">
          <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
            :typeAlert="alert.typeAlert"></widget-alerts>
          <div class="form-group row">
            <!-- Nombre Parentesco -->
            <div class="col-9">
              <base-input-forms v-model="CA009NOMBREPARENTESCO" :errors="alert.errorsFields" title="Nombre"
                inputId="CA009NOMBREPARENTESCO"></base-input-forms>
            </div>
            <div class="col-3">
              <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoading.operation"
                :disabled="isLoading.operation" label="Guardar" :permissionId="25"></base-button-forms>
            </div>
          </div>
        </form>
        <form v-if="isEditing" @submit.prevent="onAddRelevance">
          <!-- Relevancia -->
          <div class="form-group row mb-2">
            <div class="col-3">
              <base-select-forms idSelect="codObjetivo" :options="objetivesSelect" title="Objetivos"
                optionName="CA008NOMBREOBJETIVO" optionId="CA008CODOBJETIVO"
                :errors="alert.errorsFields"></base-select-forms>
            </div>
            <div class="col-3">
              <base-select-forms idSelect="valorRelevancia" :options="relevanceValue()" title="Relevancia"
                optionName="value" optionId="value" :errors="alert.errorsFields"></base-select-forms>
            </div>
            <div class="col-6">
              <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoading.relevance"
                :disabled="isLoading.relevance" label="Agregar/Actualizar" :permissionId="25"></base-button-forms>
            </div>
          </div>
          <base-table-forms :headers="['Objetivo', 'Relevancia']" :whitPagination="false">
            <tr v-for="relevancia in relationship.relevancia" :key="relevancia.CA008CODOBJETIVO">
              <td>
                {{ relevancia.CA008NOMBREOBJETIVO }}
              </td>
              <td class="text-center">
                {{ relevancia.pivot.CA010X100RELEVANCIA }}
              </td>
            </tr>
          </base-table-forms>
        </form>
      </template>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { Modal } from 'bootstrap';
import { useStore } from 'vuex'
import { Toast } from "@/helpers/toastSwalert"

import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';


import useRelationship from '@/modules/parameterization/relationship/composables/useRelationship'


export default {
  components: {
    BaseButtonFab,
    BaseInputForms,
    BaseTableForms,
    BaseButtonForms,
    BaseInputGroup,
    BaseSelectForms,
    TheContentBody,
    TheContentHeader,
    WidgetAlerts,
    WidgetCard,
    WidgetDropdown,
    WidgetLoading,
    WidgetModalBoostrap,
    WidgetSelectPaginator,


  },

  setup() {
    const relationshipId = ref('newRelationship')
    const formData = ref({
      search: '',
      numPages: '5',
      page: 1,
      column: 'CA009IDPARENTESCO',
      order: 'asc'

    })
    const headers = ref([
      { encabezado: 'ID', columna: 'CA009IDPARENTESCO' },
      { encabezado: 'Nombre', columna: 'CA009NOMBREPARENTESCO' },
      { encabezado: 'Acciones', columna: null },

    ])

    const modals = ref({ modalRelationship: null })
    const store = useStore()

    const {
      isLoading,
      alert,
      loadRelationshipIndex,
      relationshipList,
      createRelationship,
      getRelationship,
      updateRelationship,
      relationship,
      loadObjetiveListSelect,
      objetivesSelect,
      addRelevance,
    } = useRelationship()


    onMounted(() => {
      modals.modalRelationship = new Modal('#modalRelationship', {})
    })
    const callLoadRelationshipIndex = (page, column) => {
      formData.value.page = page
      if (column) {
        formData.value.column = column
        formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
      }
      loadRelationshipIndex(formData.value)
    }
    callLoadRelationshipIndex()
    loadObjetiveListSelect()

    const openModal = async (id) => {
      store.commit('relationship/clearRelationship')
      relationshipId.value = id
      modals.modalRelationship.show()
      if (id != 'newRelationship') {
        await getRelationship(id)
      }
    }
    const closeModal = () => {
      modals.modalRelationship.hide()
    }


    return {
      relationshipId,
      relationship,
      relationshipList,
      createRelationship,
      getRelationship,
      updateRelationship,
      formData,
      headers,
      alert,
      isLoading,
      callLoadRelationshipIndex,
      objetivesSelect,

      isEditing:computed(()=>relationshipId.value!='newRelationship'),

      relevanceValue: () => {
        let data = []
        for (let index = 1; index < 100; index++) {
          data.push({
            value: index / 100
          })

        }
        data.push({
          value:'1.00'
        })
        return data
      },

      openModal,
      closeModal,

      CA009NOMBREPARENTESCO: computed({
        get: () => store.state['relationship']['relationship']['CA009NOMBREPARENTESCO'],
        set: (val) => store.commit('relationship/updateField', { field: 'CA009NOMBREPARENTESCO', value: val })
      }),

      onSubmit: async (e) => {
        const formData = new FormData(e.target)
        if (relationshipId.value === 'newRelationship') {
          const { response } = await createRelationship(formData)
          if (response) {
            openModal(relationshipList.value.data[0].CA009IDPARENTESCO)
          }
        } else {
          const { response } = await updateRelationship(relationshipId.value, formData)
        }
      },

      onAddRelevance: async (e) => {
        const formData = new FormData(e.target)
        const { response } = await addRelevance(relationshipId.value, formData)
      },


    }
  }

}
</script>

<style></style>