<template>
    <!-- Content Header (Page header) -->
    <!-- <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">{{ titlePage }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li v-if="nameCurrentPage!='Inicio'" class="breadcrumb-item"><a href="#"> <router-link to="/dashboard">Inicio</router-link> </a></li>
                        <li class="breadcrumb-item active">{{ nameCurrentPage }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-fluid py-2">
        <div class="col">
            <nav aria-label="breadcrumb" class="bg-body-tertiary rounded-3 p-3 mb-4">
                <ol class="breadcrumb mb-0">
                    <li v-if="nameCurrentPage != 'Inicio'" class="breadcrumb-item"><a href="#">
                            <router-link to="/dashboard">Inicio</router-link> </a></li>
                    <li class="breadcrumb-item active">{{ nameCurrentPage }}</li>
                </ol>
            </nav>
        </div>
    </div>

</template>

<script>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';


export default {

    props: {
        titlePage: {
            type: String,
            default: 'Título Página'
        }

    },
    setup() {
        const router = useRouter()
        const route = useRoute()

        return {
            nameCurrentPage: computed(() => route.meta.title)
        }


    },
}
</script>
