export default () => ({
    tasksList: [],
    summaryTask: [],
    task: {
        codTarea:0,
        codCategoria: 0,
        nomCategoria: '',
        nombre: '',
        descripcion: '',
        prioridad: '',
        fechaInicio: '',
        fechaEjecucionEstimada: '',
        frecuenciaEjecucion: 0,
        codUsuarioAsignado: 0,
        emailUsuarioAsignado: '',
        estado:'',
        observaciones:'',
        usuarioAsignado:{},
        codUsuarioCreador:0,
        usuarioCreador:{},
        seguimiento: 0,
        categoria:{},
        repeticiones:[]
    },
    contactosFrecuentes:[],
    categorias:[],
    isLoading: {
        task: false,
        list: false,
        operation: false,
        summary: false,
        status: false
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: ''
    },
    taskParameters: {}
})