<template>
    <!-- Modal -->
    <div class="modal fade" :id="modalId" data-bs-focus="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        :data-bs-backdrop="modalStatic">
        <div class="modal-dialog" :class="modalClassSize">
            <div class="modal-content">
                <div class="modal-header" :class="modalClassHeader">
                    <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('on:modalClose')"></button>
                </div>
                <div class="modal-body">
                    <slot name="modalBody"></slot>
                </div>
                <div v-if="showFooter" class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" @click="$emit('on:click')">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modalId: {
            type: String,
            required: true
        },
        modalTitle: {
            type: String,
            default: ''
        },
        modalStatic: {
            type: String,
            default: 'no-static'//static
        },
        modalClassSize: {
            type: String,
            default: ''//modal-xl, modal-lg, modal-sm,modal-fullscreen,modal-fullscreen-sm-down,modal-fullscreen-md-down,modal-fullscreen-lg-down,modal-fullscreen-xl-down,modal-fullscreen-xxl-down,modal-dialog-scrollable
        },
        modalClassHeader:{
            type:String,
            default:''
        },
        modalClass:{
            type:String,
            default:''
        },
        showFooter: {
            type: Boolean,
            default: true
        },

    },
    setup() {

    },
}
</script>