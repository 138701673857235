import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setRequestList = (state, { data }) => {
    setRequestParameters(state, { data })
    state.requestsLists = data.solicitudes
}
export const setRequestParameters = (state, { data }) => {
    state.requestParameters = data.parametros
    const estadosSolicitud = state.requestParameters.estados
    const estadosFase = state.requestParameters.fases_estado
    // Colocar la URL de los íconos
    const urlServer = process.env.VUE_APP_BASE_URL.replace('api', '');
    for (const key in estadosSolicitud) {
        state.requestParameters.estados[key][2] = urlServer + state.requestParameters.estados[key][2]
    }
    for (const key in estadosFase) {
        state.requestParameters.fases_estado[key][2] = urlServer + state.requestParameters.fases_estado[key][2]
    }
}
export const setRequest = (state, { data }) => {
    for (const key in data) {
        if (key.includes('Fecha')) {
            data[key] = inputDate(data[key], 3);
        }
        if (key.includes('VLR')) {
            data[key] = inputCurrence(data[key], 0, 'state');
        }
        if (key.includes('NumeroId')) {
            data[key] = inputNumId(data[key]);
        }
        if (key.includes('Telefono') || key.includes('Celular')) {
            data[key] = inputPhone(data[key]);
        }
    }
    state.request = data
}

export const clearRequest = (state) => {
    for (const property in state.request) {
        state.request[property] = ''
    }
}

export const updateField = (state, { field, value }) => {
    state.request[field] = value;
}


export const addRequest = (state, { data }) => {
    const { solicitud } = data
    state.requestsLists.data.unshift(solicitud)
}

export const updateRequest = (state, { data }) => {
    const { solicitud } = data
    const idx = state.requestsLists.data.map(u => u.Ca111CodSolicitud).indexOf(solicitud.Ca111CodSolicitud)
    state.requestsLists.data[idx] = solicitud

}

export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'requestList':
            state.isLoading = true
            break;
        case 'request':
            state.isLoadingRequest = true
            break;
        case 'operation':
            state.isLoadingOperationRequest = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingRequest = false
    state.isLoadingOperationRequest = false
    state.messageServer = messageServer
    // state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }
}
