<template>
  <TheContentHeader titlePage="Almacenes" />
  <the-content-body>
    <widget-card title="Almacenes" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Almacenes</div>
          <base-button-fab @on:click="openModal('newShop')" :permissionId="30"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
        <!-- Filtros de búsqueda -->
        <form @submit.prevent="callLoadShopList">
          <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-input-forms v-model="formData.codAlmacen" title="Cod. Almacén" :errors="errorsFields"
                inputId="codAlmacen"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-list v-model="formData.tipoIdentidad" idSelect="tipoIdentidad" :errors="errorsFields"
                tipoLista="TPIDEN" listaPadre="" listaPadreId="" title="Tipo ID Aliado"></base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-input-forms v-model="formData.numIdentidad" title="No. Id Aliado" :errors="errorsFields"
                inputId="numIdentidad" typeData="numId"></base-input-forms>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <base-button-forms classButton="btn-primary" :isLoading="isLoading" :disabled="isLoading" label="Buscar"
              :permissionId="29"></base-button-forms>
          </div>
        </form>

        <widget-loading v-if="isLoading" />
        <template v-else-if="shopList.data">
          <!-- Resultado búsqueda -->
          <widget-select-paginator v-model="formData.numPages" :onChange="callLoadShopList"></widget-select-paginator>
          <base-table-forms :data="shopList" :dataFunction="callLoadShopList" :headers="headers"
            :orderColumn="formData.column"  :order="formData.order" :whitOrder="true">
            <tr v-for="shop in shopList.data" :key="shop.Ca110CodAlmacen">
              <td class="text-center">{{ shop.Ca110CodAlmacen }}</td>
              <td class="text-center">{{ shop.Ca110Alias }}</td>
              <td class="text-center">{{ shop.nombreAliado }}</td>
              <td>
                <widget-dropdown v-if="canEdit(shop)" icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
            { label: 'Editar', action: () => { openModal(shop.Ca110CodAlmacen.toString()) }, permissionId: 31, visible: true },
          ]"></widget-dropdown>
              </td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <!-- Modal para crear o actualizar solicitud -->
  <widget-modal-boostrap modalId="modalShop"
    :modalTitle="shopId === 'newShop' ? 'Crear Almacén' : 'Actualizar Almacén No. ' + shopId"
    modalClassSize="modal-dialog-scrollable modal-xl" :showFooter="false">
    <template v-slot:modalBody>
      <shop-operation :typeOperation="shopId" @on:createdShop="openModal" @on:closeModal="closeModal"
        @on:LoadListShop="callLoadShopList"></shop-operation>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { computed, onMounted, ref, defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { useStore } from 'vuex'

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';


import useShop from '@/modules/shop/composables/useShop'
import useAuth from '@/modules/auth/composables/useAuth'


export default {
  components: {
    WidgetAlerts,
    BaseButtonFab,
    BaseButtonForms,
    WidgetCard,
    TheContentBody,
    TheContentHeader,
    WidgetDropdown,
    BaseInputForms,
    WidgetLoading,
    WidgetModalBoostrap,
    BaseSelectForms,
    BaseSelectList,
    WidgetSelectPaginator,
    BaseTableForms,
    ShopOperation: defineAsyncComponent(() => import(/*webpackChunkName:"ShopOperation"*/'@/modules/shop/components/ShopOperation.vue')),



  },

  setup() {
    const formData = ref({
      codAlmacen: '',
      tipoPersona: '',
      tipoIdentidad: '',
      numIdentidad: '',
      numPages: '5',
      page: '',
      column: 'Ca110CodAlmacen',
      order: 'asc'
    })
    const store = useStore()
    const modals = ref({ modalShop: null })
    const shopId = ref()
    const headers = ref(
      [
        { encabezado: 'ID', columna: 'Ca110CodAlmacen' },
        { encabezado: 'Almacén', columna: 'Ca110Alias' },
        { encabezado: 'Aliado', columna: 'nombreAliado' },
        { encabezado: 'Acciones', columna: null }
      ]
    )
    const {
      isLoading,
      loadShopList,
      shopList,
      getShop,
      messageServer,
      showAlert,
      typeAlert,
      errorsFields,

    } = useShop()

    const { checkGroupUser, userDataLoggedIn } = useAuth()


    onMounted(() => {
      modals.modalShop = new Modal('#modalShop', {})
    })
    const callLoadShopList = async (page, column) => {
      formData.value.page = page
      if (column) {
        formData.value.column = column
        formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
      }
      await loadShopList(formData.value)
    }
    callLoadShopList()

    return {
      shopId,
      shopList,
      messageServer,
      showAlert,
      typeAlert,
      isLoading,
      errorsFields,
      formData,
      headers,


      callLoadShopList,
      openModal: async (id) => {
        store.commit('shop/clearShop')
        modals.modalShop.show()
        shopId.value = id
        if (id != 'newShop') {
          await getShop(id)
        }
      },
      closeModal: () => {
        modals.modalShop.hide()
      },

      canEdit: (shop) => {
        // if (checkGroupUser('usuario-analista', userDataLoggedIn.value.perfil)) {
        //   return allied.Ca100Estado === 'P'
        // }
        // if (checkGroupUser('usuario-comercial', userDataLoggedIn.value.perfil)) {
        //   return ['I', 'AP', 'AE', 'N', 'A', 'C'].includes(allied.Ca100Estado)
        // }
        return true

      }

    }
  }

}
</script>

<style></style>