import { isAuthenticatedGuard, isNotAuthenticatedGuard } from '@/guard/auth-guard'
export default{
    name:'errors',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'/:pathMatch(.*)*',
            beforeEnter: [isAuthenticatedGuard],
            name:'error-404',
            component:()=>import(/* webpackChunkName: "Error404" */ '@/modules/errors/views/404')
        },
        {
            path:'/error-403',
            beforeEnter: [isAuthenticatedGuard],
            name:'error-403',
            component:()=>import(/* webpackChunkName: "Error403" */ '@/modules/errors/views/403')
        }
    ]    
}