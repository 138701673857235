import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import useRequest from '@/modules/credit-request/composables/useRequest'

const useCreditStudy = () => {
    const store = useStore()

    const numPages = ref("5")
    const modals = ref({ modalRequest: null })

    const { requestParameters } = useRequest()


    const loadRequest = async (page = 1, numPages = 5) => {
        // const resp1 = await store.dispatch('request/loadRequestParameters');
        const resp2 = await store.dispatch('studyRequest/loadRequest', { page, numPages })
        return resp2
    }
    const getRequest = async (requestId, numFase) => {
        const resp = await store.dispatch('studyRequest/getRequest', { requestId, numFase })
        return resp
    }
    const returnRequest = async (requestId) => {
        const resp = await store.dispatch('studyRequest/returnRequest', requestId)
        return resp
    }
    const storeDecisionFase1 = async (requestId, formData) => {
        const resp = await store.dispatch('studyRequest/storeDecisionFase1', { requestId, formData })
        return resp
    }
    const getQuestions = async (requestId, referenceId) => {
        const resp = await store.dispatch('studyRequest/getQuestions', { requestId, referenceId })
        return resp
    }
    const createAnswer = async (requestId, referenceId, formData) => {
        const resp = await store.dispatch('studyRequest/createAnswer', { requestId, referenceId, formData })
        return resp
    }
    const startQualification = async (requestId, referenceId) => {
        const resp = await store.dispatch('studyRequest/startQualification', { requestId, referenceId })
        return resp
    }
    const qualify = async (requestId, objetiveResultId, referenceId, formData) => {
        const resp = await store.dispatch('studyRequest/qualify', { requestId, objetiveResultId, referenceId, formData })
        return resp
    }
    const storeDecisionFase2 = async (requestId, formData) => {
        const resp = await store.dispatch('studyRequest/storeDecisionFase2', { requestId, formData })
        return resp
    }
    const storeDecisionFase3 = async (requestId, formData) => {
        const resp = await store.dispatch('studyRequest/storeDecisionFase3', { requestId, formData })
        return resp
    }

    const createQuota = async (formData) => {
        const resp = await store.dispatch('studyRequest/createQuota', { formData })
        return resp
    }


    const setErrorsFielFrontend = (errors) => {
        const resp = store.dispatch('studyRequest/setErrorsFielFrontend', errors)
    }

    return {

        modals,
        numPages,

        setErrorsFielFrontend,

        createAnswer,
        storeDecisionFase1,
        getQuestions,
        getRequest,
        loadRequest,
        requestParameters,
        returnRequest,
        startQualification,
        qualify,
        storeDecisionFase2,
        storeDecisionFase3,
        createQuota,

        fase2decision: computed(() => store.state['studyRequest']['fase2decision']),
        isLoading: computed(() => store.state['studyRequest']['isLoading']),
        isLoadingOperationQuestion: computed(() => store.state['studyRequest']['isLoadingOperationQuestion']),
        isLoadingOperationRequest: computed(() => store.state['studyRequest']['isLoadingOperationRequest']),
        isLoadingQuestions: computed(() => store.state['studyRequest']['isLoadingQuestions']),
        isLoadingRequest: computed(() => store.state['studyRequest']['isLoadingRequest']),
        objetiveResult: computed(() => store.state['studyRequest']['objetiveResult']),
        questions: computed(() => store.state['studyRequest']['questions']),
        references: computed(() => store.state['studyRequest']['references']),
        request: computed(() => store.state['studyRequest']['request']),
        requestsLists: computed(() => store.state['studyRequest']['requestsLists']),
        alert: computed(() => store.state['studyRequest']['alert']),
        centralInformationValidation: computed(() => store.state['studyRequest']['centralInformationValidation']),

        canEditFase1: computed(() => store.state['studyRequest']['request'].Ca111EstadoFase1 === 'AB' || store.state['studyRequest']['request'].Ca111EstadoFase1 === 'PE'),
        canEditFase2: computed(() => store.state['studyRequest']['request'].Ca111EstadoFase2 === 'AB' || store.state['studyRequest']['request'].Ca111EstadoFase2 === 'PE'),
        headerRequest: computed(() => {
            const request = store.state['studyRequest']['request']

            return `Solicitud No. ${request.Ca111CodSolicitud} | ${request.Ca111PrimerNombre} ${request.Ca111PrimerApellido} | Fecha Nac: ${request.Ca111FechaNacimiento} | ${request.alias_tipo_id_cliente}: ${request.Ca111NumeroIdCliente} | Fecha Exp: ${request.Ca111FechaExpDocID} | Ciudad Exp: ${request.Ca111CiudadExpedicion}`


        }),





    }
}

export default useCreditStudy