import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setNotificationList = (state, { data }) => {
    state.notificationList = data
}
export const setNotification = (state, { data }) => {
    state.customer = data
}

export const updateNotificationList = (state, { notificationId }) => {
    state.notificationList = state.notificationList.filter(n => n.id != notificationId)
}


export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.messageServer = ''
    state.alert.showAlert = false
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false
    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.alert.showAlert = false
        }, 5000);
    }
}