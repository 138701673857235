import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setAlliedList = (state, { data }) => {
    state.alliedList = data.aliados
    state.alliedParameters = data.parametros
}
export const setAllied = (state, { data }) => {
    for (const key in data) {
        if (key.includes('Fecha')) {
            data[key] = inputDate(data[key], 3);
        }
        if (key.includes('VLR')) {
            data[key] = inputCurrence(data[key], 0, 'state');
        }
        if (key.includes('NumeroId')) {
            data[key] = inputNumId(data[key]);
        }
        if (key.includes('Telefono') || key.includes('Celular')) {
            data[key] = inputPhone(data[key]);
        }
    }
    state.allied = data
}
export const setSimpleList = (state, { data }) => {
    //Ordenar listado por nombre de aliado
    data.sort((x, y) => x.nombreAliado.localeCompare(y.nombreAliado));
    state.simpleList=data
}
export const addAllied = (state, { data }) => {
    state.alliedList.data.unshift(data)
    state.allied.ca100CodAliado=data.ca100CodAliado
}
export const updateField = (state, { field, value }) => {
    state.allied[field] = value;
}
export const updateAllied = (state, { data }) => {
    const idx = state.alliedList.data.map(u => u.ca100CodAliado).indexOf(data.ca100CodAliado)
    state.alliedList.data[idx] = data
}
export const clearAllied = (state) => {
    for (const property in state.allied) {
        state.allied[property] = ''
    }
}


export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'alliedList':
            state.isLoading = true
            break;
        case 'allied':
            state.isLoadingAllied = true
            break;
        case 'operation':
            state.isLoadingOperationAllied = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingAllied = false
    state.isLoadingOperationAllied = false
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'
    state.alert.messageServer = messageServer

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }

}