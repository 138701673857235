import { isAuthenticatedGuard, hasChangedPassword } from '@/guard/auth-guard'
import NotificationsList from '../views/NotificationsList.vue'
export default{
    name:'notifications',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword],
            meta:{permission:0,showNav: false},
            name:'notification-index',
            component:NotificationsList
        },
    ]
    
}