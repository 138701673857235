<template>
    <label v-if="title" :for="inputId" class="fw-bold">{{ title }}</label>
    <input v-model="inputModel" :list="inputId + '-list'" :class="{ 'is-invalid': errors[inputId] }"
        :disabled="disabled" :id="inputId" :name="inputId" :required="required" @blur="onBlur(paramFunction)"
        @change="onChange(paramFunction)" @keyup="onKeyup(paramFunction)" autocomplete="off"
        class="form-control form-control-sm" :placeholder="placeholder">
    <span v-if="errors[inputId]" class="invalid-feedback" role="alert">
        <small>{{ errors[inputId] ? errors[inputId][0] : '' }}</small>
    </span>
    <datalist :id="inputId + '-list'">
        <option v-for="l in list" :key="l[optionValue]" :value="l[optionName]" :label="l[optionValue]==l[optionName]?'':l[optionValue]"></option>
    </datalist>

</template>

<script>
import { computed, ref } from "vue"

import inputMaskFormat from "@/helpers/inputMaskFormat"

export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            // type: String|Number,
        },
        list: {
            required: true,
            default: []
        },
        optionValue: {
            required: true,
            default: ''
        },
        optionName: {
            required: true,
            default: ''
        },
        title: {
            type: String,
            default: null
        },//Label
        inputId: {
            type: String,
            default: null
        },
        inputName: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: []
        },
        onBlur: {
            type: Function,
            default: () => { }
        },
        onChange: {
            type: Function,
            default: () => { }
        },
        onKeyup: {
            type: Function,
            default: () => { }
        },
        paramFunction: {
            default: ''

        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String,
            default: ''
        }

    },
    setup(props, { emit }) {

        const format = inputMaskFormat()
        const inputModel = computed({
            get() {
                // Buscar el nombre correspondiente al valor actual
                const selectedOption = props.list.find(option => option[props.optionValue] === props.modelValue);
                return selectedOption ? selectedOption[props.optionName] : props.modelValue;
            },
            set(newValue) {

                // Buscar el valor correspondiente al nombre ingresado
                const selectedOption = props.list.find(option => option[props.optionName] === newValue);
                const valueToEmit = selectedOption ? selectedOption[props.optionValue] : newValue;
                emit('update:modelValue', valueToEmit);
            }
        })


        return {
            inputModel,
        }

    }

}
</script>