import { computed } from "vue";
import { useStore } from "vuex";

const useProduct = () => {
    const store = useStore()

    const loadProductList = async(formData) => {
        return await  store.dispatch('product/loadProductList', { formData })
    }
    const createProduct = async(formData) => {
        return await  store.dispatch('product/createProduct', { formData })
    }
    const getProduct = async(productId) => {
        return await  store.dispatch('product/getProduct', { productId })
    }
    const updateProduct = async(productId,formData) => {
        return await  store.dispatch('product/updateProduct', { productId,formData })
    }
    const deleteProduct = async(productId) => {
        return await  store.dispatch('product/deleteProduct', { productId })
    }
    const storeProductShop = async(productId,formData) => {
        return await  store.dispatch('product/storeProductShop', { productId,formData })
    }
    const deleteProductShop = async(productId,codShop) => {
        return await  store.dispatch('product/deleteProductShop', { productId,codShop })
    }

    

    return {
        loadProductList,
        createProduct,
        getProduct,
        updateProduct,
        deleteProduct,
        storeProductShop,
        deleteProductShop,

        product: computed(() => store.state['product']['product']),
        productList: computed(() => store.state['product']['productList']),
        productParameters: computed(() => store.state['product']['productParameters']),
        productCategorie: computed(() => store.state['product']['productCategorie']),
        errorsFields: computed(() => store.state['product']['errorsFields']),
        isLoading: computed(() => store.state['product']['isLoading']),
        isLoadingProduct: computed(() => store.state['product']['isLoadingProduct']),
        isLoadingOperationProduct: computed(() => store.state['product']['isLoadingOperationProduct']),
        isLoadinStoreProductShop: computed(() => store.state['product']['isLoadinStoreProductShop']),
        messageServer: computed(() => store.state['product']['messageServer']),
        showAlert: computed(() => store.state['product']['showAlert']),
        typeAlert: computed(() => store.state['product']['typeAlert']),


    }



}


export default useProduct