import {
    isAuthenticatedGuard,
    hasActiveShopAllied,
    hasNoActiveShopAllied,
    isAuthorized,
    hasChangedPassword
} from '@/guard/auth-guard'
import UsersLists from '@/modules/users/views/UsersLists'
import UserSelectShop from '../views/UserSelectShop.vue'
import UserChangePassword from '../views/UserChangePassword.vue'
import UserProfile from '../views/UserProfile.vue'
export default {
    name: 'users',
    component: () =>
        import( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [
        {
            path: '',
            beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized, hasActiveShopAllied],
            name: 'users-index',
            meta: { permission: 3, showNav: false },
            component: UsersLists
        },
        {
            path: '/select-user-shop',
            beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized, hasNoActiveShopAllied],
            name: 'select-user-shop',
            meta: { permission: 12, showNav: false },
            component: UserSelectShop
        },
        {
            path: '/profile',
            beforeEnter: [isAuthenticatedGuard,isAuthorized],
            name: 'my-profile',
            meta: {
                title: 'Perfil',
                icon: 'fas fa-users',
                showNav: true,
                permission: 8,
                group: 'acount',
            },
            component: UserProfile
        },
        {
            path: '/profile/:id',
            beforeEnter: [isAuthenticatedGuard,isAuthorized],
            name: 'profile',
            meta: {
                title: 'Perfil',
                icon: 'fas fa-users',
                showNav: false,
                permission: 8,
                group: 'acount',
            },
            component: UserProfile,
            props: (router) => {
                return {
                    userId: router.params.id
                }
            },
        },
        {
            path: '/change-password',
            beforeEnter: [isAuthenticatedGuard],
            name: 'change-password',
            meta: {
                title: 'Cambiar contraseña',
                icon: 'fas fa-users',
                showNav: true,
                permission: 0,
                group: 'acount',
            },
            component: UserChangePassword
        },
    ]



}