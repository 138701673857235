import { computed } from "vue";
import { useStore } from "vuex";

const useAuth = () => {
    const store = useStore()
    const userPermissions = store.state['auth']['permissions']
    const userParameters = store.state['auth']['userParameters']

    const loginUser = async (user) => {
        const resp = await store.dispatch('auth/signInUser', user)
        return resp
    }
    const checkAuthStatus = async () => {
        const resp = await store.dispatch('auth/checkAuthentication')
        return resp
    }
    const logout = (porInactividad = false) => {
        return store.dispatch('auth/logout', porInactividad)
    }
    const checkPermission = (permissionId) => {
        if (permissionId == 0) {
            return true
        }
        return userPermissions.some(p => p.id === permissionId)
    }
    const checkGroupUser = (groupName, rolName) => {
        return userParameters.grupos[groupName].some(p => p === rolName)
    }
    const getPermissions = (modulePermission) => {
        const permissions = userPermissions.filter(p => p.module == modulePermission)
        return permissions
    }
    const requestLinkChangePassword = async (email) => {
        return await store.dispatch('auth/requestLinkChangePassword', { email })
    }
    const changePassword = async (formData) => {
        return await store.dispatch('auth/changePassword', { formData })
    }
    const changeAnalystShift = async (codShift) => {
        return await store.dispatch('auth/changeAnalystShift', { codShift })
    }
    const tokenRefresh = async () => {
        return await store.dispatch('auth/tokenRefresh')
    }
    const setCurrentPage = (currentPage) => {
        store.dispatch('auth/setCurrentPage', currentPage)
    }
    const setErrorsFielFrontend = (errors) => {
        const resp = store.dispatch('auth/setErrorsFielFrontend', errors)
    }






    return {
        checkAuthStatus,
        loginUser,
        logout,
        setErrorsFielFrontend,
        setCurrentPage,
        checkGroupUser,
        requestLinkChangePassword,
        changePassword,
        changeAnalystShift,
        tokenRefresh,



        isLoading: computed(() => store.state['auth']['isLoading']),
        messageServer: computed(() => store.state['auth']['messageServer']),
        showAlert: computed(() => store.state['auth']['showAlert']),
        errorsFields: computed(() => store.state['auth']['errorsFields']),
        typeAlert: computed(() => store.state['auth']['typeAlert']),
        userDataLoggedIn: computed(() => store.state['auth']['UserLoggedIn']),
        currentPage: computed(() => store.state['auth']['currentPage']),
        system: computed(() => store.state['auth']['system']),
        userParameters: computed(() => store.state['auth']['userParameters']),

        checkPermission,
        getPermissions

    }



}


export default useAuth