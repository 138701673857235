<template>
    <div class="row justify-content-center mt-5">
        <div class="d-flex justify-content-center">
            <widget-loading v-if="isLoadingShopList" />
            <widget-card v-else classHeader="bg-primary" >
                <template v-slot:header>
                    <div class="text-white">Seleccionar Almacén</div>
                </template>
                <template v-slot:body>
                    <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer" :typeAlert="alert.typeAlert"></widget-alerts>
                    <form @submit.prevent="onSelectUserShop" action="">
                        <base-select-forms v-model="formData.codAlmacen" idSelect="codAlmacen"
                            :options="shopAuthorizedLists" optionName="nombreAlmacen" optionId="codAlmacen"
                            :errors="alert.errorsFields"></base-select-forms>
                        <div class="d-flex justify-content-center">
                            <base-button-forms classButton="btn-primary mt-3" label="Seleccionar" :permissionId="12"
                                :isLoading="isLoading" :disabled="isLoading"></base-button-forms>
                        </div>
                    </form>
                </template>
            </widget-card>
        </div>
    </div>
</template>

<script>
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import useShop from '@/modules/shop/composables/useShop';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue'
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';

import { ref } from 'vue';
import useUsers from '../composables/useUsers';
import { useRouter } from 'vue-router'


export default {
    components: {
        BaseButtonForms,
        BaseInputGroup,
        BaseSelectForms,
        WidgetAlerts,
        WidgetCard,
        WidgetLoading

    },
    setup() {
        const router = useRouter()
        const { getShopAuthorizedLists, shopAuthorizedLists, isLoading:isLoadingShopList } = useShop()
        const { selectUserShop, alert, isLoading} = useUsers()
        const formData = ref({
            codAlmacen: ''
        })
        getShopAuthorizedLists('sesion')

        return {
            shopAuthorizedLists,
            formData,
            alert,
            isLoading,
            isLoadingShopList,

            onSelectUserShop: async () => {

                //Seleccionar el almacén
                const { response } = await selectUserShop(formData.value)
                if (response) {
                    //Refrescar la página para que obtenga el perfil del usuario, según el almacén seleccionado.
                    location.reload()
                }
            }
        }
    }

}
</script>

<style scoped>
.card {
    width: 450px;
    margin-top: 10%;
}
</style>