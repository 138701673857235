<template>
    <TheContentHeader titlePage="Notificaciones" />
    <the-content-body>
        <widget-card title="Notificaciones" classHeader="bg-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between">
                    <div class="text-white">Notificaciones</div>
                </div>
            </template>
            <template v-slot:body>
                <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                    :typeAlert="alert.typeAlert"></widget-alerts>
                <widget-loading v-if="isLoading.list" />
                <template v-else-if="notificationList.length > 0">
                    <div v-for="(notification, index) in notificationList" :key="notification.id"
                        class="alert alert-secondary" role="alert">
                        <div class="d-flex justify-content-between">
                            <h4 class="alert-heading">
                                {{ titleNotification(notification) }}
                            </h4>
                            <widget-spinner v-if="isMarking[index]" :typeSpinner="4"></widget-spinner>
                            <button v-else @click="onMarkAsRead(notification.id, index)" type="button"
                                class="btn-close"></button>
                        </div>
                        <p>{{ notification.data['descripcion'] }}</p>
                        <hr>
                        <p class="mb-0">{{ getDiffForHumans(notification.created_at) }}
                        </p>

                    </div>
                    <!-- <base-table-forms :whitPagination="false"
                        :headers="['Tipo', 'Asunto', 'Descripción', 'Tiempo descripción']">
                        <tr v-for="notification in notificationList" :key="notification.id">
                            <td>{{ notification.data['tipo'] + " " + notification.data['numero'] +
                                "(" + notification.data['cliente'] + ")" }}</td>
                            <td>{{ notification.data['asunto'] }}</td>
                            <td class="text-break"> {{ notification.data['descripcion'] }}</td>
                            <td>{{ getDiffForHumans(notification.created_at) }}</td>

                        </tr>
                    </base-table-forms> -->
                </template>
                <div v-else>
                    <div class="row justify-content-md-center">
                        <div class="col-12 alert-info text-center mt-5">
                            No hay Información a mostrar
                        </div>
                    </div>
                </div>
            </template>
        </widget-card>
    </the-content-body>
</template>
  
<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex'

import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import WidgetSpinner from '@/common/widgets/WidgetSpinner.vue'


import useNotification from '@/modules/notification/composables/useNotification'


export default {
    components: {
        WidgetAlerts,
        BaseButtonForms,
        WidgetCard,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        BaseInputForms,
        WidgetLoading,
        WidgetModalBoostrap,
        BaseSelectForms,
        BaseSelectList,
        WidgetSelectPaginator,
        BaseTableForms,
        WidgetSpinner



    },

    setup() {

        const {
            isLoading,
            alert,
            notificationList,
            markAsRead,
            titleNotification,
            getDiffForHumans,
            loadNotificationList,

        } = useNotification()
        const isMarking=ref({})



        loadNotificationList()


        return {
            notificationList,
            alert,
            isLoading,
            getDiffForHumans,
            titleNotification,
            isMarking,
            onMarkAsRead: async (notificationId, index) => {
                isMarking.value[index] = true
                await markAsRead(notificationId)
                isMarking.value[index] = false

            },

        }

    }
}
</script>
  
<style></style>