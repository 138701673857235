<template>
    <div class="info-box" @click="onClick(paramFunction)" :role="cursor?'button':''">
        <span class="info-box-icon" :class="bgIcon">
            <i v-if="image.length === 0" :class="icon"></i>
            <img v-else :src="image" alt="imagen" height="50">
        </span>

        <div class="info-box-content">
            <span class="info-box-text">{{ label }}</span>
            <span class="info-box-number">{{ number }}</span>
        </div>
        <!-- /.info-box-content -->
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: 'far fa-envelope'
        },
        image: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Texto'
        },
        number: {
            default: ''
        },
        bgIcon: {
            type: String,
            default: 'bg-light'
        },
        onClick: {
            type: Function,
            default: () => { }
        },
        paramFunction:{
            default:''
        },
        cursor:{
            type:Boolean,
            default:false
        }

    },
    setup(props) {



    }

}
</script>

<style lang="css" scoped src="../../modules/dashboard/css/adminlte.css"></style>