import { isAuthenticatedGuard,isAuthorized,hasActiveShopAllied,hasChangedPassword } from '@/guard/auth-guard'
import ProductList from '../views/ProductList.vue'
export default {
    name: 'product',
    component: () =>
        import ( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [{
        path: '',
        beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized,hasActiveShopAllied],
        name: 'product-index',
        meta:{permission:64,showNav: false},
        component: ProductList
    }, ]



}