//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    referenceList: [],
    currentReference: {
        // CA115CODSOLICITUD: '',
        // CA115CODCLIENTEALIADO: '',
        // CA115CODPARENTESCO: '',
        // CA115CODTIPOREFERENCIA: '',
        // CA115TIPOMODELO: '',
        // CA115NOMBRE: '',
        // CA115APELLIDO: '',
        // CA115CELULAR: '',
        // CA115TELEFONOFIJO: '',
        // CA115TIPORESPUESTA: '',
        // CA115OBSERVACIONES: '',

    },
    isLoading: false,
    isLoadingReference: false,
    isLoadinOperationReference: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: ''
})
