import { computed } from "vue";
import { useStore } from "vuex";
import { getShopAuthorizedLists } from "../store/actions";

const useShop=()=>{

    const store=useStore()

    const getShopAuthorizedLists=async(typeShop)=>{
        const resp=await store.dispatch('shop/getShopAuthorizedLists',typeShop)
        return resp
    }
    const loadShopList = async(formData) => {
        return await  store.dispatch('shop/loadShopList', { formData })
    }
    const createShop = async(formData) => {
        return await  store.dispatch('shop/createShop', { formData })
    }
    const getShop = async(shopId) => {
        return await  store.dispatch('shop/getShop', { shopId })
    }
    const updateShop = async(shopId,formData) => {
        return await  store.dispatch('shop/updateShop', { shopId,formData })
    }
    const ShopStudyDecision = async(shopId,formData) => {
        return await  store.dispatch('shop/ShopStudyDecision', { shopId,formData })
    }
    const getSimpleList = async(codAliado) => {
        return await  store.dispatch('shop/getSimpleList', { codAliado })
    }
    

    return{
        getShopAuthorizedLists,
        loadShopList,
        createShop,
        getShop,
        updateShop,
        ShopStudyDecision,
        getSimpleList,

        shop: computed(() => store.state['shop']['shop']),
        shopList: computed(() => store.state['shop']['shopList']),
        shopParameters: computed(() => store.state['shop']['shopParameters']),
        errorsFields: computed(() => store.state['shop']['errorsFields']),
        isLoading: computed(() => store.state['shop']['isLoading']),
        isLoadingShop: computed(() => store.state['shop']['isLoadingShop']),
        isLoadingOperationShop: computed(() => store.state['shop']['isLoadingOperationShop']),
        messageServer: computed(() => store.state['shop']['messageServer']),
        showAlert: computed(() => store.state['shop']['showAlert']),
        typeAlert: computed(() => store.state['shop']['typeAlert']),
        simpleList: computed(() => store.state['shop']['simpleList']),

        shopAuthorizedLists:computed(()=>store.getters['shop/getShopAuthorizedLists'])
    }

}

export default useShop