import { isAuthenticatedGuard,isAuthorized,hasChangedPassword } from '@/guard/auth-guard'
import ChatList from '../views/ChatList.vue'
export default {
    name: 'chat',
    component: () =>
        import ( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [{
        path: '',
        beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
        name: 'chat-index',
        meta:{permission:60,showNav: false},
        component: ChatList
    }, ]



}