<template>
    <widget-loading v-if="isLoadingRequest" />
    <template v-else>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-list v-model="Ca111CodOcupacion" idSelect="Ca111CodOcupacion" :errors="errorsFields" tipoLista="OCP"
                    listaPadre="" listaPadreId="" title="Profesión/Ocupación">
                </base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-list v-model="Ca111CIIU" idSelect="Ca111CIIU" :errors="errorsFields" tipoLista="CIIU" listaPadre=""
                    listaPadreId="" title="CIIU">
                </base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLRIngresoPromedioMensual" inputId="Ca111VLRIngresoPromedioMensual"
                    :errors="errorsFields" title="Ingreso promedio mensual" typeInput="text"
                    typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-list v-model="Ca111CodOrigenIngresos" idSelect="Ca111CodOrigenIngresos" :errors="errorsFields"
                    tipoLista="OGRING" listaPadre="" listaPadreId="" title="Origen ingresos">
                </base-select-list>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLROtrosIngresos" inputId="Ca111VLROtrosIngresos" :errors="errorsFields"
                    title="Otros ingresos" typeInput="text" typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-text-area v-model="Ca111DescripcionOtrosIngresos" textareaId="Ca111DescripcionOtrosIngresos"
                    title="Descripción otros ingreso"></base-text-area>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLRGastoSostenimiento" inputId="Ca111VLRGastoSostenimiento"
                    :errors="errorsFields" title="Gastos sostenimiento" typeInput="text" typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLRGastoArrendamiento" inputId="Ca111VLRGastoArrendamiento"
                    :errors="errorsFields" title="Gastos arrendamiento" typeInput="text" typeData="currence"></base-input-forms>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLRGastoFinanciero" inputId="Ca111VLRGastoFinanciero" :errors="errorsFields"
                    title="Gastos financieros" typeInput="text" typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLROtrosGastos" inputId="Ca111VLROtrosGastos" :errors="errorsFields"
                    title="Otros gastos" typeInput="text" typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-text-area v-model="Ca111DescripcionOtrosGastos" textareaId="Ca111DescripcionOtrosGastos"
                    title="Descripción otros gastos"></base-text-area>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-forms v-model="Ca111TenenciaVehiculo" title="Tiene vehículo" idSelect="Ca111TenenciaVehiculo"
                    :options="[{ nombre: 'SI', codigo: 'SI' }, { nombre: 'NO', codigo: 'NO' }]" optionName="nombre"
                    optionId="codigo" :errors="errorsFields"></base-select-forms>
            </div>
        </div>
        <template v-if="Ca111TenenciaVehiculo === 'SI'">
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="Ca111TipoVehiculo" idSelect="Ca111TipoVehiculo" :errors="errorsFields"
                        tipoLista="TPVEHI" listaPadre="" listaPadreId="" title="Tipo vehículo">
                    </base-select-list>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca111PlacaVehiculo" inputId="Ca111PlacaVehiculo" :errors="errorsFields"
                        title="Placa" typeInput="text" typeData="plate"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca111MarcaVehiculo" inputId="Ca111MarcaVehiculo" :errors="errorsFields"
                        title="Marca vehículo" typeInput="text" typeData="plate"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca111ClaseVehiculo" inputId="Ca111ClaseVehiculo" :errors="errorsFields"
                        title="Clase vehículo" typeInput="text"></base-input-forms>
                </div>

            </div>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca111Referencia1Vehiculo" inputId="Ca111Referencia1Vehiculo" :errors="errorsFields"
                        title="Referencia vehículo" typeInput="text"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="Ca111TipoServicioVehiculo" title="Tipo servicio" idSelect="Ca111TipoServicioVehiculo"
                        :options="[{ nombre: 'PARTICULAR', codigo: 'PAR' }, { nombre: 'PUBLICO', codigo: 'PUB' }]" optionName="nombre"
                        optionId="codigo" :errors="errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca111AnoModeloVehiculo" inputId="Ca111AnoModeloVehiculo" :errors="errorsFields"
                        title="Año modelo" typeInput="text" typeData="year"></base-input-forms>
                </div>
            </div>
        </template>

    </template>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoading,
            loadRequest,
            isLoadingRequest,
            requestParameters,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111CodOcupacion: computed({
                get: () => store.state['request']['request']['Ca111CodOcupacion'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodOcupacion', value: val })
            }),
            Ca111CIIU: computed({
                get: () => store.state['request']['request']['Ca111CIIU'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CIIU', value: val })
            }),
            Ca111VLRIngresoPromedioMensual: computed({
                get: () => store.state['request']['request']['Ca111VLRIngresoPromedioMensual'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRIngresoPromedioMensual', value: val })
            }),
            Ca111CodOrigenIngresos: computed({
                get: () => store.state['request']['request']['Ca111CodOrigenIngresos'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodOrigenIngresos', value: val })
            }),
            Ca111VLROtrosIngresos: computed({
                get: () => store.state['request']['request']['Ca111VLROtrosIngresos'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLROtrosIngresos', value: val })
            }),
            Ca111DescripcionOtrosIngresos: computed({
                get: () => store.state['request']['request']['Ca111DescripcionOtrosIngresos'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DescripcionOtrosIngresos', value: val })
            }),
            Ca111VLRGastoSostenimiento: computed({
                get: () => store.state['request']['request']['Ca111VLRGastoSostenimiento'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRGastoSostenimiento', value: val })
            }),
            Ca111VLRGastoArrendamiento: computed({
                get: () => store.state['request']['request']['Ca111VLRGastoArrendamiento'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRGastoArrendamiento', value: val })
            }),
            Ca111VLRGastoFinanciero: computed({
                get: () => store.state['request']['request']['Ca111VLRGastoFinanciero'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRGastoFinanciero', value: val })
            }),
            Ca111VLROtrosGastos: computed({
                get: () => store.state['request']['request']['Ca111VLROtrosGastos'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLROtrosGastos', value: val })
            }),
            Ca111DescripcionOtrosGastos: computed({
                get: () => store.state['request']['request']['Ca111DescripcionOtrosGastos'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DescripcionOtrosGastos', value: val })
            }),
            Ca111TenenciaVehiculo: computed({
                get: () => store.state['request']['request']['Ca111TenenciaVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TenenciaVehiculo', value: val })
            }),
            Ca111TipoVehiculo: computed({
                get: () => store.state['request']['request']['Ca111TipoVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoVehiculo', value: val })
            }),
            Ca111PlacaVehiculo: computed({
                get: () => store.state['request']['request']['Ca111PlacaVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111PlacaVehiculo', value: val })
            }),
            Ca111PlacaVehiculo: computed({
                get: () => store.state['request']['request']['Ca111PlacaVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111PlacaVehiculo', value: val })
            }),
            Ca111MarcaVehiculo: computed({
                get: () => store.state['request']['request']['Ca111MarcaVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111MarcaVehiculo', value: val })
            }),
            Ca111ClaseVehiculo: computed({
                get: () => store.state['request']['request']['Ca111ClaseVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111ClaseVehiculo', value: val })
            }),
            Ca111Referencia1Vehiculo: computed({
                get: () => store.state['request']['request']['Ca111Referencia1Vehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111Referencia1Vehiculo', value: val })
            }),
            Ca111TipoServicioVehiculo: computed({
                get: () => store.state['request']['request']['Ca111TipoServicioVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoServicioVehiculo', value: val })
            }),
            Ca111AnoModeloVehiculo: computed({
                get: () => store.state['request']['request']['Ca111AnoModeloVehiculo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111AnoModeloVehiculo', value: val })
            }),
        }

    },
}
</script>