<template>
    Mostrar
    <select v-model="inputModel" @change="onChange">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
    </select>
    por página
</template>

<script>
import { computed, ref } from 'vue'
export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            type: String
        },
        onChange:{
            type:Function
        }
    },
    setup(props, { emit }) {
        const inputModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', newValue)
            }
        })
        return {
            inputModel
        }

    }

}
</script>

<style></style>