export default () => ({
    requestGraphics: {},
    shivamAccountGraphics: {},
    salesGraphics: {},
    paymentsGraphics: {},
    creditGraphics: {},
    isLoading: {
        requestGraphicsExport: false,
        salesGraphicsExport: false,
        paymentsGraphicsExport: false,
        creditGraphicsExport: false,
        shivamGraphicsExport: false,
        requestGraphics: false,
        shivamGraphics: false,
        salesGraphics: false,
        paymentsGraphics: false,
        creditGraphics: false,
    },

    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: '',
    },
    graphicsParameters: {}
})