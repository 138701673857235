import inputMaskFormat from "@/helpers/inputMaskFormat";

export const setTasksList = (state, { data }) => {

    const estados = data.parametros.estados
    const prioridad = data.parametros.prioridad
    const frecuenciaEjecucion = data.parametros.frecuenciaEjecucion
    const newEstados = []
    const newPrioridad = []
    const newFrecuencia = []

    for (const key in estados) {
        let estado = {
            'nombre': estados[key],
            'codigo': key
        }
        newEstados.push(estado)
    }
    for (const key in prioridad) {
        let p = {
            'nombre': prioridad[key],
            'codigo': key
        }
        newPrioridad.push(p)
    }
    for (const key in frecuenciaEjecucion) {
        let frecuencia = {
            'nombre': frecuenciaEjecucion[key],
            'codigo': key
        }
        newFrecuencia.push(frecuencia)
    }
    state.tasksList = data.tareas
    state.taskParameters.estados = newEstados
    state.taskParameters.estadosBackend = estados
    state.taskParameters.prioridad = newPrioridad
    state.taskParameters.prioridadBackend = prioridad
    state.taskParameters.frecuenciaEjecucionBackend = frecuenciaEjecucion
    state.taskParameters.frecuenciaEjecucion = newFrecuencia

}
export const setTask = (state, { data }) => {
    const { inputDate } = inputMaskFormat();
    let task = data.tarea
    state.task.codTarea = task.CA126CODTAREA
    state.task.codCategoria = task.CA126CODCATEGORIA
    state.task.nomCategoria = task.categoria.CA129NOMBRE
    state.task.categoria = task.categoria
    state.task.nombre = task.CA126NOMBRE
    state.task.descripcion = task.CA126DESCRIPCION
    state.task.prioridad = task.CA126PRIORIDAD
    state.task.fechaInicio = inputDate(task.CA126FECHAINICIOESTIMADO, 3)
    state.task.fechaEjecucionEstimada = inputDate(task.CA126FECHAEJECUCIONESTIMADA, 3)
    state.task.frecuenciaEjecucion = task.CA126FRECUENCIAEJECUCION
    state.task.codUsuarioCreador = task.CA126CODUSUARIOCREADOR
    state.task.codUsuarioAsignado = task.CA126CODUSUARIOASIGNADO
    state.task.emailUsuarioAsignado = task.usuario_asignado.email
    state.task.usuarioAsignado = task.usuario_asignado
    state.task.usuarioCreador = task.usuario_creador
    state.task.seguimiento = task.CA126SEGUIMIENTO == 1 ? true : false
    state.task.repeticiones = task.repeticiones
    state.task.estado = task.CA126ESTADO
    state.task.observaciones = task.CA126OBSERVACIONES
}

export const setListConfig = (state, { data }) => {

    let contactosFrecuentes = []
    let contactos = data[0]

    for (const key in contactos) {
        let contacto = {
            'codigo': contactos[key]['CA130CODCONTACTOUSUARIO'],
            'nombre': contactos[key].usuario_contacto['name'],
            'email': contactos[key].usuario_contacto['email'],
        }
        contactosFrecuentes.push(contacto)
    }
    state.contactosFrecuentes = contactosFrecuentes
    state.categorias = data[1]
}
export const setSummaryTask = (state, { data }) => {
    state.summaryTask = data
}

export const addTask = (state, { data }) => {
    state.tasksList.unshift(data)
}


export const updateTasksList = (state, { taskId, data }) => {
    const idx = state.tasksList.map(t => t.CA126CODTAREA).indexOf(taskId)
    state.tasksList[idx] = data
    state.task.repeticiones = data.repeticiones
}

export const clearTask = (state) => {
    for (const property in state.task) {
        state.task[property] = ''
    }
}

export const updateField = (state, { field, value }) => {
    state.task[field] = value;
}

export const orderList = (state, { column, order = 'asc' }) => {
    state.tasksList = state.tasksList.sort((x, y) => {
        let comparison = 0;

        if (typeof x[column] === 'string' && typeof y[column] === 'string') {
            comparison = x[column].localeCompare(y[column]);
        } else if (typeof x[column] === 'number' && typeof y[column] === 'number') {
            comparison = x[column] - y[column];
        }

        return order === 'asc' ? comparison : -comparison;
    });
}


export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.messageServer = ''
    state.alert.showAlert = false
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false
    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.alert.showAlert = false
        }, 5000);
    }
}