<template>
    <div v-if="checkPermission(permissionId)">
        <label :for="inputId" class="subir" @click="onSelectFile">
            <i class="fas fa-cloud-upload-alt"></i> Subir archivo
        </label>
        <input :id="inputId" :name="inputId" type="file" @change="onChange(paramFunction, $event)" ref="imageSelector" style='display: none;' />
        <!-- <div id="info"></div>
    <input type="submit" value="Enviar"> -->
    </div>
</template>

<script>
import { ref } from 'vue'

import useAuth from '@/modules/auth/composables/useAuth';
export default {

    props: {
        onChange: {
            type: Function
        },
        paramFunction: '',
        permissionId: {
            type: Number
        },
        inputId:{
            type:String,
            default:''
        }
    },

    setup() {
        const imageSelector = ref(null)
        const { checkPermission } = useAuth()


        return {
            imageSelector,
            checkPermission,

            onSelectFile: () => {
                imageSelector.value.click()
            }

        }
    }


}
</script>

<style scoped>
.subir {
    padding: 5px 10px;
    background: #afe4e8;
    color: #4e4d4d;
    border: 0px solid #fff;
}

.subir:hover {
    color: #fff;
    background: #4858a2;
}
</style>