import { orderArray } from '@/helpers/utilities'

export const setUsers = (state, { data }) => {
    state.users = data
}
export const setUserEdit = (state, { data }) => {
    state.userEdit = data
}
export const setStatusUserList = (state, { userId }) => {
    const idx = state.users.data.map(u => u.idUsuario).indexOf(userId)
    state.users.data[idx].Estado = state.users.data[idx].Estado === 'A' ? 'B' : 'A'
}
export const addUserLists = (state, { data }) => {
    const newUsuario = {
        idUsuario: data.id,
        perfil: data.roles[0].name,
        nombreUsuario: data.name,
        email: data.email,
        Estado: data.Estado
    }
    state.users.data.unshift(newUsuario)
}
export const updateUserLists = (state, { data }) => {
    const idx = state.users.data.map(u => u.idUsuario).indexOf(data.id)
    state.users.data[idx].perfil = data.roles[0].name
    state.users.data[idx].nombreUsuario = data.name
    state.users.data[idx].email = data.email
    state.users.data[idx].Estado = data.Estado
}

export const updateField = (state, { field, value }) => {
    state.userEdit[field] = value;
}
export const setUserShops = (state, { data }) => {
    state.userShops = data
}
export const orderUserShops=(state,{column, order})=>{
    state.userShops=orderArray(state.userShops, column, order)
}

export const clearUser = (state) => {
    state.userEdit = {}
    for (const key in state.userEdit) {
        state.userEdit[key] = ''
    }
    state.userShops = {}

}
export const clearUserShopChat = (state) => {
    state.userShopChat = {}
}
export const setUserShopChat = (state, { data }) => {
    state.userShopChat = data
}


export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'list':
            state.isLoading = true
            break;
        case 'user':
            state.isLoadingUser = true
            break;
        case 'operation':
            state.isLoadingOperationUser = true
            break;
        case 'store':
            state.userShops = {}
            state.isLoadingUserStore = true
            break;
        case 'addStore':
            state.isLoadingAddUserStore = true
            break;
    }
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingUser = false
    state.isLoadingOperationUser = false
    state.isLoadingUserStore = false
    state.isLoadingAddUserStore = false
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'
    state.alert.messageServer = messageServer
}