export const setObjetiveSelect = (state, { data }) => {
    state.objetivesSelect = data
}
export const setObjetiveIndex = (state, { data }) => {
    for (const key in data.data) {
        data.data[key].CA008X100PESO = Number(data.data[key].CA008X100PESO) * 10
    }
    state.objetivesList = data
}

export const setObjetive = (state, { data }) => {
    data.CA008X100PESO = Number(data.CA008X100PESO) * 10
    state.objetive = data

}


export const addObjetiveList = (state, { data }) => {
    data.CA008X100PESO = Number(data.CA008X100PESO) * 10
    state.objetivesList.data.unshift(data)
}
export const updateObjetiveList = (state, { data }) => {
    const idx = state.objetivesList.data.map(u => u.CA008CODOBJETIVO).indexOf(data.CA008CODOBJETIVO)
    state.objetivesList.data[idx] = data
    state.objetivesList.data[idx].CA008X100PESO = Number(data.CA008X100PESO)*10

}
export const clearObjetive = (state) => {
    state.objetive = {
        CA008NOMBREOBJETIVO: '',
        CA008X100PESO: ''
    }
}


export const updateField = (state, { field, value }) => {
    state.objetive[field] = value;
}

export const showProcessHideAlert = (state, typeProcess) => {

    state.isLoading[typeProcess] = true
    state.alert.messageServer = ''
    state.alert.showAlert = false
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse, typeProcess }) => {

    state.isLoading[typeProcess] = false
    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.alert.showAlert = false
        }, 5000);
    }
}