<template>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <label :for="idSelectCountry" class="fw-bold">{{ titleCountry }}</label>
        <select v-model="selectModelCountry" :name="idSelectCountry" :id="idSelectCountry"
            class="form-control form-control-sm" :class="{ 'is-invalid': errors[idSelectCountry] }" :required="required"
            @blur="onBlur" :disabled="disabled">
            <option value="">Seleccione...</option>
            <option v-for="{ codLista, descripcionLista } in countries" :value="codLista" :key="codLista">{{
                descripcionLista }}</option>
        </select>
        <span v-if="errors[idSelectCountry]" class="invalid-feedback" role="alert">
            <small>{{ errors[idSelectCountry] ? errors[idSelectCountry][0] : '' }}</small>
        </span>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <label :for="idSelectState" class="fw-bold">{{ titleState }}</label>
        <select v-model="selectModelState" :name="idSelectState" :id="idSelectState" class="form-control form-control-sm"
            :class="{ 'is-invalid': errors[idSelectState] }" :required="required" @blur="onBlur" :disabled="disabled">
            <option value="">Seleccione...</option>
            <option v-for="{ codLista, descripcionLista } in state" :value="codLista" :key="codLista">{{ descripcionLista }}
            </option>
        </select>
        <span v-if="errors[idSelectState]" class="invalid-feedback" role="alert">
            <small>{{ errors[idSelectCountry] ? errors[idSelectState][0] : '' }}</small>
        </span>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <label :for="idSelectCity" class="fw-bold">{{ titleCity }}</label>
        <select v-model="selectModelCity" :name="idSelectCity" :id="idSelectCity" class="form-control form-control-sm"
            :class="{ 'is-invalid': errors[idSelectCity] }" :required="required" @blur="onBlur" :disabled="disabled">
            <option value="">Seleccione...</option>
            <option v-for="{ codLista, descripcionLista } in cities" :value="codLista" :key="codLista">{{ descripcionLista
            }}</option>
        </select>
        <span v-if="errors[idSelectCity]" class="invalid-feedback" role="alert">
            <small>{{ errors[idSelectCity] ? errors[idSelectCity][0] : '' }}</small>
        </span>
    </div>
</template>
<script>


import { computed, ref, watch } from "vue"

import requestApi from '@/api/requestApi'
import { useStore } from "vuex";

export default {
    props: {
        modelValueCountry: {
            required: false,
            default: '',
            // type: String|Number,
        },
        modelValueState: {
            required: false,
            default: '',
            // type: String|Number,
        },
        modelValueCity: {
            required: false,
            default: '',
            // type: String|Number,
        },
        idSelectCountry: {
            type: String,
            default: ''
        },
        titleCountry: {
            type: String,
            default: 'País'
        },
        idSelectState: {
            type: String,
            default: ''
        },
        titleState: {
            type: String,
            default: 'Departamento'
        },
        idSelectCity: {
            type: String,
            default: ''
        },
        titleCity: {
            type: String,
            default: 'Ciudad'
        },
        errors: Object,
        required: {
            type: Boolean,
            default: false
        },
        onBlur: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    setup(props, { emit }) {

        const store = useStore()

        const countries = ref(store.getters['parameterization/getListSelect']('PAIS', '', ''))
        const state = ref(store.getters['parameterization/getListSelect']('DEPTO', 'PAIS', props.modelValueCountry))
        const cities = ref(store.getters['parameterization/getListSelect']('CIUDAD', 'DEPTO', props.modelValueState))

        const selectModelCountry = computed({
            get() {
                return props.modelValueCountry
            },
            set(newValue) {
                emit('update:modelValueCountry', newValue)
            }
        })
        const selectModelState = computed({
            get() {
                return props.modelValueState
            },
            set(newValue) {
                emit('update:modelValueState', newValue)
            }
        })
        const selectModelCity = computed({
            get() {
                return props.modelValueCity
            },
            set(newValue) {
                emit('update:modelValueCity', newValue)
            }
        })
        watch(selectModelCountry, (newValue) => {

            state.value = store.getters['parameterization/getListSelect']('DEPTO', 'PAIS', newValue === '' ? ' ' : newValue)

        })
        watch(selectModelState, (newValue) => {

            cities.value = store.getters['parameterization/getListSelect']('CIUDAD', 'DEPTO', newValue === '' ? ' ' : newValue)
        })

        return {
            selectModelCountry,
            selectModelState,
            selectModelCity,
            countries,
            state,
            cities,

        }

    }
}


</script>
