
export const setFilesList = (state, { data }) => {
    state.filesList = data.archivos
    state.filesParameters = data.parametros
}

export const clearFilesList = (state) => {
    state.filesList = {
        tipoModelo: '',
        codModelo: '',
        tiposArchivos: []
    }
}
export const updateFilesList = (state, { data }) => {
    const { validacionArchivo } = data
    const idx = state.filesList.tiposArchivos.map(f => f.codArchivo).indexOf(validacionArchivo.CA125CODARCHIVO)
    state.filesList.tiposArchivos[idx].validaciones[0] = validacionArchivo
}

export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'filesList':
            state.isLoading = true
            break;
        case 'checking':
            state.isCheckingFile = true
            break;
        case 'operation':
            state.isLoadingOperationFile = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {
    state.isLoading = false
    state.isCheckingFile = false
    state.isLoadingOperationFile = false
    state.messageServer = messageServer
    // state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }
}
