/**
 * Administrar el almacenamiento, validacion y obtención del token en el localStorage
 * Por el momento no se esta utilizando ya que la aplicación se autentica por medio de cookies
 */
import { encryption, decryption } from '@/helpers/encryptionDecryption'

/**
 * Obtener token del localStorage
 * @returns {String}
 */
export const getToken = () => {
    const tokenEncrypted = localStorage.getItem('token')
    const token = tokenEncrypted ? decryption(tokenEncrypted).replace(/['"]+/g, '') : ''
    return token
}


/**
 * Almacenar token en localStorage
 * @param {String} token
 * @return {void}
 */
export const setToken = (token) => {
    const tokenEncrypted = encryption(token)
    localStorage.setItem('token', tokenEncrypted)
}

/**
 * Validar la vigencia del token
 * @param {String} token 
 * @returns {Boolean}
 */
export const isTokenExpired = (token) => {
    if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        //Tiempo se calcula sobre fecha unix, que representa como un número entero que cuenta los segundos transcurridos desde el 1 de enero de 1970 a las 00:00:00
        //Javascript con Date.now() trae la cantidad de milisegundos, es por eso que se divide en 1000 para que quede en segundos
        const { exp } = JSON.parse(jsonPayload);
        const currentSeconds = Date.now() / 1000
        const timeToexpire = (currentSeconds - exp)<300;
        return timeToexpire

    }
}