<template>
    <button v-if="permissionId===0?true:checkPermission(permissionId)" :type="type" class="btn" :class="classButton" :disabled="disabled" @click="onClick(paramFunction)">
        <i :class="label?'me-2 ':'' + icon"></i>
        <template v-if="isLoading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Procesando...
        </template>
        <template v-else>
            {{ label }}
        </template>


    </button>
</template>

<script>
import useAuth from '@/modules/auth/composables/useAuth';
export default {

    props: {

        type: {
            type: String,
            default: 'submit'
        },
        classButton: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Function,
            default: () => { }
        },
        paramFunction: {
            default: ''
        },
        permissionId: {
            type: Number
        },
        showLoading:{
            type:Boolean,
            default:true
        }
    },
    setup() {
        const { checkPermission } = useAuth()


        return {
            checkPermission
        }

    },
}
</script>
<style lang="scss">
@import "@/styles/styles.scss";

.btn-primary{
    background-color: $primary !important;
    border:1px solid $primary !important;
}




</style>