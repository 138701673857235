<template>
    <template v-if="Ca111EstadoCivil === '1' || Ca111EstadoCivil === '5'">
        <div class="badge bg-primary text-wrap mt-2" style="width: 10rem;">
            Información Cónyuge
        </div>
        <CreditRequestSpouse></CreditRequestSpouse>
    </template>
    <div class="badge bg-primary text-wrap mt-2" style="width: 10rem;">
        Referencias
    </div>
    <hr>
    <ReferenceList :tipoModelo="4" :idAliado="0" :idSolicitud="Ca111CodSolicitud" :tipoPersona="'2'"></ReferenceList>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import CreditRequestSpouse from './CreditRequestSpouse.vue';
import ReferenceList from '@/modules/references/views/ReferenceList.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'

export default {

    components: {
        CreditRequestSpouse,
        ReferenceList
    },
    setup() {
        const store = useStore()
        const {

            isLoadingRequest,

        } = useRequest()
        return {
            isLoadingRequest,
            Ca111EstadoCivil: computed(() => store.state['request']['request']['Ca111EstadoCivil']),
            Ca111CodSolicitud: computed(() => store.state['request']['request']['Ca111CodSolicitud']),

        }
    },

}
</script>

<style></style>