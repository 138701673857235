<template>
    <div v-show="hideAlert" :class="'alert alert-' + typeAlert + ' alert-dismissible fade show text-start'" role="alert">
        <ul v-if="typeof (messages) === 'object' || typeof (messages) === 'array'">
            <li v-for="(message, key, index) in messages" :key="index">{{ key + ': ' + message }}</li>
        </ul>
        <ul v-else>
            <li>{{ messages }}</li>
        </ul>
        <button type="button" class="btn-close" @click="hideAlert = false"></button>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router'

export default {

    props: {
        messages: {

        },
        typeAlert: {
            type: String,
            default: 'danger'
        },
        module: {
            type: String,
            default: ''
        }

    },

    setup(props) {

        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const hideAlert = ref(true)

 

        return {
            hideAlert
            // hideAlert: () => {
            //     store.state[props.module]['showAlert'] = false
            //     store.state[props.module]['alert']['showAlert'] = false
            // }
        }
    }
}
</script>