export default () => ({
    notificationList: [],
    notification: {

    },
    isLoading: {
        notification: false,
        list: false,
        operation: false
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: ''
    },
    notificationParameters: {}
})