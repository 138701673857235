<template>
    <widget-loading v-if="isLoadingRequest" />
    <EmployeeIncomeVue v-else-if="Ca111CodOcupacion==='2'"></EmployeeIncomeVue>
    <IndependentIncome v-else-if="Ca111CodOcupacion==='3'"></IndependentIncome>
    <StudentIncome v-else-if="Ca111CodOcupacion==='4'"></StudentIncome>
    <ArmedIncome v-else-if="Ca111CodOcupacion==='7'"></ArmedIncome>
    <p v-else>No aplica esta sección para la profesión seleccionada </p>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

import EmployeeIncomeVue from './IncomeComponents/EmployeeIncome.vue'
import IndependentIncome from './IncomeComponents/IndependentIncome.vue';
import ArmedIncome from './IncomeComponents/ArmedIncome.vue';
import StudentIncome from './IncomeComponents/StudentIncome.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'



export default {
    components: {
        EmployeeIncomeVue,
        IndependentIncome,
        ArmedIncome,
        StudentIncome,
        WidgetLoading
    },
    setup() {
        const store = useStore()
        const {

            isLoadingRequest,

        } = useRequest()
        return {
            isLoadingRequest,
            Ca111CodOcupacion: computed(() => store.state['request']['request']['Ca111CodOcupacion'])

        }
    },

}
</script>