/**
 * Autenticar y Conectar aplicación a servidor Websockets con Pusher
 */

import Echo from 'laravel-echo'
import requestApi from './requestApi';
window.Pusher = require('pusher-js')
window.Echo = new Echo({
    broadcaster: process.env.VUE_APP_BROADCASTER,
    key: process.env.VUE_APP_ECHO_KEY,
    cluster: process.env.VUE_APP_CLUSTER,
    forceTLS: process.env.VUE_APP_FORCE_TLS,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                requestApi.post('/auth/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then(response => {
                        callback(false, response.data);
                    })
                    .catch(error => {
                        callback(true, error);
                    });
            }
        };
    },
    // authEndpoint: process.env.VUE_APP_BASE_URL + '/auth/websockets',
    // auth: {
    //     headers: {
    //         'Authorization': `Bearer ${getToken()}`,
    //         'appKey': process.env.VUE_APP_ECHO_KEY,
    //         'app-version':1
    //     }
    // },
})

