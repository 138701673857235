//Acciones asincronas que pueden llamar una mutación. 
//Estas tienen impacto en la base de datos
//Una vez tengan respuesta de la base de datos se para a las mutations
// export const myAction=async ({commit})=>{

import requestApi from "@/api/requestApi"
import store from "@/store"
import { setErrorsFields } from '@/helpers/errorsFields'




export const loadRequest = async ({ commit }, { page, numPages }) => {
    try {
        commit('showProcessHideAlert', 'requestList')
        const { data } = await requestApi.get(`/estudioCredito/index`, {
            params: {
                page,
                numPages
            }
        })
        store.commit('request/setRequestParameters', { data: data.data })
        commit('setRequestList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }

}

export const getRequest = async ({ commit }, { requestId, numFase }) => {
    try {
        commit('showProcessHideAlert', 'request')
        const { data } = await requestApi.get(`/estudioCredito/fase${numFase}/${requestId}`)
        if (numFase === 1) {
            const { solicitud } = data.data
            commit('setRequest', { data: solicitud })
        }
        if (numFase === 2) {
            const { solicitud, referencias, decision } = data.data
            commit('setRequest', { data: solicitud })
            commit('setResultFase2', { decision })
            commit('setReferences', { referencias })
        }
        if (numFase === 3) {
            const { solicitud, validacionCentrales, referencias } = data.data
            commit('setRequest', { data: solicitud })
            commit('setCentralInformationValidation', { data: validacionCentrales })
            commit('setReferences', { referencias })

        }
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const returnRequest = async ({ commit }, requestId) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/estudioCredito/devolverSolicitud/${requestId}`)
        commit('hideProcessShowAlert', { messageServer: 'Solicitud devuelta', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}

export const storeDecisionFase1 = async ({ commit }, { requestId, formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/estudioCredito/decisionFase1/${requestId}`, formData)
        commit('hideProcessShowAlert', { messageServer: 'Información actualizada', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const getQuestions = async ({ commit }, { requestId, referenceId }) => {
    try {
        commit('showProcessHideAlert', 'question')
        const { data } = await requestApi.post(`/estudioCredito/preguntasReferencia?codSolicitud=${requestId}&codReferencia=${referenceId}`)
        commit('setQuestions', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const createAnswer = async ({ commit }, { requestId, referenceId, formData }) => {
    try {
        commit('showProcessHideAlert', 'operationQuestion')
        formData.codSolicitud = requestId
        formData.codReferencia = referenceId
        const { data } = await requestApi.post(`/estudioCredito/registrarRespuestasReferencia`, formData)
        commit('updateAnswer', { formData, referenceId })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const startQualification = async ({ commit }, { requestId, referenceId }) => {
    try {
        commit('showProcessHideAlert', 'qualification')
        const { data } = await requestApi.post(`/estudioCredito/iniciarProcesoCalificacionReferencia`, {
            'codSolicitud': requestId, 'codReferencia': referenceId

        })
        commit('setObjetiveResult', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}

export const qualify = async ({ commit }, { requestId, objetiveResultId, referenceId, formData }) => {
    try {
        commit('showProcessHideAlert', 'qualification')
        formData.codSolicitud = requestId
        formData.codResultadoObjetivo = objetiveResultId
        const { data } = await requestApi.post(`/estudioCredito/calificarReferencia`, formData)
        commit('updateResultFase2', { data: data.data, referenceId })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const storeDecisionFase2 = async ({ commit }, { requestId, formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/estudioCredito/decisionFase2/${requestId}`, formData)
        commit('hideProcessShowAlert', { messageServer: 'Información actualizada', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const storeDecisionFase3 = async ({ commit }, { requestId, formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.put(`/estudioCredito/decisionFase3/${requestId}`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}
export const createQuota = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/estudioCredito/crearCupo`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'studyRequest', error: error })

    }
}

