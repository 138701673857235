<template>
    <TheContentHeader :titlePage="titlePage" />
    <the-content-body>
        <div class="d-flex justify-content-center">
            <widget-card :title="titlePage" classHeader="bg-primary">
                <template v-slot:header>
                    <div class="d-flex justify-content-between">
                        <div class="text-white">{{ titlePage }}  (Debe ser un número de 5 dígitos)</div>
                    </div>
                </template>
                <template v-slot:body>
                    <widget-alerts v-if="messagePassword" :messages="messagePassword"
                        typeAlert="danger"></widget-alerts>
                    <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                        :typeAlert="alert.typeAlert"></widget-alerts>
                    <form @submit.prevent="onChangePassword">
                        <base-input-forms v-model="formData.old_password" title="Ingrese contraseña anterior"
                            typeInput="password" inputId="old_password" :errors="alert.errorsFields"></base-input-forms>
                        <base-input-forms v-model="formData.new_password" title="Ingrese nueva contraseña"
                            typeInput="password" inputId="new_password" :errors="alert.errorsFields"></base-input-forms>
                        <base-input-forms v-model="formData.new_password_confirmation" title="Confirme nueva contraseña"
                            typeInput="password" inputId="new_password_confirmation"
                            :errors="alert.errorsFields"></base-input-forms>
                        <div class="d-flex justify-content-center mt-3">
                            <base-button-forms classButton="btn-primary" label="Cambiar contraseña" :permissionId="0"
                                :isLoading="isLoadingOperationUser"
                                :disabled="isLoadingOperationUser"></base-button-forms>
                        </div>
                    </form>
                </template>
            </widget-card>
        </div>
    </the-content-body>
</template>
<script>
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue';
import Swal from 'sweetalert2';
import useAuth from '../../auth/composables/useAuth';
import useUsers from '@/modules/users/composables/useUsers';

import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue'

export default {
    components: {
        WidgetCard,
        BaseInputForms,
        BaseButtonForms,
        WidgetAlerts,
        TheContentBody,
        TheContentHeader

    },
    setup(props) {
        const {
            userDataLoggedIn,
        } = useAuth()
        const {
            changePassword,
            isLoadingOperationUser,
            alert,
        } = useUsers()
        const formData = ref({
            old_password: '',
            new_password: '',
            new_password_confirmation: '',
        })
        const router = useRouter()

        return {
            formData,
            isLoadingOperationUser,
            alert,
            messagePassword: computed(() => {
                if (userDataLoggedIn.value.changed_password === null) return 'Como este es su primer login, debe cambiar la contraseña'
                else return false
            }),

            titlePage: computed(() => 'Cambiar contraseña'),

            onChangePassword: async () => {
                const { response, data } = await changePassword(formData.value)
                if (response) {
                    router.push({ name: 'start' });

                    // setTimeout(() => {
                    //     location.reload()

                    // }, 3);
                }
            }
        }
    }
}
</script>

<style scoped>
.card {
    width: 450px;
}
</style>