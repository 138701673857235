export default () => ({
    filesList:{
        tipoModelo:'',
        codModelo:'',
        tiposArchivos:[]
    },
    isCheckingFile:false,
    isLoading: false,
    isLoadingOperationFile: false,
    messageServer: null,
    errorsFields: {},
    showAlert: false,
    typeAlert: '',
    filesParameters:{}
})
