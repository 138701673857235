import { isAuthenticatedGuard, isAuthorized,hasActiveShopAllied } from '@/guard/auth-guard'
export default {
    path: '/ticket',
    name: 'ticket',
    component: () =>
        import( /* webpackChunkName: "TicketLayout'" */ '@/modules/casher/layout/TicketLayout'),
    children: [{
        path: '/ticket/compra/:codCompra/0',
        beforeEnter: [isAuthenticatedGuard, isAuthorized,hasActiveShopAllied],
        name: 'ticket-sale',
        meta: { permission: 46 },
        component: () =>
            import( /* webpackChunkName: "casher-sale'" */ '@/modules/casher/views/TicketSale'),
        props: (router) => {
            return {
                codCompra: router.params.codCompra,
            }
        }
    },
    {
        path: '/ticket/pago/:codPago/:codCliente',
        beforeEnter: [isAuthenticatedGuard, isAuthorized,hasActiveShopAllied],
        name: 'ticket-payment',
        meta: { permission: 46 },
        component: () =>
            import( /* webpackChunkName: "casher-payment'" */ '@/modules/casher/views/TicketPayment'),
        props: (router) => {
            return {
                codPago: router.params.codPago,
                codCliente: router.params.codCliente,
            }
        }
    },
    ]
}