import { computed, ref, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2';

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';



import useAllied from '@/modules/allied/composables/useAllied'


export default {

    props: {
        typeOperation: {
            default: ''
        }

    },

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseTextArea,
        BaseButtonForms,
        BaseInputCheckRadio,
        WidgetAlerts,
        ReferenceList: defineAsyncComponent(() => import(/*webpackChunkName:"ReferenceList"*/'@/modules/references/views/ReferenceList.vue')),
        FilesList: defineAsyncComponent(() => import(/*webpackChunkName:"FileList"*/'@/modules/files/views/FilesList.vue')),
        AlliedStudyStepper: defineAsyncComponent(() => import(/*webpackChunkName:"AlliedStudyStepper"*/'@/modules/allied/components/AlliedStudyStepper.vue')),


    },
    emits: ['on:createdAllied', 'on:closeModal','on:LoadListAllied'],
    setup(props, { emit }) {
        const store = useStore()
        const tap=ref(1)
        const checkCopyAddres = ref(false)



        const {
            allied,
            alliedParameters,
            createAllied,
            updateAllied,
            alert,
            isLoading,
            isLoadingAllied,
            isLoadingOperationAllied,
        } = useAllied()

        const isAlliedEditing=computed(()=>{
            return props.typeOperation != 'newAllied';
        })

        return {
            isLoadingAllied,
            allied,
            tap,
            checkCopyAddres,
            isLoadingOperationAllied,
            alert,
            isAlliedEditing,

            onSubmit: async (e) => {
                const formData = new FormData(e.target)
                if (!isAlliedEditing.value) {
                    const { response } = await createAllied(formData)
                    if (response) {
                        emit('on:createdAllied', allied.value.ca100CodAliado)
                    }
                } else {
                    const { response } = await updateAllied(allied.value.ca100CodAliado, formData)

                }

            },
            onToStudy: async () => {
                const formData = new FormData(document.getElementById('formAllied'))
                formData.append('enviarEstudio', 'SI')
                const { response } = await updateAllied(allied.value.ca100CodAliado, formData)
                if (response) {
                    emit('on:closeModal')
                }

            },




            copyAddress: () => {
                if (checkCopyAddres.value) {
                    store.commit('allied/updateField', { field: 'ca100CodigoPais2', value: store.state['allied']['allied']['ca100CodigoPais1'] })
                    store.commit('allied/updateField', { field: 'ca100CodigoDpto2', value: store.state['allied']['allied']['ca100CodigoDpto1'] })
                    store.commit('allied/updateField', { field: 'ca100CodigoCiudad2', value: store.state['allied']['allied']['ca100CodigoCiudad1'] })
                    store.commit('allied/updateField', { field: 'ca100Direccion2', value: store.state['allied']['allied']['ca100Direccion1'], })
                    store.commit('allied/updateField', { field: 'ca100CorreoElectronico2', value: store.state['allied']['allied']['ca100CorreoElectronico1'] })
                    store.commit('allied/updateField', { field: 'ca100TelefonoCelular2', value: store.state['allied']['allied']['ca100TelefonoCelular1'] })
                    store.commit('allied/updateField', { field: 'ca100TelefonoFijo2', value: store.state['allied']['allied']['ca100TelefonoFijo1'] })
                } else {
                    store.commit('allied/updateField', { field: 'ca100CodigoPais2', value: '' })
                    store.commit('allied/updateField', { field: 'ca100CodigoDpto2', value: '' })
                    store.commit('allied/updateField', { field: 'ca100CodigoCiudad2', value: '' })
                    store.commit('allied/updateField', { field: 'ca100Direccion2', value: '' })
                    store.commit('allied/updateField', { field: 'ca100CorreoElectronico2', value: '' })
                    store.commit('allied/updateField', { field: 'ca100TelefonoCelular2', value: '' })
                    store.commit('allied/updateField', { field: 'ca100TelefonoFijo2', value: '' })
                }
            },

            ca100TipoPersona: computed({
                get: () => store.state['allied']['allied']['ca100TipoPersona'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TipoPersona', value: val })
            }),
            ca100TipoIdentidad: computed({
                get: () => store.state['allied']['allied']['ca100TipoIdentidad'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TipoIdentidad', value: val })
            }),
            ca100NumeroIdentidad: computed({
                get: () => store.state['allied']['allied']['ca100NumeroIdentidad'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100NumeroIdentidad', value: val })
            }),
            Ca100DigitoVerificacion: computed({
                get: () => store.state['allied']['allied']['Ca100DigitoVerificacion'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100DigitoVerificacion', value: val })
            }),
            ca100PrimerNombre: computed({
                get: () => store.state['allied']['allied']['ca100PrimerNombre'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100PrimerNombre', value: val })
            }),
            ca100SegundoNombre: computed({
                get: () => store.state['allied']['allied']['ca100SegundoNombre'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100SegundoNombre', value: val })
            }),
            ca100PrimerApellido: computed({
                get: () => store.state['allied']['allied']['ca100PrimerApellido'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100PrimerApellido', value: val })
            }),
            ca100SegundoApellido: computed({
                get: () => store.state['allied']['allied']['ca100SegundoApellido'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100SegundoApellido', value: val })
            }),
            ca100FechaNacimiento: computed({
                get: () => store.state['allied']['allied']['ca100FechaNacimiento'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100FechaNacimiento', value: val })
            }),
            ca100FechaExpedicion: computed({
                get: () => store.state['allied']['allied']['ca100FechaExpedicion'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100FechaExpedicion', value: val })
            }),
            ca100CiudadExpedicion: computed({
                get: () => store.state['allied']['allied']['ca100CiudadExpedicion'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CiudadExpedicion', value: val })
            }),
            ca100TipoGenero: computed({
                get: () => store.state['allied']['allied']['ca100TipoGenero'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TipoGenero', value: val })
            }),
            ca100TipoEstadoCivil: computed({
                get: () => store.state['allied']['allied']['ca100TipoEstadoCivil'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TipoEstadoCivil', value: val })
            }),
            ca100NombreComercial: computed({
                get: () => store.state['allied']['allied']['ca100NombreComercial'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100NombreComercial', value: val })
            }),
            ca100RazonSocial: computed({
                get: () => store.state['allied']['allied']['ca100RazonSocial'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100RazonSocial', value: val })
            }),
            ca100TipoSociedad: computed({
                get: () => store.state['allied']['allied']['ca100TipoSociedad'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TipoSociedad', value: val })
            }),
            ca100TipoConstitucion: computed({
                get: () => store.state['allied']['allied']['ca100TipoConstitucion'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TipoConstitucion', value: val })
            }),
            ca100NumeroConstitucion: computed({
                get: () => store.state['allied']['allied']['ca100NumeroConstitucion'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100NumeroConstitucion', value: val })
            }),
            ca100FechaConstitucion: computed({
                get: () => store.state['allied']['allied']['ca100FechaConstitucion'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100FechaConstitucion', value: val })
            }),
            ca100NumeroNotaria: computed({
                get: () => store.state['allied']['allied']['ca100NumeroNotaria'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100NumeroNotaria', value: val })
            }),
            ca100CiudadNotaria: computed({
                get: () => store.state['allied']['allied']['ca100CiudadNotaria'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CiudadNotaria', value: val })
            }),
            CA100SectorEconomico: computed({
                get: () => store.state['allied']['allied']['CA100SectorEconomico'],
                set: (val) => store.commit('allied/updateField', { field: 'CA100SectorEconomico', value: val })
            }),
            CA100ActividadComercial: computed({
                get: () => store.state['allied']['allied']['CA100ActividadComercial'],
                set: (val) => store.commit('allied/updateField', { field: 'CA100ActividadComercial', value: val })
            }),
            ca100ResponsableRenta: computed({
                get: () => store.state['allied']['allied']['ca100ResponsableRenta'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100ResponsableRenta', value: val })
            }),
            ca100ResponsableIVA: computed({
                get: () => store.state['allied']['allied']['ca100ResponsableIVA'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100ResponsableIVA', value: val })
            }),
            ca100RetenedorIVA: computed({
                get: () => store.state['allied']['allied']['ca100RetenedorIVA'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100RetenedorIVA', value: val })
            }),
            ca100AutoRetenedorIva: computed({
                get: () => store.state['allied']['allied']['ca100AutoRetenedorIva'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100AutoRetenedorIva', value: val })
            }),
            ca100CodigoPais1: computed({
                get: () => store.state['allied']['allied']['ca100CodigoPais1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CodigoPais1', value: val })
            }),
            ca100CodigoDpto1: computed({
                get: () => store.state['allied']['allied']['ca100CodigoDpto1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CodigoDpto1', value: val })
            }),
            ca100CodigoCiudad1: computed({
                get: () => store.state['allied']['allied']['ca100CodigoCiudad1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CodigoCiudad1', value: val })
            }),
            ca100Direccion1: computed({
                get: () => store.state['allied']['allied']['ca100Direccion1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100Direccion1', value: val })
            }),
            ca100CorreoElectronico1: computed({
                get: () => store.state['allied']['allied']['ca100CorreoElectronico1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CorreoElectronico1', value: val })
            }),
            ca100DireccionEnvioCorrespondencia: computed({
                get: () => store.state['allied']['allied']['ca100DireccionEnvioCorrespondencia'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100DireccionEnvioCorrespondencia', value: val })
            }),
            ca100CodigoPais2: computed({
                get: () => store.state['allied']['allied']['ca100CodigoPais2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CodigoPais2', value: val })
            }),
            ca100CodigoDpto2: computed({
                get: () => store.state['allied']['allied']['ca100CodigoDpto2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CodigoDpto2', value: val })
            }),
            ca100CodigoCiudad2: computed({
                get: () => store.state['allied']['allied']['ca100CodigoCiudad2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CodigoCiudad2', value: val })
            }),
            ca100Direccion2: computed({
                get: () => store.state['allied']['allied']['ca100Direccion2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100Direccion2', value: val })
            }),
            ca100TelefonoCelular1: computed({
                get: () => store.state['allied']['allied']['ca100TelefonoCelular1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TelefonoCelular1', value: val })
            }),
            ca100TelefonoCelular2: computed({
                get: () => store.state['allied']['allied']['ca100TelefonoCelular2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TelefonoCelular2', value: val })
            }),
            ca100TelefonoFijo1: computed({
                get: () => store.state['allied']['allied']['ca100TelefonoFijo1'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TelefonoFijo1', value: val })
            }),
            ca100TelefonoFijo2: computed({
                get: () => store.state['allied']['allied']['ca100TelefonoFijo2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100TelefonoFijo2', value: val })
            }),
            ca100CorreoElectronico2: computed({
                get: () => store.state['allied']['allied']['ca100CorreoElectronico2'],
                set: (val) => store.commit('allied/updateField', { field: 'ca100CorreoElectronico2', value: val })
            }),
            Ca100ExperienciaActividadComercial: computed({
                get: () => store.state['allied']['allied']['Ca100ExperienciaActividadComercial'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100ExperienciaActividadComercial', value: val })
            }),
            Ca100CantEmpleados: computed({
                get: () => store.state['allied']['allied']['Ca100CantEmpleados'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100CantEmpleados', value: val })
            }),
            Ca100ComentariosEjecutivoComercial: computed({
                get: () => store.state['allied']['allied']['Ca100ComentariosEjecutivoComercial'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100ComentariosEjecutivoComercial', value: val })
            }),
            Ca100DescValidacionArchivos: computed({
                get: () => store.state['allied']['allied']['Ca100DescValidacionArchivos'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100DescValidacionArchivos', value: val })
            }),
            Ca100DescDecisionEstudio: computed({
                get: () => store.state['allied']['allied']['Ca100DescDecisionEstudio'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100DescDecisionEstudio', value: val })
            }),
            Ca100Estado: computed({
                get: () => store.state['allied']['allied']['Ca100Estado'],
                set: (val) => store.commit('allied/updateField', { field: 'Ca100Estado', value: val })
            }),
        }

    },
}