<template>
  <TheContentHeader titlePage="Parametrizacion de Preguntas" />
  <the-content-body>
    <widget-card title="Preguntas" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Preguntas</div>
          <base-button-fab @on:click="openModal('newQuestion')" :permissionId="21"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
          :typeAlert="alert.typeAlert"></widget-alerts>
        <base-input-group :icon="'fa-solid fa-magnifying-glass'" v-model="formData.search"
          :onBlur="callLoadQuestionIndex"></base-input-group>
        <widget-loading v-if="isLoading.list" />
        <!-- Tabla -->
        <template v-else-if="questionsList.data.length > 0">
          <widget-select-paginator v-model="formData.numPages" :onChange="callLoadQuestionIndex"></widget-select-paginator>
          <base-table-forms :data="questionsList" :dataFunction="callLoadQuestionIndex"
            :headers="headers"  :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
            <tr v-for="question in questionsList.data" :key="question.CA003CODPREGUNTA">
              <td class="text-center">{{ question.CA003CODPREGUNTA }}</td>
              <td class="text-center">{{ question.CA003PREGUNTA }}</td>
              <td class="text-center">{{ typeQuestion[question.CA003TIPOPREGUNTA][0] }}</td>
              <td class="text-center">{{ question.CA003CAMPORELACIONADO }}</td>
              <td class="text-center">
                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
                  { label: 'Editar', action: () => { openModal(question.CA003CODPREGUNTA.toString()) }, permissionId: 23, visible: true },
                ]"></widget-dropdown>
              </td>
            </tr>
          </base-table-forms>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <!-- Modal -->
  <widget-modal-boostrap modalId="modalQuestion" :modalTitle="modalTitle"
    modalClassSize="modal-lg modal-dialog-scrollable" :showFooter="false">
    <template v-slot:modalBody>
      <widget-loading v-if="isLoading.question" />
      <template v-else>
        <form @submit.prevent="onSubmit">
          <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
            :typeAlert="alert.typeAlert"></widget-alerts>
          <div class="form-group row">
            <!-- Nombre Pregunta -->
            <div class="col-12">
              <base-text-area v-model="CA003PREGUNTA" textareaId="CA003PREGUNTA" title="Pregunta"
                :errors="alert.errorsFields"></base-text-area>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-forms v-model="CA003TIPOPREGUNTA" idSelect="CA003TIPOPREGUNTA" title="Tipo Pregunta"
                :options="typeQuestion" optionId="0" optionName="0" :withKey="true"
                :errors="alert.errorsFields"></base-select-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="CA003TIPOPREGUNTA > 3">
              <base-input-forms v-model="CA003NUMOPCIONES" inputId="CA003NUMOPCIONES" title="Número de opciones"
                :errors="alert.errorsFields"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-forms v-model="CA003CAMPORELACIONADO" idSelect="CA003CAMPORELACIONADO"
                title="Campo Relacionado" :options="requestFields" optionId="alias" optionName="alias" :withKey="true"
                :errors="alert.errorsFields"></base-select-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoading.operation"
                :disabled="isLoading.operation" label="Guardar" :permissionId="23"></base-button-forms>
            </div>
          </div>
        </form>
        <!-- Configuración preguntas -->
        <template v-if="isEditing">
          <!-- Segmentos -->
          <form @submit.prevent="onSubmitQuestionSettings(0, $event)">
            <div class="form-group">
              <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Segmentos
              </div>
              <template v-for="segmento in listaSegmentos" :key="segmento.consecutivo">
                <base-input-check-radio v-model="segmentos" typeInput="checkbox" :title="segmento.descripcionLista"
                  :inputId="'segmentos[]'" :inputValue="segmento.codLista" :errors="alert.errorsFields" />
              </template>
              <base-button-forms classButton="btn-primary btn-sm mt-3" :isLoading="isLoading.segmento"
                :disabled="isLoading.segmento" label="Guardar" :permissionId="23"></base-button-forms>
            </div>
          </form>
          <!-- Tipos referencias -->
          <form @submit.prevent="onSubmitQuestionSettings(1, $event)">
            <div class="form-group">
              <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Tipos Referencias
              </div>
              <template v-for="tipoReferencia in listaTipoReferencias" :key="tipoReferencia.consecutivo">
                <base-input-check-radio v-model="tiposReferencias" typeInput="checkbox"
                  :title="tipoReferencia.descripcionLista" :inputId="'tiposReferencias[]'"
                  :inputValue="tipoReferencia.codLista" :errors="alert.errorsFields" />
              </template>
              <base-button-forms classButton="btn-primary btn-sm mt-3" :isLoading="isLoading.referencia"
                :disabled="isLoading.referencia" label="Guardar" :permissionId="23"></base-button-forms>
            </div>
          </form>
          <!-- Objetivos -->
          <form @submit.prevent="onSubmitQuestionSettings(2, $event)">
            <div class="form-group">
              <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Objetivos
              </div>
              <template v-for="objetivo in objetivesSelect" :key="objetivo.CA008CODOBJETIVO">
                <base-input-check-radio v-model="objetivos" typeInput="checkbox" :title="objetivo.CA008NOMBREOBJETIVO"
                  :inputId="'objetivos[]'" :inputValue="objetivo.CA008CODOBJETIVO" :errors="alert.errorsFields" />
              </template>
              <base-button-forms classButton="btn-primary btn-sm mt-3" :isLoading="isLoading.objetivo"
                :disabled="isLoading.objetivo" label="Guardar" :permissionId="23"></base-button-forms>
            </div>
          </form>
          <!-- Opciones de respuesta -->
          <template v-if="CA003TIPOPREGUNTA > 3">
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
              Opciones de respuesta
            </div>
            <base-table-forms :headers="['Opción', 'Valor', 'Acciones']" :whitPagination="false">
              <tr v-for="opcion in opcionesRespuesta" :key="opcion.CA004IDLISTARESPUESTA">
                <td class="text-center">
                  <base-input-forms v-model="opcion.CA004LISTARESPUESTA" :errors="alert.errorsFields"></base-input-forms>
                </td>
                <td class="text-center">
                  <base-input-forms v-model="opcion.CA004VALOR" :errors="alert.errorsFields">
                  </base-input-forms>
                </td>
                <td class="text-center">
                  <base-button-forms icon="fa-solid fa-trash" classButton="btn-danger btn-sm" :permissionId="23"
                    :onClick="eliminarFila"></base-button-forms>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-end">
                  <base-button-forms classButton="btn-primary btn-sm" :isLoading="isLoading.answerOptions"
                    :disabled="isLoading.answerOptions" label="Guardar" :permissionId="23"
                    :onClick="onSubmitAnswerOptions"></base-button-forms>
                </td>
                <td class="text-center">
                  <base-button-forms icon="fa-solid fa-plus" classButton="btn-primary btn-sm rounded-circle"
                    :permissionId="23" :onClick="agregarOpcionRespuesta"></base-button-forms>
                </td>
              </tr>
            </base-table-forms>
          </template>
        </template>
      </template>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { Modal } from 'bootstrap';
import { useStore } from 'vuex'
import { Toast } from "@/helpers/toastSwalert"

import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';


import useQuestion from '@/modules/parameterization/questions/composables/useQuestion'
import useLists from '@/modules/parameterization/lists/composables/useLists'
import useObjetive from '@/modules/parameterization/objetives/composables/useObjetive'


export default {
  components: {
    BaseButtonFab,
    BaseInputForms,
    BaseTableForms,
    BaseButtonForms,
    BaseInputGroup,
    BaseSelectForms,
    BaseTextArea,
    BaseInputCheckRadio,
    TheContentBody,
    TheContentHeader,
    WidgetAlerts,
    WidgetCard,
    WidgetDropdown,
    WidgetLoading,
    WidgetModalBoostrap,
    WidgetSelectPaginator,


  },

  setup() {
    const questionId = ref('newQuestion')
    const modals = ref({ modalQuestion: null })
    const formData = ref({
      search: '',
      numPages: '5',
      page: 1,
      column: 'CA003CODPREGUNTA',
      order: 'asc'

    })

    const headers = ref([
      { encabezado: 'ID', columna: 'CA003CODPREGUNTA' },
      { encabezado: 'Pregunta', columna: 'CA003PREGUNTA' },
      { encabezado: 'Tipo pregunta', columna: 'CA003TIPOPREGUNTA' },
      { encabezado: 'Campo relacionado', columna: 'CA003CAMPORELACIONADO' },
      { encabezado: 'Acciones', columna: null },

    ])
    const typeQuestion = ref({
      1: ['Campo de Texto'],
      2: ['Campo Numérico'],
      3: ['Campo de fecha'],
      4: ['Lista desplegable'],
      5: ['Selección multiple, única respuesta'],
      6: ['Selección multiple, varias respuestas']
    })
    const store = useStore()
    const { getListSelect } = useLists()
    const { loadObjetiveListSelect, objetivesSelect } = useObjetive()
    const listaSegmentos = getListSelect('OCP')
    const listaTipoReferencias = getListSelect('TPREF')
    const opcionesRespuesta = ref([])

    const cargarOpcionesRespuestas = () => {
      for (const key in question.value.opcionesRespuesta) {
        opcionesRespuesta.value.push({
          CA004LISTARESPUESTA: question.value.opcionesRespuesta[key].CA004LISTARESPUESTA,
          CA004VALOR: question.value.opcionesRespuesta[key].CA004VALOR
        })
      }
    }


    const {
      isLoading,
      alert,
      loadQuestionIndex,
      questionsList,
      createQuestion,
      getQuestion,
      updateQuestion,
      question,
      requestFields,
      questionSettings,
      answerOptions
    } = useQuestion()

    const isEditing = computed(() => questionId.value != 'newQuestion')
    onMounted(() => {
      modals.modalQuestion = new Modal('#modalQuestion', {})
    })
    const callLoadQuestionIndex = (page, column) => {
      formData.value.page = page
      if (column) {
        formData.value.column = column
        formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
      }
      loadQuestionIndex(formData.value)
    }

    callLoadQuestionIndex()
    loadObjetiveListSelect()

    const openModal = async (id) => {
      store.commit('questions/clearQuestion')
      opcionesRespuesta.value = []
      questionId.value = id
      modals.modalQuestion.show()
      if (id != 'newQuestion') {
        await getQuestion(id)
        cargarOpcionesRespuestas()
      }
    }
    const closeModal = () => {
      modals.modalQuestion.hide()
    }


    return {
      questionId,
      question,
      questionsList,
      createQuestion,
      getQuestion,
      updateQuestion,
      formData,
      headers,
      alert,
      isLoading,
      callLoadQuestionIndex,
      typeQuestion,
      requestFields,
      listaSegmentos,
      listaTipoReferencias,
      objetivesSelect,
      isEditing,

      modalTitle: computed(() => {
        return questionId.value === 'newQuestion' ? 'Crear Pregunta' : 'Actualizar Pregunta ' + question.value.CA003PREGUNTA
      }),

      openModal,
      closeModal,

      CA003PREGUNTA: computed({
        get: () => store.state['questions']['question']['CA003PREGUNTA'],
        set: (val) => store.commit('questions/updateField', { field: 'CA003PREGUNTA', value: val })
      }),
      CA003TIPOPREGUNTA: computed({
        get: () => store.state['questions']['question']['CA003TIPOPREGUNTA'],
        set: (val) => store.commit('questions/updateField', { field: 'CA003TIPOPREGUNTA', value: val })
      }),
      CA003NUMOPCIONES: computed({
        get: () => store.state['questions']['question']['CA003NUMOPCIONES'],
        set: (val) => store.commit('questions/updateField', { field: 'CA003NUMOPCIONES', value: val })
      }),
      CA003CAMPORELACIONADO: computed({
        get: () => store.state['questions']['question']['CA003CAMPORELACIONADO'],
        set: (val) => store.commit('questions/updateField', { field: 'CA003CAMPORELACIONADO', value: val })
      }),
      segmentos: computed({
        get: () => store.state['questions']['question']['segmentos'],
        set: (val) => store.commit('questions/updateField', { field: 'segmentos', value: val })
      }),
      tiposReferencias: computed({
        get: () => store.state['questions']['question']['tiposReferencias'],
        set: (val) => store.commit('questions/updateField', { field: 'tiposReferencias', value: val })
      }),
      objetivos: computed({
        get: () => store.state['questions']['question']['objetivos'],
        set: (val) => store.commit('questions/updateField', { field: 'objetivos', value: val })
      }),
      opcionesRespuesta,


      agregarOpcionRespuesta: () => {
        opcionesRespuesta.value.push({
          CA004LISTARESPUESTA: 'SI',
          CA004VALOR: 'SI'
        })
      },
      eliminarFila: (index) => {
        opcionesRespuesta.value.splice(index, 1)
      },

      onSubmit: async (e) => {
        const formData = new FormData(e.target)
        if (questionId.value === 'newQuestion') {
          const { response } = await createQuestion(formData)
          if (response) {
            openModal(questionsList.value.data[0].CA003CODPREGUNTA)
          }
        } else {
          const { response } = await updateQuestion(questionId.value, formData)
        }
      },

      onSubmitQuestionSettings: async (numSetting, e) => {
        const formData = new FormData(e.target)
        const { response } = await questionSettings(questionId.value, formData, numSetting)
      },
      onSubmitAnswerOptions: async () => {
        const { response } = await answerOptions(questionId.value, { opcionesRespuesta: opcionesRespuesta.value })
      }



    }
  }

}
</script>

<style></style>