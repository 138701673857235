<template>
    <TheContentHeader titlePage="Solicitud de Crédito" />
    <the-content-body>
        <widget-card title="Solicitudes" classHeader="bg-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between">
                    <div class="text-white">Solicitudes</div>
                    <base-button-fab @on:click="openModal('newRequest')" :permissionId="37"></base-button-fab>
                </div>
            </template>
            <template v-slot:body>
                <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
                <!-- Filtros de búsqueda -->
                <form @submit.prevent="callLoadRequestsLists">
                    {{ asuntoChat }}
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-input-forms v-model="formDataFilter.codSolicitud" title="No. Solicitud"
                                :errors="errorsFields" inputId="codSolicitud"></base-input-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-select-forms v-model="formDataFilter.codAlmacen" title="Almacenes"
                                idSelect="codAlmacen" :options="shopAuthorizedLists" optionName="nombreAlmacen"
                                optionId="codAlmacen" :errors="errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-select-list v-model="formDataFilter.tipoIdentificacionCliente"
                                idSelect="tipoIdentificacionCliente" :errors="errorsFields" tipoLista="TPIDEN"
                                listaPadre="TPTERC" listaPadreId="2" title="Tipo ID Cliente"></base-select-list>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-input-forms v-model="formDataFilter.numIdentificacionCliente" title="No. Id Cliente"
                                :errors="errorsFields" inputId="numIdentificacionCliente"
                                typeData="numId"></base-input-forms>
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-">
                            <base-select-forms v-model="formDataFilter.estadoSolicitud" title="Estado solicitud"
                                idSelect="estadoSolicitud" :options="requestParameters.estados" optionName="0"
                                optionId="0" :withKey="true" :errors="errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-">
                            <base-select-forms v-model="formDataFilter.estadoFase1" title="Estado validaciones"
                                idSelect="estadoFase1" :options="requestParameters.fases_estado" optionName="0"
                                optionId="0" :withKey="true" :errors="errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-">
                            <base-select-forms v-model="formDataFilter.estadoFase2" title="Estado referenciación"
                                idSelect="estadoFase2" :options="requestParameters.fases_estado" optionName="0"
                                optionId="0" :withKey="true" :errors="errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-">
                            <base-select-forms v-model="formDataFilter.estadoFase3" title="Estado decisión"
                                idSelect="estadoFase3" :options="requestParameters.fases_estado" optionName="0"
                                optionId="0" :withKey="true" :errors="errorsFields"></base-select-forms>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <base-button-forms classButton="btn-primary" :isLoading="isLoading" :disabled="isLoading"
                            label="Buscar" :permissionId="36"></base-button-forms>
                    </div>
                </form>

                <widget-loading v-if="isLoading" />
                <template v-else-if="requestsLists.data">
                    <!-- Resultado búsqueda -->
                    <widget-select-paginator v-model="formDataFilter.numPages"
                        :onChange="callLoadRequestsLists"></widget-select-paginator>
                    <base-table-forms :data="requestsLists" :dataFunction="callLoadRequestsLists"
                    :headers="headers" :orderColumn="formDataFilter.column" :order="formDataFilter.order" :whitOrder="true">
                        <tr v-for="request in requestsLists.data" :key="request.Ca111CodSolicitud">
                            <td>{{ request.Ca111CodSolicitud }}</td>
                            <td>{{
                        `${request.Ca111PrimerNombre}
                                ${request.Ca111SegundoNombre}
                                ${request.Ca111PrimerApellido}
                                ${request.Ca111SegundoApellido}` }}</td>
                            <td>{{ request.Ca111NumeroIdCliente }}</td>
                            <td>{{ request.nombreAlmacen }}</td>
                            <td>{{ requestParameters.estados[request.Ca111EstadoSolicitud][0] }}</td>
                            <td class="text-center"><img
                                    :src="requestParameters.fases_estado[request.Ca111EstadoFase1][2]"
                                    :alt="request.Ca111EstadoFase1" width="20"></td>
                            <td class="text-center"><img
                                    :src="requestParameters.fases_estado[request.Ca111EstadoFase2][2]"
                                    :alt="request.Ca111EstadoFase2" width="20"></td>
                            <td class="text-center"><img
                                    :src="requestParameters.fases_estado[request.Ca111EstadoFase3][2]"
                                    :alt="request.Ca111EstadoFase3" width="20"></td>
                            <td>
                                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
                        { label: 'Editar', action: () => { openModal(request.Ca111CodSolicitud.toString()) }, permissionId: 38, visible: canEdit(request) },
                        { label: 'Iniciar chat', action: () => { asuntoChat = `Solicitud_${request.Ca111CodSolicitud}` }, permissionId: 38, visible: true },
                    ]"></widget-dropdown>
                            </td>
                        </tr>
                    </base-table-forms>
                </template>
                <div v-else>
                    <div class="row justify-content-md-center">
                        <div class="col-12 alert-info text-center mt-5">
                            No hay Información a mostrar
                        </div>
                    </div>
                </div>
            </template>
        </widget-card>
        <!-- Chat -->
        <floating-chat :tipoAsunto="1" :asunto="asuntoChat"></floating-chat>
    </the-content-body>
    <!-- Modal para crear o actualizar solicitud -->
    <widget-modal-boostrap modalId="modalRequest" :modalTitle="modalTitle" modalClassSize="modal-xl"
        :showFooter="false">
        <template v-slot:modalBody>
            <stepper-request :countItems="requestId == 'newRequest' ? 2 : 9" @on:createdRequest="openModal"
                :requestId="requestId" @on:loadRequestList="callLoadRequestsLists" @on:closeModal="closeModal">
            </stepper-request>
        </template>
    </widget-modal-boostrap>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { Modal } from 'bootstrap';

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import StepperRequest from '@/modules/credit-request/views/StepperRequest.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import FloatingChat from '@/modules/chat/components/FloatingChat.vue';


import useRequest from '@/modules/credit-request/composables/useRequest'
import useShop from '@/modules/shop/composables/useShop'


export default {
    components: {
        WidgetAlerts,
        BaseButtonFab,
        WidgetCard,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        BaseInputForms,
        WidgetLoading,
        WidgetModalBoostrap,
        WidgetSelectPaginator,
        BaseTableForms,
        BaseSelectForms,
        BaseSelectList,
        BaseButtonForms,
        StepperRequest,
        FloatingChat
    },

    setup() {
        const {
            isLoading,
            requestId,
            loadRequest,
            requestsLists,
            messageServer,
            modals,
            search,
            showAlert,
            typeAlert,
            // loadRequestParameters,
            requestParameters,
            errorsFields,
            formDataFilter,
            request,
        } = useRequest()

        const { getShopAuthorizedLists, shopAuthorizedLists } = useShop()
        const asuntoChat = ref()
        const headers=ref([
        { encabezado: '# Solicitud', columna: 'Ca111CodSolicitud' },
        { encabezado: 'Cliente', columna: 'nombreCliente' },
        { encabezado: 'Identificación', columna: 'Ca111NumeroIdCliente' },
        { encabezado: 'Almacén', columna: 'nombreAlmacen' },
        { encabezado: 'Estado', columna: 'Ca111EstadoSolicitud' },
        { encabezado: 'Validación', columna: 'Ca111EstadoFase1' },
        { encabezado: 'Referenciación', columna: 'Ca111EstadoFase2' },
        { encabezado: 'Decisión', columna: 'Ca111EstadoFase3' },
        { encabezado: 'Acciones', columna: null },
        
        ])


        onMounted(() => {
            modals.modalRequest = new Modal('#modalRequest', {})
        })
        const callLoadRequestsLists = async (page,column) => {
            formDataFilter.value.page = page

            if (column) {
                formDataFilter.value.column = column
                formDataFilter.value.order = formDataFilter.value.order == 'asc' ? 'desc' : 'asc'
            }
            loadRequest(formDataFilter.value)
        }

        callLoadRequestsLists()
        getShopAuthorizedLists('solicitud')


        return {
            requestId,
            request,
            requestsLists,
            messageServer,
            showAlert,
            typeAlert,
            isLoading,
            headers,
            requestParameters,
            errorsFields,
            formDataFilter,
            shopAuthorizedLists,
            asuntoChat,


            callLoadRequestsLists,
            openModal: (id) => {
                requestId.value = id
                modals.modalRequest.show()
            },
            closeModal: () => {
                modals.modalRequest.hide()
            },

            canEdit: (request) => {

                return request.Ca111EstadoSolicitud === 'PR' || request.Ca111EstadoSolicitud === 'DV' || request.Ca111EstadoFase1 === 'DV'

            },
            modalTitle: computed(() => requestId.value === 'newRequest' ? 'Crear Solicitud' : 'Actualizar Solicitud No. ' + requestId.value)

        }
    }

}
</script>

<style></style>