<template>
    <li class="nav-item dropdown user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" style="top: -30%">
            <!-- Avatar del usuario -->
            <TheUserAvatar :name="userDataLoggedIn.name" />
            <!-- <span class="d-none d-md-inline">
                Nombre Usuario
            </span> -->
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <li class="user-header bg-white">
                <!-- Avatar del usuario -->
                <TheUserAvatar avatarSize="l" :name="userDataLoggedIn.name" />
                <!-- Nombre y perfil usuario -->
                <p class="">
                    {{ userDataLoggedIn.name }}
                    <small>{{ userDataLoggedIn.perfilDescripcion }}</small>
                </p>
            </li>
            <li class="user-footer">
                <a href="#" class="btn btn-default btn-flat" @click="$router.push({ name: 'my-profile' })">
                    <i class="fa fa-fw fa-user text-lightblue"></i>
                    Perfil
                </a>
                <a class="btn btn-default btn-flat float-right " href="#" @click="onLogout">
                    <i class="fa fa-fw fa-power-off text-red"></i>
                    Salir
                </a>
            </li>
        </ul>
    </li>
</template>

<script>
import useAuth from '@/modules/auth/composables/useAuth'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import Swal from 'sweetalert2'

import TheUserAvatar from '@/modules/dashboard/components/TheUserAvatar.vue';



export default {
    components: {
        TheUserAvatar,
    },

    setup() {
        const router = useRouter()
        const { logout, userDataLoggedIn } = useAuth()
        return {
            userDataLoggedIn,
            onLogout: async () => {
                new Swal({
                    title: 'Cerrando sesión...',
                    allowOutsideClick: false
                })
                Swal.showLoading()
                const { response } = await logout()
                if (response) {
                    Swal.fire('Cesión cerrada correctamente', '', 'success')
                    router.push({ name: 'login' })
                    location.reload()
                } else {
                    Swal.fire('No se pudo cerrar la sesión correctamente', '', 'error')
                }



            }
        }
    },
}
</script>
<style lang="css" scoped src="../css/adminlte.css"></style>