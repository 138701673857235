<template>
    <label v-if="title" for="inputId" class="fw-bold">{{ title }}</label>
    <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1"><i :class="icon"></i></span>
        <input v-model="inputModel" :type="typeInput" :id="inputId" :name="inputId" class="form-control form-control-sm"
            :class="{ 'is-invalid': errors[inputId] }" :data-tp_dato="typeData" autocomplete="off" :required="required"
            :placeholder="placeholder" @blur="onBlur">
        <span v-if="errors[inputId]" class="invalid-feedback" role="alert">
            <small>{{ errors[inputId] ? errors[inputId][0] : '' }}</small>
        </span>
    </div>
</template>

<script>
import { computed, ref } from 'vue'

import inputMaskFormat from "@/helpers/inputMaskFormat"
export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            // type: String|Number,
        },
        title: {
            type: String,
            default: null
        },//Label
        icon: {
            type: String,
            default: 'fa-solid fa-maximize'
        },//Label
        typeInput: {
            type: String,
            default: null
        },
        typeData: {
            type: String,
            default: null
        },
        decimals: {
            type: Number,
            default: 0
        },
        inputId: {
            type: String,
            default: null
        },
        inputName: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: []
        },
        placeholder: {
            type: String,
            default: null
        },
        onBlur: {
            type: Function
        }

    },
    setup(props, { emit }) {
        const format = inputMaskFormat()
        const inputModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', setFormat(newValue, props.typeData))
            }
        })
        const setFormat = (input, type) => {

            switch (type) {
                case 'currence':
                    return format.inputCurrence(input, props.decimals)
                case 'phone':
                    return format.inputPhone(input)
                case 'numId':
                    return format.inputNumId(input)
                case 'plate':
                    return input.toUpperCase()
                default:
                    return format.inputText(input)
            }

        }
        return {
            inputModel,

            maxCharacteres: computed(() => {
                // return props.typeData === 'currence' ? 10 : 1
                switch (props.typeData) {
                    case 'currence':
                        return 12
                    case 'phone':
                        return 12
                    case 'numId':
                        return 12
                    case 'digit':
                        return 3
                    case 'plate':
                        return 6
                    case 'year':
                        return 4
                    default:
                        break;
                }
            })
        }

    }


}
</script>

<style scoped>
strong {
    margin-left: 40px;
}
</style>