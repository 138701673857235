<template>
    <widget-loading v-if="isLoading" />
    <div v-else>
        <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
        <base-table-forms :data="filesList" :headers="['Documento ', 'Seleccionar archivo', 'Capturar imagen', 'Ver archivo']"
            :whitPagination="false">
            <tr v-for="file in filesList.tiposArchivos" :key="file.codArchivo">
                <td>{{ file.nombreArchivo }}</td>
                <td>
                    <base-input-file :onChange="onUploadFile" :paramFunction="file.codArchivo" :permissionId="34" />
                </td>
                <td class="text-center">
                    <base-button-forms classButton="btn-info" type="button" icon="fa-solid fa-camera-retro" :onClick="showCamera"
                        :paramFunction="file.codArchivo" :permissionId="35" ></base-button-forms>
                </td>
                <td class="text-center">
                    <!-- <widget-spinner v-if="isCheckingFile"></widget-spinner> -->
                    <base-button-forms classButton="btn-info" type="button" icon="fa-solid fa-eye" :disabled="!file.existeImagen"
                        :onClick="showFile"
                        :paramFunction="{ url: file.rutaImagen, nombreArchivo: file.nombreArchivo }" :permissionId="33"></base-button-forms>
                </td>
            </tr>
        </base-table-forms>
    </div>
    <!-- Modal cámara -->
    <widget-modal-boostrap modalId="modalCamara" modalTitle="Capturar foto" :showFooter="false" modalStatic="static"
        @on:modalClose="hideCamera">
        <template v-slot:modalBody>
            <div class="row justify-content-center" v-if="!isLoading">
                <camera-component camaraId="web-camera-container" :tipoModelo="tipoModelo"
                    :codModelo="codModelo" :tipoAliado="tipoAliado" :ocupacionCliente="ocupacionCliente"
                    :estudioCategoria="estudioCategoria" ref="webCamera">
                </camera-component>
            </div>
        </template>
    </widget-modal-boostrap>
</template>

<script>
import { onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { Modal } from 'bootstrap';

import useFiles from '@/modules/files/composables/useFiles'
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseInputFile from '@/common/forms/BaseInputFile.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetSpinner from '@/common/widgets/WidgetSpinner.vue'
import CameraComponent from '../components/CameraComponent.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';

export default {

    props: {
        tipoModelo: '',
        codModelo: '',
        tipoAliado: '',
        ocupacionCliente: '',
        estudioCategoria: '',
    },

    components: {
        WidgetLoading,
        BaseTableForms,
        WidgetAlerts,
        BaseInputFile,
        BaseButtonForms,
        WidgetSpinner,
        CameraComponent,
        WidgetModalBoostrap
    },



    setup(props) {

        const { formData,loadFilesList, filesList, isLoading, showAlert, typeAlert, messageServer, uploadFile, isCheckingFile } = useFiles()
        const modals = ref({ modalCamara: null })
        const webCamera = ref(null)

        formData.value.tipoModelo=props.tipoModelo,
        formData.value.codModelo= props.codModelo,
        formData.value.tipoAliado= props.tipoAliado,
        formData.value.ocupacionCliente= props.ocupacionCliente,
        formData.value.estudioCategoria= props.estudioCategoria,

        onMounted(() => {
            modals.modalCamara = new Modal('#modalCamara', {})
        })
        loadFilesList(formData.value)

        return {
            filesList,
            isLoading,
            isCheckingFile,
            showAlert,
            typeAlert,
            messageServer,
            modals,
            webCamera,



            loadFilesList,

            onUploadFile: async (codArchivo, event) => {
                new Swal({
                    title: 'Subiendo archivo...',
                    allowOutsideClick: false
                })
                Swal.showLoading()
                const file = event.target.files[0]
                if (file) {
                    const form = new FormData();
                    form.append('file', file)
                    form.append('tipoModelo', props.tipoModelo)
                    form.append('codModelo', props.codModelo)
                    form.append('tipoArchivo', codArchivo)
                    const { response } = await uploadFile(form)
                    if (response) {
                        Swal.fire('Archivo subido', '', 'success')
                        return
                    }
                    // Swal.fire('Ha ocurrido un error', '', 'error')
                }
            },

            showFile: ({ url, nombreArchivo }) => {
                Swal.fire({
                    title: nombreArchivo,
                    //text: 'Modal with a custom image.',
                    imageUrl: url + '?' + Date.now(),
                    imageWidth: 500,
                    imageHeight: 400,
                    imageAlt: nombreArchivo,
                })
            },
            showCamera: (codArchivo) => {
                modals.modalCamara.show()
                webCamera.value.toggleCamera(codArchivo)
            },
            hideCamera: () => {
                webCamera.value.toggleCamera()
            }





        }


    }

}
</script>

<style>

</style>
