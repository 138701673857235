import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const getAvailable = async ({ commit }, { formData }) => {
    try {
        commit('clear')
        commit('showProcessHideAlert', 'cash')
        const { data } = await requestApi.post(`/caja/consultarCupo`, formData)
        commit('setAvailable', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}

export const getArticles = async ({ commit }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.get(`/caja/getArticulosCompra`)
        commit('setArticles', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const getTokenSale = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/caja/tokenCompra`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const registerSale = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/caja/registrarCompra`, formData)
        commit('setDataTicket', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const getTicket = async ({ commit }, { tipoTicket, codCompraRecaudo, codCliente = 0 }) => {
    try {
        commit('showProcessHideAlert', 'ticket')
        const { data } = await requestApi.get(`/caja/imprimirCopiaTicket?tipoTicket=${tipoTicket}&codCompraRecaudo=${codCompraRecaudo}&codCliente=${codCliente}`)
        commit('setDataTicket', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const getSales = async ({ commit }, { formData }) => {
    try {
        commit('clear')
        commit('showProcessHideAlert', 'cash')
        const { data } = await requestApi.post(`/caja/consultarCompras`, formData)
        commit('setSales', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const getTokenSaleReverse = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/caja/tokenReversarCompra`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const saleReverse = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/caja/reversarCompra`, formData)
        commit('saleReverse', { codCompra: formData.codCompra })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const getAccountStatus = async ({ commit }, { formData }) => {
    try {
        commit('clear')
        commit('showProcessHideAlert', 'cash')
        const { data } = await requestApi.post(`/caja/estadoCuenta`, formData)
        commit('setAccountStatus', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}

export const registerPayment = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/caja/realizarRecaudo`, formData)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}

export const getPayments = async ({ commit }, { formData }) => {
    try {
        commit('clear')
        commit('showProcessHideAlert', 'cash')
        const { data } = await requestApi.post(`/caja/consultarPagos`, formData)
        commit('setPayments', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}
export const paymentReverse = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/caja/reversarPago`, formData)
        commit('paymentReverse', { codPago: formData.codPago })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'casher', error: error })
    }
}


