export default () => ({
    objetivesList: {
         data:{}
    },
    objetivesSelect: {},
    objetive: {
        CA008NOMBREOBJETIVO:'' ,
        CA008X100PESO:''
    },
    isLoading: {
        list: false,
        objetive: false,
        operation: false,
    },
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: ''
    }
})