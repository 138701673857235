import inputFormat from '@/helpers/inputMaskFormat'

const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setRegister = (state, { data }) => {

    state.register = data
}

export const clearRegister = (state) => {
    for (const property in state.request) {
        state.request[property] = ''
    }
}
export const updateField = (state, { field, value }) => {
    state.register[field] = value;
}

export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'register':
            state.isLoadingRegister = true
            break;
        case 'operation':
            state.isLoadingOperationRegister = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingRegister = false
    state.isLoadingOperationRegister = false
    state.messageServer = messageServer
    // state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function() {
            state.showAlert = false
        }, 5000);
    }
}