export const setRequestList = (state, { data }) => {
    state.requestsLists = data
}

export const setRequest = (state, { data }) => {

    state.request = data
}
export const setResultFase2 = (state, { decision }) => {
    state.fase2decision = decision
}
export const setReferences = (state, { referencias }) => {
    state.references = referencias
}
export const setObjetiveResult = (state, { data }) => {
    state.objetiveResult = data
}
export const updateResultFase2 = (state, { data, referenceId }) => {
    state.fase2decision = data.puntajeSolicitud
    const idx = state.references.map(r => r.CA115CODREFERENCIA).indexOf(referenceId)
    state.references[idx].puntajeReferencia = data.puntajeReferencia
}

export const setQuestions = (state, { data }) => {
    state.questions = data
}
export const setCentralInformationValidation = (state, { data }) => {
    state.centralInformationValidation = data
}

export const clearRequest = (state) => {
    for (const property in state.request) {
        state.request[property] = ''
    }
}

export const updateField = (state, { field, value }) => {
    state.request[field] = value;
}
export const updateAnswer = (state, { formData, referenceId }) => {
    const idx = state.questions.preguntas.map(q => q.CA003CODPREGUNTA).indexOf(formData.idPregunta)
    state.questions.preguntas[idx].respuesta = formData.respuesta

    const idxReference = state.references.map(r => r.CA115CODREFERENCIA).indexOf(referenceId)
    state.references[idxReference].num_contestadas = state.questions.preguntas.filter(q => q.respuesta != '').length

}
export const updateRequest = (state, { data }) => {
    const { solicitud } = data
    const idx = state.requestsLists.data.map(u => u.Ca111CodSolicitud).indexOf(solicitud.Ca111CodSolicitud)
    state.requestsLists.data[idx] = solicitud

}

export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'requestList':
            state.isLoading = true
            break;
        case 'request':
            state.isLoadingRequest = true
            break;
        case 'operation':
            state.isLoadingOperationRequest = true
            break;
        case 'question':
            state.isLoadingQuestions = true
            break;
        case 'operationQuestion':
            state.isLoadingOperationQuestion = true
            break;
        case 'qualification':
            state.isLoadingQualification = true
            break;
        case 'operationQualification':
            state.isLoadingOperationQualification = true
            break;
    }
    state.alert.messageServer = ''
    state.alert.showAlert = false
    state.alert.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingRequest = false
    state.isLoadingOperationRequest = false
    state.isLoadingQuestions = false
    state.isLoadingOperationQuestion = false
    state.isLoadingQualification = false
    state.isLoadingOperationQualification = false
    state.alert.messageServer = messageServer
    // state.alert.showAlert = messageServer === '' ? false : true
    state.alert.errorsFields = errorsFields
    state.alert.typeAlert = statusResponse ? 'success' : 'danger'

    if (statusResponse && messageServer != '') {
        setTimeout(function () {
            state.showAlert = false
        }, 5000);
    }
}