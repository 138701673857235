<template>
    <widget-loading v-if="isLoadingRequest" />
    <template v-else>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-forms v-model="Ca111TipoVivienda" title="Tipo vivienda" idSelect="Ca111TipoVivienda"
                    :options="[{nombre:'Arrendada',codigo:'A'},{nombre:'Propia',codigo:'P'}]" optionName="nombre" optionId="codigo"
                    :errors="errorsFields"></base-select-forms>
            </div>
            <base-select-list-location 
                v-model:modelValueCountry="Ca111CodPais" 
                v-model:modelValueState="Ca111CodDepartamento" 
                v-model:modelValueCity="Ca111CodCiudad" 
                idSelectCountry="Ca111CodPais"
                idSelectState="Ca111CodDepartamento"
                idSelectCity="Ca111CodCiudad"
                :errors="errorsFields" 
            >
            </base-select-list-location>
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111Barrio" inputId="Ca111Barrio" :errors="errorsFields"
                    title="Barrio" typeInput="text"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111Direccion" inputId="Ca111Direccion" :errors="errorsFields"
                    title="Dirección" typeInput="text"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111TelefonoFijo" inputId="Ca111TelefonoFijo" :errors="errorsFields"
                    title="Teléfono fijo" typeInput="text" typeData="phone"></base-input-forms>
            </div>
        </div>
        <!-- Información arrendador -->
    <template v-if="Ca111TipoVivienda==='A'">
            <div class="badge bg-primary text-wrap mt-2" style="width: 10rem;">
            Información Arrendador
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLRCanonMensualVivienda" inputId="Ca111VLRCanonMensualVivienda" :errors="errorsFields"
                    title="Canon mensual" typeInput="text" typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111NombreArrendadorVivienda" inputId="Ca111NombreArrendadorVivienda" :errors="errorsFields"
                    title="Nombre arrendatario" typeInput="text"></base-input-forms>
            </div>
            <base-select-list-location 
                v-model:modelValueCountry="Ca111PaisArrendadorVivienda" 
                v-model:modelValueState="Ca111DptoArrendadorVivienda" 
                v-model:modelValueCity="Ca111CiudadArrendadorVivienda" 
                idSelectCountry="Ca111PaisArrendadorVivienda"
                idSelectState="Ca111DptoArrendadorVivienda"
                idSelectCity="Ca111CiudadArrendadorVivienda"
                :errors="errorsFields" 
            >
            </base-select-list-location>
        
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111TelefonoFijoArrendadorVivienda" inputId="Ca111TelefonoFijoArrendadorVivienda" :errors="errorsFields"
                    title="Teléfono fijo" typeInput="text" typeData="phone"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111TelefonoCelularArrendadorVivienda" inputId="Ca111TelefonoCelularArrendadorVivienda" :errors="errorsFields"
                    title="Celular" typeInput="text" typeData="phone"></base-input-forms>
            </div>
        </div>
    </template>
    </template>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation
    },
    setup() {
        const store = useStore()



        const {
            isLoading,
            loadRequest,
            isLoadingRequest,
            requestParameters,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111TipoVivienda: computed({
                get: () => store.state['request']['request']['Ca111TipoVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoVivienda', value: val })
            }),
            Ca111CodPais: computed({
                get: () => store.state['request']['request']['Ca111CodPais'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodPais', value: val })
            }),
            Ca111CodDepartamento: computed({
                get: () => store.state['request']['request']['Ca111CodDepartamento'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodDepartamento', value: val })
            }),
            Ca111CodCiudad: computed({
                get: () => store.state['request']['request']['Ca111CodCiudad'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodCiudad', value: val })
            }),
            Ca111Barrio: computed({
                get: () => store.state['request']['request']['Ca111Barrio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111Barrio', value: val })
            }),
            Ca111Direccion: computed({
                get: () => store.state['request']['request']['Ca111Direccion'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111Direccion', value: val })
            }),
            Ca111TelefonoFijo: computed({
                get: () => store.state['request']['request']['Ca111TelefonoFijo'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoFijo', value: val })
            }),
            Ca111VLRCanonMensualVivienda: computed({
                get: () => store.state['request']['request']['Ca111VLRCanonMensualVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRCanonMensualVivienda', value: val })
            }),
            Ca111NombreArrendadorVivienda: computed({
                get: () => store.state['request']['request']['Ca111NombreArrendadorVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreArrendadorVivienda', value: val })
            }),
            Ca111PaisArrendadorVivienda: computed({
                get: () => store.state['request']['request']['Ca111PaisArrendadorVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111PaisArrendadorVivienda', value: val })
            }),
            Ca111DptoArrendadorVivienda: computed({
                get: () => store.state['request']['request']['Ca111DptoArrendadorVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DptoArrendadorVivienda', value: val })
            }),
            Ca111CiudadArrendadorVivienda: computed({
                get: () => store.state['request']['request']['Ca111CiudadArrendadorVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CiudadArrendadorVivienda', value: val })
            }),
            Ca111TelefonoFijoArrendadorVivienda: computed({
                get: () => store.state['request']['request']['Ca111TelefonoFijoArrendadorVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoFijoArrendadorVivienda', value: val })
            }),
            Ca111TelefonoCelularArrendadorVivienda: computed({
                get: () => store.state['request']['request']['Ca111TelefonoCelularArrendadorVivienda'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoCelularArrendadorVivienda', value: val })
            }),
        }

    },
}
</script>