<template>
    <widget-loading v-if="isLoadingRequest" />
    <template v-else>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-list v-model="Ca111TipoIdCliente" idSelect="Ca111TipoIdCliente" :errors="errorsFields"
                    tipoLista="TPIDEN" listaPadre="TPTERC" listaPadreId="2" title="Tipo ID Usuario">
                </base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111NumeroIdCliente" inputId="Ca111NumeroIdCliente" :errors="errorsFields"
                    title="Número ID" typeInput="text" typeData="numId"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111FechaNacimiento" inputId="Ca111FechaNacimiento" :errors="errorsFields"
                    title="Fecha Nacimiento" typeInput="date" typeData="date"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111FechaExpDocID" inputId="Ca111FechaExpDocID" :errors="errorsFields"
                    title="Fecha Exp. Doc." typeInput="date" typeData="date"></base-input-forms>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111CiudadExpedicion" inputId="Ca111CiudadExpedicion" :errors="errorsFields"
                    title="Ciudad expedición" typeInput="text"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111PrimerNombre" inputId="Ca111PrimerNombre" :errors="errorsFields"
                    title="Primer nombre" typeInput="text"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111SegundoNombre" inputId="Ca111SegundoNombre" :errors="errorsFields"
                    title="Segundo nombre" typeInput="text"></base-input-forms>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111PrimerApellido" inputId="Ca111PrimerApellido" :errors="errorsFields"
                    title="Primer Apellido" typeInput="text"></base-input-forms>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111SegundoApellido" inputId="Ca111SegundoApellido" :errors="errorsFields"
                    title="Segundo apellido" typeInput="text"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-list v-model="Ca111Genero" idSelect="Ca111Genero" :errors="errorsFields" tipoLista="GENERO"
                    listaPadre="" listaPadreId="" title="Género">
                </base-select-list>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-list v-model="Ca111EstadoCivil" idSelect="Ca111EstadoCivil" :errors="errorsFields"
                    tipoLista="ESTCIV" listaPadre="" listaPadreId="" title="Estado civil">
                </base-select-list>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111TelefonoCelular" inputId="Ca111TelefonoCelular" :errors="errorsFields"
                    title="Celular" typeInput="text" typeData="phone"></base-input-forms>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111CorreoElectronico" inputId="Ca111CorreoElectronico" :errors="errorsFields"
                    title="Correo electrónico" typeInput="email"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111NumTotalHijos" inputId="Ca111NumTotalHijos" :errors="errorsFields"
                    title="No. hijos" typeData="digit"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111NumHijosDependenciaFinanciera" inputId="Ca111NumHijosDependenciaFinanciera"
                    :errors="errorsFields" title="No. Hijos dependientes" typeData="digit"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111NumOtrosDependenciaFinanciera" inputId="Ca111NumOtrosDependenciaFinanciera"
                    :errors="errorsFields" title="No. Otros" typeData="digit"></base-input-forms>
            </div>
        </div>
    </template>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseInputForms,
        WidgetLoading
    },
    setup() {
        const store = useStore()
        


        const {
            isLoading,
            loadRequest,
            isLoadingRequest,
            requestParameters,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111TipoIdCliente: computed({
                get: () => store.state['request']['request']['Ca111TipoIdCliente'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoIdCliente', value: val })
            }),
            Ca111NumeroIdCliente: computed({
                get: () => store.state['request']['request']['Ca111NumeroIdCliente'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NumeroIdCliente', value: val })
            }),
            Ca111FechaExpDocID: computed({
                get: () => store.state['request']['request']['Ca111FechaExpDocID'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111FechaExpDocID', value: val })
            }),
            Ca111CiudadExpedicion: computed({
                get: () => store.state['request']['request']['Ca111CiudadExpedicion'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CiudadExpedicion', value: val })
            }),
            Ca111PrimerNombre: computed({
                get: () => store.state['request']['request']['Ca111PrimerNombre'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111PrimerNombre', value: val })
            }),
            Ca111SegundoNombre: computed({
                get: () => store.state['request']['request']['Ca111SegundoNombre'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111SegundoNombre', value: val })
            }),
            Ca111PrimerApellido: computed({
                get: () => store.state['request']['request']['Ca111PrimerApellido'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111PrimerApellido', value: val })
            }),
            Ca111SegundoApellido: computed({
                get: () => store.state['request']['request']['Ca111SegundoApellido'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111SegundoApellido', value: val })
            }),
            Ca111Genero: computed({
                get: () => store.state['request']['request']['Ca111Genero'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111Genero', value: val })
            }),
            Ca111FechaNacimiento: computed({
                get: () => store.state['request']['request']['Ca111FechaNacimiento'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111FechaNacimiento', value: val })
            }),
            Ca111EstadoCivil: computed({
                get: () => store.state['request']['request']['Ca111EstadoCivil'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111EstadoCivil', value: val })
            }),
            Ca111TelefonoCelular: computed({
                get: () => store.state['request']['request']['Ca111TelefonoCelular'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoCelular', value: val })
            }),
            Ca111CorreoElectronico: computed({
                get: () => store.state['request']['request']['Ca111CorreoElectronico'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CorreoElectronico', value: val })
            }),
            Ca111NumTotalHijos: computed({
                get: () => store.state['request']['request']['Ca111NumTotalHijos'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NumTotalHijos', value: val })
            }),
            Ca111NumHijosDependenciaFinanciera: computed({
                get: () => store.state['request']['request']['Ca111NumHijosDependenciaFinanciera'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NumHijosDependenciaFinanciera', value: val })
            }),
            Ca111NumOtrosDependenciaFinanciera: computed({
                get: () => store.state['request']['request']['Ca111NumOtrosDependenciaFinanciera'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NumOtrosDependenciaFinanciera', value: val })
            }),
        }

    },
}
</script>