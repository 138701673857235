const inputFormat = () => {
    /**
     * Recibe un valor numérico y lo separa  en miles
     * @param {String|Number} input Valor o texto a separar en miles
     * @param {Number} decimal Número de decimales
     * @param {string} type Define si el valor viene de un campo text o una variable
     * @returns String seprado en miles
     */
    const inputCurrence = (input, decimal = 2, type = 'field') => {
        if (type === 'field') {
            let regexDecimales = decimal === 2 ? /([0-9])([0-9]{2})$/ : '';
            let digitosDecimales = decimal === 2 ? '$1,$2' : '';

            return input.replace(/\D/g, "")
                .replace(regexDecimales, digitosDecimales) //Separa decimales
                .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, "."); //Separa miles
        }

        //Dar formato a una variable
        let valor = Number(input) / 100; //Se divide en 100 porque los valores de la DB vienen sin separación de decimas
        return Intl.NumberFormat(['ban', 'id'], { maximumFractionDigits: decimal, minimumFractionDigits: decimal }).format(valor); //Se coloca ['ban', 'id'] debido a que el español no esta soportado.
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat


    }
    /**
     * Recibe un valor numerico y le da formato de teléfono separado por guiones
     * @param {String} input valor a dar formato
     * @returns String separado por guiones
     */
    const inputPhone = (input) => {
        var x = input.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2] ? x[1] : +x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

    }
    /**
     * Recibe un valor numérico y lo separa en puntos por miles como número de identificación
     * @param {String} input valor a dar formato
     * @returns String separado por puntos
     */
    const inputNumId = (input) => {
        return input.replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
    }

    const inputText = (input) => {
        return input
    }

    /**
     * Recibe una fecha y la devuelve en un formato específico
     * 
     * @param {String|Date} fecha valor a dar formato
     * @param {Number} tipo define el tipo de formato a dar
     * @returns String con la fecha formateada
     */
    const inputDate = (fecha, tipo) => {
        if (fecha == '') {
            return ''
        }
        let ano = "";
        let mes = "";
        let dia = "";
        let fechaString = "";
        if (tipo > 1) {
            ano = fecha.toString().slice(0, 4);
            mes = fecha.toString().slice(4, 6);
            dia = fecha.toString().slice(6, 8);
            fechaString = ano + '-' + mes + '-' + dia;
        }
        switch (tipo) {
            case 1:
                //Recibe un objet new Date()
                //Retorna 22-Agosto-2022 12:44:51
                const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
                return fecha.getDate() + "-" + meses[fecha.getMonth()] + "-" + fecha.getFullYear() + " " + fecha.getHours() + ":" + fecha.getMinutes() + ":" + fecha.getSeconds();
            case 2:
                //Recibe fecha en string 20220822
                //Devuelve 2022-ago-22

                let nombreMesCorto = Intl.DateTimeFormat('es', { month: "short" }).format(new Date(fechaString));
                let nuevaFecha = ano + '-' + nombreMesCorto + '-' + dia;
                return nuevaFecha;

            case 3:
                //Recibe fecha en string 20220822
                //Devuelve 2022-08-22
                return fechaString;
            case 4:
                //Recibe fecha en string 20220822
                //Devuelve 22-ago-2022
                let nombreMesCorto2 = Intl.DateTimeFormat('es', { month: "short" }).format(new Date(fechaString));
                let nuevaFecha2 = dia + '-' + nombreMesCorto2 + '-' + ano;
                return nuevaFecha2;
            default:
                return fecha;
        }
    }

    /**
     * Recibie un String con una fecha y calcula cuanto tiempo ha pasado desde la fecha dada hasta la actual
     * @param {Sring} date fecha a calcular
     * @returns Objeto con el calculo en minutos,horas y días
     */
    const diffForHumans = (date) => {
        const startDate = new Date(date);
        const endDate = new Date();

        const diffInMilliseconds = endDate - startDate;
        const elapsedMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
        const elapsedHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const elapsedDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

        return {
            minuts: elapsedMinutes,
            hours: elapsedHours,
            days: elapsedDays
        };
    }

    /**
     * Recibe un número en String o texto con números y retorna solo los números en formato numérico
     * @param {String} string cadena a convertir
     * @returns Numero
     */
    const getNumberOfString = (string) => {
        var tmp = string.split("");
        var map = tmp.map(function (current) {
            if (!isNaN(parseInt(current))) {
                return current;
            }
        });

        var numbers = map.filter(function (value) {
            return value != undefined;
        });

        return parseFloat(numbers.join(""));
    }

    /**
     * Recibe un array de objetos y lo retorna agrupado por una llave específica
     * @param {Array} array Array de objetos a agrupar
     * @param {String} key Llave por el que se desea agrupar
     * @returns Array con el objeto agrupado
     */
    const groupBy = (array, key) => {
        return array.reduce((acc, item) => {
            if (!acc[item[key]]) acc[item[key]] = []
            acc[item[key]].push(item)
            return acc
        }, {});
    }

    return {
        inputCurrence,
        inputDate,
        inputNumId,
        inputPhone,
        inputText,
        diffForHumans,
        getNumberOfString,
        groupBy
    }

}

export default inputFormat