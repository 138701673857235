<template>
    <widget-loading v-if="isLoading.task" />
    <form v-else @submit.prevent="onSubmit">
        <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
            :typeAlert="alert.typeAlert"></widget-alerts>
        <div class="form-group row">

            <div class="col-sm-6 col-xs-12">
                <base-input-forms v-model="nombre" :errors="alert.errorsFields" title="Título Tarea" inputId="nombre"
                    :disabled="!canEditFile(task)"></base-input-forms>
            </div>

            <div class="col-sm-6 col-xs-12">
                <base-input-forms v-model="descripcion" :errors="alert.errorsFields" title="Descripción"
                    inputId="descripcion" :disabled="!canEditFile(task)"></base-input-forms>
            </div>
        </div>
        <div class="form-group row">

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-datalist v-model="nomCategoria" title="Categoría" :list="categorias" optionName="CA129NOMBRE"
                    optionValue="CA129NOMBRE" inputId="nomCategoria" :errors="alert.errorsFields"
                    :disabled="!canEditFile(task)"></base-datalist>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
                <base-select-forms v-model="prioridad" title="Prioridad" :options="taskParameters.prioridad"
                    optionId="codigo" optionName="nombre" :errors="alert.errorsFields" idSelect="prioridad"
                    :disabled="!canEditFile(task)"></base-select-forms>

            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-datalist v-model="emailUsuarioAsignado" title="Asignada" :list="contactosFrecuentes"
                    optionName="nombre" optionValue="email" inputId="emailUsuarioAsignado" :errors="alert.errorsFields"
                    :disabled="!canEditFile(task)"></base-datalist>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="fechaInicio" :errors="alert.errorsFields" title="Inicio"
                    inputId="fechaInicio" typeInput="date" :disabled="!canEditFile(task)"></base-input-forms>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="fechaEjecucionEstimada" :errors="alert.errorsFields" title="Finalización"
                    inputId="fechaEjecucionEstimada" typeInput="date" :disabled="!canEditFile(task)"></base-input-forms>
            </div>

        </div>
        <div class="form-group row">

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-select-forms v-model="frecuenciaEjecucion" title="Repetir cada"
                    :options="taskParameters.frecuenciaEjecucion" optionId="codigo" optionName="nombre"
                    :errors="alert.errorsFields" idSelect="frecuenciaEjecucion"
                    :disabled="!canEditFile(task)"></base-select-forms>
            </div>
            <!-- Cambiar de estado -->
            <div  v-if="typeOperation != 'newTask'" class="col-sm-6 col-xs-12">
                <label class="fw-bold pt-2">Estado:</label>
                <p v-if="isLoading.operation">Procesando...</p>
                <div v-else>
                    <span v-for="estado in taskParameters.estados" :key="estado.codigo"
                        @click="task.estado == estado.codigo ? () => { } : onChangeStatus(estado.codigo)"
                        class="border badge" :class="task.estado == estado.codigo ? 'text-bg-primary' : 'text-bg-light'"
                        role="button">
                        {{ estado.nombre }}
                    </span>
                </div>
            </div>
        </div>
        <template v-if="typeOperation != 'newTask'">
            <!-- Repeticiones -->
            <template v-if="frecuenciaEjecucion > 0">
                <br>
                <div class="row text-center">
                    <label class="fw-bold pt-2">Historial de Ejecuciones</label>
                </div>
                <table class="table table-sm table-striped table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Fecha Ejecución</th>
                            <th>Responsable</th>
                            <th>Observación</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(repeticion, index) in task.repeticiones" :key="repeticion.CA127CODREPETICION">
                            <td>{{ index + 1 }}</td>
                            <td>{{ repeticion.CA127FECHAEJECUCIONREALTIM }}</td>
                            <td>{{ repeticion.usuario.name }}</td>
                            <td>{{ repeticion.CA127OBSERVACIONES }}</td>
                        </tr>
                    </tbody>

                </table>
            </template>
        </template>

        <!-- Seguimiento -->
        <div class="d-flex justify-content-center mt-4" v-if="canEditFile(task)">
            <base-input-check-radio v-model="seguimiento" title="Hacer seguimiento a esta tarea" inputId="seguimiento"
                typeInput="checkbox" :inlineInput="false" :inputValue="seguimiento"
                :errors="alert.errorsFields"></base-input-check-radio>
        </div>
        <!-- Botón -->
        <div v-if="canEditFile(task)" class="d-flex justify-content-center mt-4">
            <base-button-forms classButton="btn-primary" label="Guardar" :isLoading="isLoading.operation"
                :disabled="isLoading.operation" :permissionId="117"></base-button-forms>
        </div>
    </form>

</template>


<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex'

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseDatalist from '@/common/forms/BaseDatalist.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useTasks from '../composables/useTasks';

export default {

    components: {
        WidgetAlerts,
        BaseInputGroup,
        BaseButtonFab,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        WidgetLoading,
        BaseTableForms,
        BaseButtonForms,
        BaseInputForms,
        BaseInputCheckRadio,
        BaseSelectForms,
        BaseDatalist,
        BaseTextArea
    },
    props: {
        typeOperation: {
            required: true,
            default: ''
        }
    },

    emits: ['on:createdTask', 'on:closeModal'],
    setup(props, { emit }) {

        const store = useStore()
        const {
            isLoading,
            task,
            createTask,
            updateTask,
            contactosFrecuentes,
            categorias,
            alert,
            canEditTask,
            taskParameters,
            tasksList,
            changeStatus
        } = useTasks()


        return {


            isLoading,
            alert,
            task,
            contactosFrecuentes,
            categorias,
            canEditTask,
            taskParameters,
            canEditFile: (task) => {
                return canEditTask(task) || props.typeOperation == 'newTask'
            },


            onSubmit: async () => {
                // Crear Tarea
                if (props.typeOperation === 'newTask') {

                    const { response } = await createTask(task.value)
                    if (response) {
                        let codTarea = tasksList.value[0].CA126CODTAREA
                        emit('on:createdTask', codTarea)
                    }
                } else {
                    // Actualizar tarea
                    const { response } = await updateTask(task.value.codTarea, task.value)


                }
            },
            onChangeStatus: async (estado) => {
                store.commit('tasks/updateField', { field: 'estado', value: estado })
                const { response } = await changeStatus(task.value.codTarea, { 'estado': estado, 'observaciones': '' })
            },
            nombre: computed({
                get: () => task.value.nombre,
                set: (val) => store.commit('tasks/updateField', { field: 'nombre', value: val })
            }),
            // nomCategoria: computed({
            //     get: () => task.value.nomCategoria,
            // }),
            descripcion: computed({
                get: () => task.value.descripcion,
                set: (val) => store.commit('tasks/updateField', { field: 'descripcion', value: val })
            }),
            nomCategoria: computed({
                get: () => task.value.nomCategoria,
                set: (val) => store.commit('tasks/updateField', { field: 'nomCategoria', value: val })
            }),
            prioridad: computed({
                get: () => task.value.prioridad,
                set: (val) => store.commit('tasks/updateField', { field: 'prioridad', value: val })
            }),
            emailUsuarioAsignado: computed({
                get: () => task.value.emailUsuarioAsignado,
                set: (val) => store.commit('tasks/updateField', { field: 'emailUsuarioAsignado', value: val })
            }),
            nombreUsuarioAsignado: computed({
                get: () => task.value.usuarioAsignado.name,
            }),
            fechaInicio: computed({
                get: () => task.value.fechaInicio,
                set: (val) => store.commit('tasks/updateField', { field: 'fechaInicio', value: val })
            }),
            fechaEjecucionEstimada: computed({
                get: () => task.value.fechaEjecucionEstimada,
                set: (val) => store.commit('tasks/updateField', { field: 'fechaEjecucionEstimada', value: val })
            }),
            frecuenciaEjecucion: computed({
                get: () => task.value.frecuenciaEjecucion,
                set: (val) => store.commit('tasks/updateField', { field: 'frecuenciaEjecucion', value: val })
            }),
            seguimiento: computed({
                get: () => task.value.seguimiento,
                set: (val) => store.commit('tasks/updateField', { field: 'seguimiento', value: val })
            }),




        }

    },
}
</script>