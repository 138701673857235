<template>
    <div class="form-group row">
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111NombreNegocio" inputId="Ca111NombreNegocio" :errors="errorsFields"
                title="Nombre negocio" typeInput="text"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111CantMesesNegocio" inputId="Ca111CantMesesNegocio" :errors="errorsFields"
                title="Tiempo negocio en meses" typeInput="text" typeData="digit"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-text-area v-model="Ca111DescripcionActividadEco" textareaId="Ca111DescripcionActividadEco"
                title="Objeto del Negocio"></base-text-area>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-select-forms v-model="Ca111TipoDomicilioNegocio" title="Tipo domicilio" idSelect="Ca111TipoDomicilioNegocio"
                :options="[{ nombre: 'Arrendada', codigo: 'A' }, { nombre: 'Propia', codigo: 'P' }]" optionName="nombre"
                optionId="codigo" :errors="errorsFields">
            </base-select-forms>
        </div>
    </div>
    <div class="form-group row">
        <base-select-list-location v-model:modelValueCountry="Ca111CodPaisNegocio"
            v-model:modelValueState="Ca111CodigoDptoNegocio" v-model:modelValueCity="Ca111CodCiudadNegocio"
            idSelectCountry="Ca111CodPaisNegocio" idSelectState="Ca111CodigoDptoNegocio"
            idSelectCity="Ca111CodCiudadNegocio" :errors="errorsFields">
        </base-select-list-location>
         <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111BarrioNegocio" inputId="Ca111BarrioNegocio" :errors="errorsFields"
                title="Barrio" typeInput="text"></base-input-forms>
        </div>
    </div>
    <div class="form-group row">
         <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111DireccionNegocio" inputId="Ca111DireccionNegocio" :errors="errorsFields"
                title="Dirección" typeInput="text"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111TelefonoFijoNegocio" inputId="Ca111TelefonoFijoNegocio" :errors="errorsFields"
                title="Teléfono fijo" typeInput="text" typeData="phone"></base-input-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <base-input-forms v-model="Ca111TelefonoCelularNegocio" inputId="Ca111TelefonoCelularNegocio" :errors="errorsFields"
                title="Teléfono celular" typeInput="text" typeData="phone"></base-input-forms>
        </div>
    </div>
    <!-- Información arrendador -->
    <template v-if="Ca111TipoDomicilioNegocio === 'A'">
        <div class="badge bg-primary text-wrap mt-2" style="width: 10rem;">
            Información Arrendador
        </div>
        <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111VLRCanonMensualNegocio" inputId="Ca111VLRCanonMensualNegocio"
                    :errors="errorsFields" title="Canon mensual" typeInput="text" typeData="currence"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111NombreArrendadorNegocio" inputId="Ca111NombreArrendadorNegocio"
                    :errors="errorsFields" title="Nombre arrendatario" typeInput="text"></base-input-forms>
            </div>
            <base-select-list-location v-model:modelValueCountry="Ca111CodPaisArrendadorNegocio"
                v-model:modelValueState="Ca111CodigoDptoArrendadorNegocio" v-model:modelValueCity="Ca111CodCiudadArrendadorNegocio"
                idSelectCountry="Ca111CodPaisArrendadorNegocio" idSelectState="Ca111CodigoDptoArrendadorNegocio"
                idSelectCity="Ca111CodCiudadArrendadorNegocio" :errors="errorsFields">
            </base-select-list-location>

            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111TelefonoFijoArrendadorNegocio" inputId="Ca111TelefonoFijoArrendadorNegocio"
                    :errors="errorsFields" title="Teléfono fijo" typeInput="text" typeData="phone"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <base-input-forms v-model="Ca111TelefonoCelularArrendadorNegocio"
                    inputId="Ca111TelefonoCelularArrendadorNegocio" :errors="errorsFields" title="Celular" typeInput="text"
                    typeData="phone"></base-input-forms>
            </div>
        </div>
    </template>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';

import useRequest from '@/modules/credit-request/composables/useRequest'


export default {

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseTextArea
    },
    setup() {
        const store = useStore()



        const {
            isLoadingRequest,
            errorsFields,
        } = useRequest()




        return {
            errorsFields,
            isLoadingRequest,

            Ca111NombreNegocio: computed({
                get: () => store.state['request']['request']['Ca111NombreNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreNegocio', value: val })
            }),
            Ca111CantMesesNegocio: computed({
                get: () => store.state['request']['request']['Ca111CantMesesNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CantMesesNegocio', value: val })
            }),
            Ca111DescripcionActividadEco: computed({
                get: () => store.state['request']['request']['Ca111DescripcionActividadEco'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DescripcionActividadEco', value: val })
            }),
            Ca111TipoDomicilioNegocio: computed({
                get: () => store.state['request']['request']['Ca111TipoDomicilioNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TipoDomicilioNegocio', value: val })
            }),
            Ca111CodPaisNegocio: computed({
                get: () => store.state['request']['request']['Ca111CodPaisNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodPaisNegocio', value: val })
            }),
            Ca111CodigoDptoNegocio: computed({
                get: () => store.state['request']['request']['Ca111CodigoDptoNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodigoDptoNegocio', value: val })
            }),
            Ca111CodCiudadNegocio: computed({
                get: () => store.state['request']['request']['Ca111CodCiudadNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodCiudadNegocio', value: val })
            }),
            Ca111BarrioNegocio: computed({
                get: () => store.state['request']['request']['Ca111BarrioNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111BarrioNegocio', value: val })
            }),
            Ca111DireccionNegocio: computed({
                get: () => store.state['request']['request']['Ca111DireccionNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111DireccionNegocio', value: val })
            }),
            Ca111TelefonoFijoNegocio: computed({
                get: () => store.state['request']['request']['Ca111TelefonoFijoNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoFijoNegocio', value: val })
            }),
            Ca111TelefonoCelularNegocio: computed({
                get: () => store.state['request']['request']['Ca111TelefonoCelularNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoCelularNegocio', value: val })
            }),

            Ca111VLRCanonMensualNegocio: computed({
                get: () => store.state['request']['request']['Ca111VLRCanonMensualNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111VLRCanonMensualNegocio', value: val })
            }),
            Ca111NombreArrendadorNegocio: computed({
                get: () => store.state['request']['request']['Ca111NombreArrendadorNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111NombreArrendadorNegocio', value: val })
            }),
            Ca111CodPaisArrendadorNegocio: computed({
                get: () => store.state['request']['request']['Ca111CodPaisArrendadorNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodPaisArrendadorNegocio', value: val })
            }),
            Ca111CodigoDptoArrendadorNegocio: computed({
                get: () => store.state['request']['request']['Ca111CodigoDptoArrendadorNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodigoDptoArrendadorNegocio', value: val })
            }),
            Ca111CodCiudadArrendadorNegocio: computed({
                get: () => store.state['request']['request']['Ca111CodCiudadArrendadorNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111CodCiudadArrendadorNegocio', value: val })
            }),
            Ca111TelefonoFijoArrendadorNegocio: computed({
                get: () => store.state['request']['request']['Ca111TelefonoFijoArrendadorNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoFijoArrendadorNegocio', value: val })
            }),
            Ca111TelefonoCelularArrendadorNegocio: computed({
                get: () => store.state['request']['request']['Ca111TelefonoCelularArrendadorNegocio'],
                set: (val) => store.commit('request/updateField', { field: 'Ca111TelefonoCelularArrendadorNegocio', value: val })
            }),

        }

    },
}
</script>