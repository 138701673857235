import inputFormat from "@/helpers/inputMaskFormat"
import { orderArray } from '@/helpers/utilities'

export const setShopAuthorized = (state, data) => {
    state.shopAuthorized = data
}


const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()

export const setShopList = (state, { data }) => {
    state.shopList = data
}
export const setShop = (state, { data }) => {
    for (const key in data) {
        if (key.includes('Fecha')) {
            data[key] = inputDate(data[key], 3);
        }
        if (key.includes('VLR')) {
            data[key] = inputCurrence(data[key], 0, 'state');
        }
        if (key.includes('numId')) {
            data[key] = inputNumId(data[key]);
        }
        if (key.includes('Telefono') || key.includes('Celular')) {
            data[key] = inputPhone(data[key]);
        }
    }
    state.shop = data
    // Convertir el objeto de las secciones en un array
    let secciones = []
    for (const key in data.secciones) {
        secciones.push(data.secciones[key].CA124CODSECCION)
    }
    state.shop.secciones = secciones
}
export const setSimpleList = (state, { data }) => {
    state.simpleList = data
}
export const clearSimpleList = (state) => {
    state.simpleList = {}
}
export const addShop = (state, { data }) => {
    state.shopList.data.unshift(data)
    state.shop.Ca110CodAlmacen = data.Ca110CodAlmacen
}
export const updateField = (state, { field, value }) => {
    state.shop[field] = value;
}

export const updateShop = (state, { data }) => {
    const idx = state.shopList.data.map(u => u.Ca110CodAlmacen).indexOf(data.Ca110CodAlmacen)
    state.shopList.data[idx] = data
}
export const clearShop = (state) => {
    for (const property in state.shop) {
        state.shop[property] = ''
    }
    state.shop['secciones'] = []
}
export const showProcessHideAlert = (state, typeProcess) => {

    switch (typeProcess) {
        case 'shopList':
            state.isLoading = true
            break;
        case 'shop':
            state.isLoadingShop = true
            break;
        case 'operation':
            state.isLoadingOperationShop = true
            break;
    }
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, statusResponse }) => {

    state.isLoading = false
    state.isLoadingShop = false
    state.isLoadingOperationShop = false
    state.messageServer = messageServer
    // state.showAlert = messageServer === '' ? false : true
    state.errorsFields = errorsFields
    state.typeAlert = statusResponse ? 'success' : 'danger'

    // if (statusResponse && messageServer != '') {
    //     setTimeout(function () {
    //         state.showAlert = false
    //     }, 5000);
    // }

}


