<template>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <label :for="idSelectBody" class="fw-bold">{{ titleBody }}</label>
        <select v-model="selectModelBody" :name="idSelectBody" :id="idSelectBody" class="form-control form-control-sm"
            :class="{ 'is-invalid': errors[idSelectBody] }" :required="required" @blur="onBlur" :disabled="disabled">
            <option value="">Seleccione...</option>
            <option v-for="{ cod, name } in bodies" :value="cod" :key="cod">{{
                name }}</option>
        </select>
        <span v-if="errors[idSelectBody]" class="invalid-feedback" role="alert">
            <small>{{ errors[idSelectBody] ? errors[idSelectBody][0] : '' }}</small>
        </span>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <label :for="idSelectSubdivision" class="fw-bold">{{ titleSubdivision }}</label>
        <select v-model="selectModelSubdivision" :name="idSelectSubdivision" :id="idSelectSubdivision"
            class="form-control form-control-sm" :class="{ 'is-invalid': errors[idSelectSubdivision] }" :required="required"
            @blur="onBlur" :disabled="disabled">
            <option value="">Seleccione...</option>
            <option v-for="{ cod, name } in subdivision" :value="cod"
                :key="cod">{{ name }}
            </option>
        </select>
        <span v-if="errors[idSelectSubdivision]" class="invalid-feedback" role="alert">
            <small>{{ errors[idSelectBody] ? errors[idSelectSubdivision][0] : '' }}</small>
        </span>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <label :for="idSelectRange" class="fw-bold">{{ titleRange }}</label>
        <select v-model="selectModelRange" :name="idSelectRange" :id="idSelectRange" class="form-control form-control-sm"
            :class="{ 'is-invalid': errors[idSelectRange] }" :required="required" @blur="onBlur" :disabled="disabled">
            <option value="">Seleccione...</option>
            <option v-for="{ codRango, descripcionRango } in ranges" :value="codRango" :key="codRango">{{ descripcionRango
            }}</option>
        </select>
        <span v-if="errors[idSelectRange]" class="invalid-feedback" role="alert">
            <small>{{ errors[idSelectRange] ? errors[idSelectRange][0] : '' }}</small>
        </span>
    </div>
</template>
<script>


import { computed, ref, watch } from "vue"

import requestApi from '@/api/requestApi'
import { useStore } from "vuex";

export default {
    props: {
        modelValueBody: {
            required: false,
            default: '',
            // type: String|Number,
        },
        modelValueRange: {
            required: false,
            default: '',
            // type: String|Number,
        },
        modelValueSubdivision: {
            required: false,
            default: '',
            // type: String|Number,
        },
        idSelectBody: {
            type: String,
            default: ''
        },
        titleBody: {
            type: String,
            default: 'Fuerza armada'
        },
        idSelectSubdivision: {
            type: String,
            default: ''
        },
        titleSubdivision: {
            type: String,
            default: 'Subdivisión'
        },
        idSelectRange: {
            type: String,
            default: ''
        },
        titleRange: {
            type: String,
            default: 'Rango'
        },
        errors: Object,
        required: {
            type: Boolean,
            default: false
        },
        onBlur: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },

    setup(props, { emit }) {

        const store = useStore()

        const bodies = ref(store.getters['parameterization/getListBodiesArmed'])
        const subdivision = ref(store.getters['parameterization/getListSubdivision'])
        const ranges = ref(store.getters['parameterization/getListArmed'](props.modelValueBody, props.modelValueSubdivision, ''))

        const selectModelBody = computed({
            get() {
                return props.modelValueBody
            },
            set(newValue) {
                emit('update:modelValueBody', newValue)
            }
        })
        const selectModelSubdivision = computed({
            get() {
                return props.modelValueSubdivision
            },
            set(newValue) {
                emit('update:modelValueSubdivision', newValue)
                
            }
        })
        const selectModelRange = computed({
            get() {
                return props.modelValueRange
            },
            set(newValue) {
                emit('update:modelValueRange', newValue)
            }
        })
        watch(selectModelBody, (newValue) => {

            ranges.value = store.getters['parameterization/getListArmed'](newValue, props.modelValueSubdivision, '')
            console.log('body');

        })
        watch(selectModelSubdivision, (newValue) => {

            ranges.value = store.getters['parameterization/getListArmed'](props.modelValueBody, newValue, '')
            console.log('sub');

        })

        return {
            selectModelBody,
            selectModelSubdivision,
            selectModelRange,
            bodies,
            subdivision,
            ranges,

        }

    }
}


</script>
