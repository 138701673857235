import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const createCustomer = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/cliente/store`, formData)
        commit('setCustomer', { data: data.data })
        // commit('addAllied', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'customer', error: error })
    }
}

