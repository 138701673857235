//Es reactivo y desde aquí notificamos a todos los componentes a varios lugares donde esten escuchando los cambios de nuestro state
// export default()=>({

// })

export default () => ({
    alliedList: {},
    allied: {
        ca100CodAliado: '',
        ca100TipoPersona: '',
        ca100TipoIdentidad: '',
        ca100NumeroIdentidad: '',
        Ca100DigitoVerificacion: '',
        ca100PrimerNombre: '',
        ca100SegundoNombre: '',
        ca100PrimerApellido: '',
        ca100SegundoApellido: '',
        ca100FechaNacimiento: '',
        ca100FechaExpedicion: '',
        ca100CiudadExpedicion: '',
        ca100TipoGenero: '',
        ca100TipoEstadoCivil: '',
        ca100NombreComercial: '',
        ca100RazonSocial: '',
        ca100TipoSociedad: '',
        ca100TipoConstitucion: '',
        ca100NumeroConstitucion: '',
        ca100FechaConstitucion: '',
        ca100NumeroNotaria: '',
        ca100CiudadNotaria: '',
        CA100SectorEconomico: '',
        CA100ActividadComercial: '',
        ca100ResponsableRenta: '',
        ca100ResponsableIVA: '',
        ca100RetenedorIVA: '',
        ca100AutoRetenedorIva: '',
        ca100CodigoPais1: '',
        ca100CodigoDpto1: '',
        ca100CodigoCiudad1: '',
        ca100Direccion1: '',
        ca100CorreoElectronico1: '',
        ca100DireccionEnvioCorrespondencia: '',
        ca100CodigoPais2: '',
        ca100CodigoDpto2: '',
        ca100CodigoCiudad2: '',
        ca100Direccion2: '',
        ca100TelefonoCelular1: '',
        ca100TelefonoCelular2: '',
        ca100TelefonoFijo1: '',
        ca100TelefonoFijo2: '',
        ca100CorreoElectronico2: '',
        Ca100ExperienciaActividadComercial: '',
        Ca100CantEmpleados: '',
        Ca100ComentariosEjecutivoComercial: '',
        Ca100DescValidacionArchivos: '',
        Ca100DescDecisionEstudio: '',
        Ca100Estado: ''
    },
    simpleList:{},
    isLoading: false,
    isLoadingAllied: false,
    isLoadingOperationAllied: false,
    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: '',
    },
    alliedParameters: {}
})