
import { encryption, decryption } from '@/helpers/encryptionDecryption'
import { setToken } from '@/helpers/adminToken'
import store from '@/store'

export const loginUser = (state, { user }) => {
    state.status = 'authenticated'
    state.UserLoggedIn = user
}

export const setUserPermissions = (state, { permissions, user, parameters, system }) => {
    state.UserLoggedIn = user
    state.permissions = permissions
    state.userParameters = parameters
    state.system = { fechaSistema: system }
    state.status = 'authenticated'
}
export const updateShiftUser = (state, { codShift }) => {
    state.UserLoggedIn.Turno = codShift
}

export const logout = (state) => {

    state.UserLoggedIn = ''
    state.status = 'not-authenticated'
    state.permissions = []

}
export const setCurrentPage = (state, currentPage) => {
    state.currentPage = currentPage
}

export const showProcessHideAlert = (state) => {

    state.isLoading = true
    state.messageServer = ''
    state.showAlert = false
    state.errorsFields = {}

}
export const hideProcessShowAlert = (state, { messageServer, errorsFields, typeAlert }) => {

    state.isLoading = false
    state.messageServer = messageServer
    // state.showAlert = true
    state.errorsFields = errorsFields
    state.typeAlert = typeAlert

}


