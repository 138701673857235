import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const loadProductList = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'productList')
        const { data } = await requestApi.get(`/productos/admin/index`, {
            params: formData
        })
        commit('setProductList', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}
export const createProduct = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/productos/admin/store`, formData)
        commit('addProduct', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}
export const getProduct = async ({ commit }, { productId }) => {
    try {
        commit('showProcessHideAlert', 'product')
        const { data } = await requestApi.get(`/productos/admin/getProducto/${productId}`)
        commit('setProduct', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}
export const updateProduct = async ({ commit }, { formData, productId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/productos/admin/update/${productId}?_method=PUT`, formData)
        commit('updateProduct', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}
export const deleteProduct = async ({ commit }, {productId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/productos/admin/delete/${productId}?_method=PUT`)
        commit('deleteProduct', { productId })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}
export const deleteProductShop = async ({ commit }, {productId,codShop }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/productos/admin/deleteProductoAlmacen/${productId}?_method=PUT`,{codAlmacen:codShop})
        commit('deleteProductShop', { codShop })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}
export const storeProductShop = async ({ commit }, { formData, productId }) => {
    try {
        commit('showProcessHideAlert', 'productShop')
        const { data } = await requestApi.post(`/productos/admin/storeProductoAlmacen/${productId}`, formData)
        commit('setProduct', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'product', error: error })
    }
}

